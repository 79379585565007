import keyMirror from '../lib/keyMirror';

export default keyMirror({

  START_MYPRINTER_AREA_PENDING: null,
  MYPRINTER_AREA_ERROR: null,

  // for getting the myPrinter info
  START_MYPRINTERS_FETCH: null,
  COMPLETE_MYPRINTERS_FETCH: null,
  COMPLETE_MYPRINTERS_WITH_ERROR: null,
  MYPRINTERS_ALTERED: null,

  START_UPDATE_MYPRINTER_ADDRESS: null,
  COMPLETE_UPDATE_MYPRINTER_ADDRESS: null,
  CLEAR_UPDATE_MYPRINTER_ADDRESS_STATUS: null,

  UPDATE_MYPRINTER_ACCESS_OPTION: null,
  COMPLETE_UPDATE_MYPRINTER_ACCESS_OPTION: null,

  START_ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS: null,
  COMPLETE_ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS: null,
  ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS_ERROR: null,

  START_REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS: null,
  COMPLETE_REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS: null,
  REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS_ERROR: null,

  START_ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS: null,
  COMPLETE_ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS: null,
  ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS_ERROR: null,

  START_REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS: null,
  COMPLETE_REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS: null,
  REMOVE_MYPRINTER_ACCESS_ALLOWED_BLOCKED_ERROR: null,

  TOGGLE_MYPRINTER_ACCESS_NOTIFICATIONS: null,
  START_UPDATE_MYPRINTER_PREFERENCE: null,
  COMPLETE_UPDATE_MYPRINTER_PREFERENCE: null,
  UPDATE_MYPRINTER_PREFERENCE_ERROR: null,
  UPDATE_MYPRINTER_PREFERENCE: null,

  COMPLETE_MYPRINTER_UNCLAIM: null,
  COMPLETE_MYPRINTER_WHITELIST_TOGGLE: null,

  // for getting the myprinter
  START_MYPRINTER_FETCH: null,
  COMPLETE_MYPRINTER_FETCH: null,
  COMPLETE_MYPRINTER_WITH_ERROR: null,

  // for claiming printer
  START_CLAIM_PRINTER: null,
  COMPLETE_CLAIM_PRINTER: null,
  CLAIM_PRINTER_ERROR: null,
  CLEAR_CLAIM_ERROR: null,

  // for contact page
  START_CONTACT_FETCH: null,
  COMPLETE_CONTACT_FETCH: null,
  COMPLETE_CONTACT_FETCH_WITH_ERROR: null,

  // for getting the cookie banner
  START_COOKIEBANNER_FETCH: null,
  COMPLETE_COOKIEBANNER_FETCH: null,
  COMPLETE_COOKIEBANNER_WITH_ERROR: null,

  // for getting the current location
  START_LOCATION_FETCH: null,
  COMPLETE_LOCATION_FETCH: null,
  COMPLETE_LOCATION_WITH_ERROR: null,

  // for getting the current location
  START_DEVICE_FETCH: null,
  COMPLETE_DEVICE_FETCH: null,
  COMPLETE_DEVICE_WITH_ERROR: null,

  // for getting the current location
  START_CONFIG_FETCH: null,
  COMPLETE_CONFIG_FETCH: null,
  COMPLETE_CONFIG_WITH_ERROR: null,

  // for getting the current home content / data
  START_HOMECONTENT_FETCH: null,
  COMPLETE_HOMECONTENT_FETCH: null,
  COMPLETE_HOMECONTENT_WITH_ERROR: null,

  // for updating user locale
  START_LOCALE_UPDATE: null,
  COMPLETE_LOCALE_UPDATE: null,
  COMPLETE_LOCALE_UPDATE_WITH_ERROR: null,

  // for updating user info
  START_USER_INFO: null,
  COMPLETE_USER_INFO: null,
  COMPLETE_USER_INFO_WITH_ERROR: null,
  SET_USER_PRINTER_MODEL: null,
  INITIALIZE_USER: null,

  // for getting the printer inform content / data
  START_PRINTERCOMMON_FETCH: null,
  COMPLETE_PRINTERCOMMON_FETCH: null,
  COMPLETE_PRINTERCOMMON_WITH_ERROR: null,

  // for getting the list of all locale information available from Craft
  START_LOCALES_FETCH: null,
  COMPLETE_LOCALES_FETCH: null,
  COMPLETE_LOCALES_FETCH_WITH_ERROR: null,
  UPDATE_LOCALE: null,

  // For retrieving mobile fax data
  START_MOBILEFAX_FETCH: null,
  COMPLETE_MOBILEFAX_FETCH: null,
  COMPLETE_MOBILEFAX_FETCH_WITH_ERROR: null,

  // For retrieving library landing data
  START_LIBRARYLANDING_FETCH: null,
  COMPLETE_LIBRARYLANDING_FETCH: null,
  COMPLETE_LIBRARYLANDING_FETCH_WITH_ERROR: null,

  // For retrieving library topic data
  START_LIBRARYTOPIC_FETCH: null,
  COMPLETE_LIBRARYTOPIC_FETCH: null,
  COMPLETE_LIBRARYTOPIC_FETCH_WITH_ERROR: null,

  // For retrieving terms of use
  START_TERMSOFUSE_FETCH: null,
  COMPLETE_TERMSOFUSE_FETCH: null,
  COMPLETE_TERMSOFUSE_FETCH_WITH_ERROR: null,

  // For retrieving terms of use for HPSmart.com
  START_TERMS_FETCH: null,
  COMPLETE_TERMS_FETCH: null,
  COMPLETE_TERMS_FETCH_WITH_ERROR: null,

  // For retrieving ePrint Landing Page
  START_EPRINTLANDING_FETCH: null,
  COMPLETE_EPRINTLANDING_FETCH: null,
  COMPLETE_EPRINTLANDING_FETCH_WITH_ERROR: null,

  // used to compose fetch begin/complete patterns for other reducers.
  START_FETCH: null,
  COMPLETE_FETCH: null,
  COMPLETE_FETCH_WITH_ERROR: null,

  // Used for country dropdown reducer for static and dynamic content
  START_COUNTRYDROPDOWN_FETCH: null,
  COMPLETE_COUNTRYDROPDOWN_FETCH: null,
  COMPLETE_COUNTRYDROPDOWN_WITH_ERROR: null,

  SET_CURRENT_USER: null,

  AUTHENTICATE_START: null,
  AUTHENTICATE_COMPLETE: null,
  AUTHENTICATE_ERROR: null,

  ERROR_RETRIEVING_STATE: null,

  // for getting notifications page content
  START_NOTIFICATIONS_FETCH: null,
  COMPLETE_NOTIFICATIONS_FETCH: null,
  COMPLETE_NOTIFICATIONS_WITH_ERROR: null,

  // print anywhere printanywhere
  START_PRINTANYWHERE_FETCH: null,
  COMPLETE_PRINTANYWHERE_FETCH: null,
  COMPLETE_PRINTANYWHERE_WITH_ERROR: null,

  START_PRINTANYWHERE_UPDATE_EMAIL: null,
  COMPLETE_PRINTANYWHERE_UPDATE_EMAIL: null,
  COMPLETE_PRINTANYWHERE_UPDATE_EMAIL_WITH_ERROR: null,

  START_PRINTANYWHERE_UPDATE_MODE: null,
  COMPLETE_PRINTANYWHERE_UPDATE_MODE: null,
  COMPLETE_PRINTANYWHERE_UPDATE_MODE_WITH_ERROR: null,

  START_PRINTANYWHERE_UPDATE_PERMISSIONS: null,
  COMPLETE_PRINTANYWHERE_UPDATE_PERMISSIONS: null,
  COMPLETE_PRINTANYWHERE_UPDATE_PERMISSIONS_WITH_ERROR: null,

  START_PRINTANYWHERE_ADD_PERMISSIONS: null,
  COMPLETE_PRINTANYWHERE_ADD_PERMISSIONS: null,
  COMPLETE_PRINTANYWHERE_ADD_PERMISSIONS_WITH_ERROR: null,

  START_PRINTANYWHERE_REMOVE_PERMISSIONS: null,
  COMPLETE_PRINTANYWHERE_REMOVE_PERMISSIONS: null,
  COMPLETE_PRINTANYWHERE_REMOVE_PERMISSIONS_WITH_ERROR: null,

  OPEN_LOCALE_DROPDOWN: null,
  CLOSE_LOCALE_DROPDOWN: null,
  OPEN_SEARCH_DROPDOWN: null,
  CLOSE_SEARCH_DROPDOWN: null,
  OPEN_SETTINGS_DROPDOWN: null,
  CLOSE_SETTINGS_DROPDOWN: null,
  OPEN_MOBILE_MENU: null,
  CLOSE_MOBILE_MENU: null,
  TO_TABLET: null,
  TO_NOT_TABLET: null,
  TO_DESKTOP: null,
  TO_MOBILE: null,
  DROPDOWNS_OFF: null,
  NOTIFICATIONS_BANNER_OFF: null,
  NOTIFICATIONS_BANNER_ON: null,

  // Application level actions
  API_CALL: null,
  PENDING_CALL: null,
  CALL_RESOLVED: null,
  CALL_FAILED: null,
  TOAST_SUCCESS: null,
  TOAST_FAIL: null,
  CLEAR_TOAST: null,
  REDIRECT_TO_SIGNOUT: null,

  // craft specific fetch actions
  CRAFT_CONTENT: null,
  CRAFT_CONTENT_FAILURE: null,

  // analytics related actions
  CAPTURE_UDL_PARAMS: null,
  SAVE_UDL_PARAMS: null,
  UPDATE_SHORT_TITLE_META: null,
});
