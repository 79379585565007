import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { setCookie, setCsrfState } from '../../utils/globals';
import Config from '../../config';
import {
  selectSignInProps,
} from '../../store/modules/portal/selectors';
import SignIn from '../global/signin';
import {
  dispatchJWebCloseEvent,
  ifJWebAvailable,
  isJWebAndroidApp,
  isJWebEventingPluginValid,
  isJWebiOSApp,
  setSelectedOrgId,
} from '../../utils/auth';
import Loader from '../shared-components/Loader';
import { renderErrorModal } from '../ErrorModal';
import i18n from '../../utils/i18n';
import { selectJWebSignInStatus } from '../../store/modules/jweb/selectors';
import { jwebSignInRequest } from '../../store/modules/jweb/actions';
import useFeatureFlags from '../../utils/useFeatureFlags';
import { IS_INVITE } from '../../constants/cookieNames';

const SignInWeb = ({ encodedState, handshake, promptLogin }) => {
  const { stratusClientId } = useFeatureFlags();
  setCsrfState(handshake);

  const signinUrl = Config.createSigninUrl(encodedState, promptLogin, stratusClientId);

  return <SignIn loc={signinUrl} />;
};

SignInWeb.defaultProps = {
  promptLogin: false,
};

SignInWeb.propTypes = {
  encodedState: PropTypes.string.isRequired,
  handshake: PropTypes.string.isRequired,
  promptLogin: PropTypes.bool,
};

const MainSignIn = ({ queryString }) => {
  const [useWebSignIn, setUseWebSignIn] = useState(undefined);
  const [isSigningWithJWeb, setIsSigningWithJWeb] = useState(false);

  const {
    handshake,
    redirectTo,
    encodedState,
    promptLogin,
  } = useSelector(selectSignInProps);
  const dispatch = useDispatch();

  const { jWebSignInFailed } = useSelector(selectJWebSignInStatus);

  useEffect(() => {
    if (queryString.trim().length) {
      const urlSearchParams = new URLSearchParams(queryString);
      const searchParamsObject = Object.fromEntries(urlSearchParams.entries());
      const invite = pathOr('', ['invite'], searchParamsObject);
      const orgId = pathOr('', ['orgId'], searchParamsObject);

      setSelectedOrgId(orgId);

      if (invite && invite === 'true') {
        setCookie(IS_INVITE, true);
      } else {
        setCookie(IS_INVITE, false);
      }
    }
  }, [queryString]);

  useEffect(() => {
    if (jWebSignInFailed) {
      const loadUserJWebErrorAction = () => {
        dispatch(jwebSignInRequest(redirectTo, true));
      };

      const loadUserJWebErrorDismiss = (isJWebiOSApp() || (isJWebAndroidApp() && isJWebEventingPluginValid()))
        ? () => dispatchJWebCloseEvent()
        : undefined;

      renderErrorModal({
        title: i18n.t('home.error.retrieveData.title'),
        errorMessage: i18n.t('home.error.retrieveData.message'),
        actionLabel: i18n.t('home.error.confirmLabel'),
        actionCallback: () => loadUserJWebErrorAction(),
        dismissLabel: loadUserJWebErrorDismiss && i18n.t('home.topNav.buttons.close'),
        dismissCallback: loadUserJWebErrorDismiss,
      });
    }
  }, [jWebSignInFailed]);

  if (useWebSignIn === undefined) {
    ifJWebAvailable(() => {
      setUseWebSignIn(false);
    }, () => {
      setUseWebSignIn(true);
    });
  }

  if (useWebSignIn) {
    return (
      <SignInWeb
        encodedState={encodedState}
        handshake={handshake}
        promptLogin={promptLogin}
      />
    );
  }

  if (!isSigningWithJWeb && useWebSignIn === false) {
    // JWeb bridge is not available.. ready to sign in with web
    setIsSigningWithJWeb(true);

    const skipTokenRefresh = promptLogin;
    dispatch(jwebSignInRequest(redirectTo, true, skipTokenRefresh));
  }

  return (
    <Loader fullScreen />
  );
};

MainSignIn.defaultProps = {
  queryString: '',
};

MainSignIn.propTypes = {
  queryString: PropTypes.string,
};

export default MainSignIn;
