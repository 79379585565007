import ActionTypes from '../constants/ActionTypes';
import { makeActionCreator } from '../utils/redux';

const {
  PENDING_CALL,
  CALL_RESOLVED,
  API_CALL,
  TOAST_FAIL,
  CLEAR_TOAST,
  TOAST_SUCCESS,
  CALL_FAILED,
} = ActionTypes;

export const failureToastNotification = makeActionCreator(TOAST_FAIL, 'message');

export const successToastNotification = makeActionCreator(TOAST_SUCCESS, 'message');

export const clearToastNotification = makeActionCreator(CLEAR_TOAST, 'message');

export const apiCallFailed = makeActionCreator(CALL_FAILED, 'action', 'error');

export const addToAsyncArray = makeActionCreator(PENDING_CALL, 'signal');

export const removeFromAsyncArray = makeActionCreator(CALL_RESOLVED, 'signal');

export const makeApiCall = ({
  asyncFn,
  args,
  successActionCreator,
  failureActionCreator,
  callback,
}) => ({
  type: API_CALL,
  asyncFn,
  args,
  successActionCreator,
  failureActionCreator,
  callback,
});
