import { Service } from 'axios-middleware';
import createAxiosInstance from './api';
import { parseUcdeGatewayApiError } from '../utils/apiErrorHandling';
import Config from '../config';
import { IS_AGENT_SESSION } from '../constants/cookieNames';
import { getCookie } from '../utils/globals';
import { getStratusAccessToken, getStratusBaseToken, isStratusAccessTokenV2Base } from '../utils/auth';

const sessionApi = createAxiosInstance(Config.SESSION_SERVICE_BASE_URL);

const service = new Service(sessionApi);

service.register({
  onResponseError(error) {
    if (error) {
      const currentStack = Config.STACK;

      if (currentStack === 'dev') {
        console.error(parseUcdeGatewayApiError(error));
      }
    }

    throw error;
  },
});

export const getExchangeToken = async accessToken => {
  const authorizationHeader = `Bearer ${accessToken}`;
  const options = {
    headers: {
      authorization: authorizationHeader,
    },
    withCredentials: true,
  };
  const exchangeToken = await sessionApi.put('/exchange', null, options);
  return exchangeToken;
};

// TODO: change this to a observer logic
export async function refreshToken() {
  if (getCookie(IS_AGENT_SESSION)) {
    return;
  }

  const options = {
    withCredentials: true,
  };

  // there's no need to pass auth as a header since it get the cookies
  await sessionApi.put('/refresh', null, options);
}

export const tokenExchange = async tenantId => {
  const token = isStratusAccessTokenV2Base() ? getStratusAccessToken() : getStratusBaseToken();

  const authorizationHeader = `Bearer ${token}`;
  const options = {
    headers: {
      authorization: authorizationHeader,
    },
    withCredentials: true,
  };
  const response = await sessionApi.post('/token-exchange', { tenantId }, options);
  return response;
};
