import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconPerson from '@veneer/core/dist/scripts/icons/icon_person';
import { Circle, Container, Text } from './styles';

function formatText(text) {
  if (!text) {
    return '';
  }

  const splitted = (text.indexOf(' ') === 0) ? text : text.split(' ');
  const len = splitted.length;

  if (len === 1) {
    return splitted[0].substring(0, 1).toUpperCase();
  }

  return splitted[0].substring(0, 1).concat(splitted[len - 1].substring(0, 1)).toUpperCase();
}

const Avatar = ({
  text, backgroundColor, backgroundOpacity, backgroundSize, fontColor, fontSize,
}) => {
  const [initials, setInitials] = useState();

  useEffect(() => {
    async function loadInitials() {
      setInitials(formatText(text));
    }

    loadInitials();
  }, [text]);

  return (
    <Container>
      <Circle
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundSize={backgroundSize}
      />
      <Text
        fontColor={fontColor}
        fontSize={fontSize}
        backgroundSize={backgroundSize}
      >
        { initials || <IconPerson /> }
      </Text>
    </Container>
  );
};

Avatar.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.string,
  backgroundSize: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
};

Avatar.defaultProps = {
  text: '',
  backgroundColor: '#027AAE',
  backgroundOpacity: '0.2',
  backgroundSize: '36',
  fontColor: '#027AAE',
  fontSize: '25px',
};

export default Avatar;
