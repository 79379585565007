import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import analytics from './analyticsReducer';
import application from './application';
import portalReducers from '../store/modules/rootReducer';

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  analytics,
  application,
  ...portalReducers,
});

export default createRootReducer;
