import { VISION_SESSION } from '../../../constants/cookieNames';
import { getCookie } from '../../../utils/globals';

export function getSessionID() {
  const visionSessionCookieValue = getCookie(VISION_SESSION);

  if (visionSessionCookieValue) {
    return visionSessionCookieValue.replace('sessionID=%23s', '');
  }
  return '';
}

export default getSessionID;
