import {
  exchangeToken as authExchangeToken,
  forceLogin,
  getAccessToken,
  getForceLoginThreshold,
  getOrglessAccessToken,
  getStratusAccessToken,
  isCriticalScopesValid,
  redirectToSignIn,
} from '../utils/auth';

const exchangeToken = async () => (
  authExchangeToken(getStratusAccessToken())
);

const oldAuth = {
  forceLogin: (args = {}) => redirectToSignIn({ ...args, forceLogin: true }),
  getAccessToken,
  exchangeToken,
  getOrglessAccessToken,
};

const auth = {
  ...oldAuth,
  v1: { ...oldAuth },
  v2: {
    getForceLoginThreshold,
    forceLogin,
    isCriticalScopesValid,
  },
};

export default auth;
