import { propOr } from 'ramda';
import {
  FallbackCountries,
  Locales,
  PublishedDefault,
  getLocaleMessages,
  redundantLocales,
} from '../locale/index';
import enUS from '../locale/en_US.json';
import Config from '../config';
import Envs from '../config/environments';
import { getBrowserLocale } from './globals';

const DEFAULT_COUNTRY = 'US';
const DEFAULT_LANGUAGE = 'en';
const DEFAULT_LOCALE_MESSAGES = enUS;
const TEMPLATE_MATCHER = /{{\s?([^{}\s]*)\s?}}/g;
const TAG_MATCHER = /{{([^{}\s]*)}}(.*){{\/\1}}|{{([^{}\s]*)}}/g;

function stringParser(string, keys) {
  return string.replace(TEMPLATE_MATCHER, (_, idx) => keys[idx]);
}

/**
 * Parses a string containing tags to be replaced by keys.
 * @param {string} string is the string to be parsed, containing the tags to be replaced
 * @param {object} keys is the object containing the keys to be replaced
 * @returns {array} the parsed string with the tags replaced by the keys
 */
function jsxParser(string, keys) {
  // splitted array examples:
  // 1: "before {{tag}}content{{/tag}}  after"
  // [ 'before ', 'tag', 'content', undefined, ' after' ]
  // 2: "before {{tag1}}content #1{{/tag1}} middle {{tag2}}content #2{{/tag2}} after"
  // [ 'before ', 'tag1', 'content #1', undefined, ' middle ', 'tag2', 'content #2', undefined, ' after' ]
  // 3: "before {{tag}} after"
  // [ 'before ', undefined, undefined, 'tag', ' after' ]
  // 4: "before {{tag1}}content{{/tag1}} middle {{tag2}} after"
  // [ 'before ', 'tag1', 'content', undefined, ' middle ', undefined, undefined, 'tag2', ' after' ]
  return string
    .split(TAG_MATCHER)
    .map((value, index, array) => {
      const isTag = index % 4;
      if (isTag) {
        if (isTag === 1) {
          const tag = keys[value] || keys[array[index + 2]];
          const content = array[index + 1];
          if (typeof tag === 'function') {
            return tag(content);
          }
          return tag;
        }
        return undefined;
      }
      return value;
    })
    .filter(content => content);
}

export class I18n {
  constructor() {
    this.country = DEFAULT_COUNTRY;
    this.language = DEFAULT_LANGUAGE;
    this.messages = DEFAULT_LOCALE_MESSAGES;
    this.publishedLocales = Config.STACK === Envs.prd.STACK ? PublishedDefault : Locales;
    this.filters = [];
  }

  locale() {
    return `${this.language}_${this.country}`;
  }

  getCountry() {
    return this.country;
  }

  getLanguage() {
    return this.language;
  }

  getFallbackCountry(language) {
    return FallbackCountries[language];
  }

  getDefaultCountry() {
    return DEFAULT_COUNTRY;
  }

  getDefaultLanguage() {
    return DEFAULT_LANGUAGE;
  }

  isLanguageSupported(language) {
    if (this.getFallbackCountry(language)) {
      return true;
    }
    return false;
  }

  isLanguageAndCountryPublished(languageAndCountry) {
    return !!this.publishedLocales.includes(`${languageAndCountry}`);
  }

  isLocalePublished(country = '', language) {
    return this.isLanguageAndCountryPublished(`${language}_${country.toUpperCase()}`);
  }

  setPublishedLocales(locales) {
    if (Array.isArray(locales) && locales.length) {
      this.publishedLocales = locales.reduce((acc, item) => {
        if (Locales.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, []);
    }
  }

  async setSupportedLocale(country = '', language = '', userLocale = '', gatewayBaseUser) {
    let upperCasedCountry = country.toUpperCase();

    // If it's is gateway, use the given locale.
    if (gatewayBaseUser) {
      if (country) {
        await this.updateCountryAndLanguage(upperCasedCountry, language);
      } else {
        this.applyDefaultCountryAndLanguage();
      }
      return;
    }

    // If given locale is published, use that.
    if (this.isLocalePublished(upperCasedCountry, language)) {
      await this.updateCountryAndLanguage(upperCasedCountry, language);
      return;
    }

    // If fallback country + language is a published locale, use that.
    let fallbackCountry = this.getFallbackCountry(language);
    if (fallbackCountry && this.isLocalePublished(fallbackCountry, language)) {
      await this.updateCountryAndLanguage(fallbackCountry, language);
      return;
    }

    // If fallback country not exists, use that.
    if (!fallbackCountry && !userLocale) {
      const { language: browserLanguage, country: browserCountry } = getBrowserLocale();
      const countryOfLanguage = browserCountry || this.getFallbackCountry(browserLanguage);

      if (this.isLocalePublished(countryOfLanguage, browserLanguage)) {
        await this.updateCountryAndLanguage(countryOfLanguage.toUpperCase(), browserLanguage);
        return;
      }
    }

    // Try to use the user locale.
    if (userLocale) {
      const [userLanguage, userCountry] = userLocale.split('_');
      upperCasedCountry = userCountry.toUpperCase();

      // If user locale is published, use that.
      if (this.isLocalePublished(upperCasedCountry, userLanguage)) {
        await this.updateCountryAndLanguage(upperCasedCountry, userLanguage);
        return;
      }

      // If fallback locale is published, use that.
      fallbackCountry = this.getFallbackCountry(userLanguage);
      if (fallbackCountry && this.isLocalePublished(fallbackCountry, userLanguage)) {
        await this.updateCountryAndLanguage(fallbackCountry, userLanguage);
        return;
      }
    }

    this.applyDefaultCountryAndLanguage();
  }

  async updateCountryAndLanguage(country, language) {
    if (this.country === country && this.language === language) {
      return;
    }

    const localeCode = `${language}_${country}`;
    const locale = propOr(localeCode, localeCode, redundantLocales);
    const messages = await getLocaleMessages(locale);

    if (messages) {
      this.country = country;
      this.language = language;
      this.messages = messages;
      this.filter();
    }
  }

  applyDefaultCountryAndLanguage() {
    this.country = DEFAULT_COUNTRY;
    this.language = DEFAULT_LANGUAGE;
    this.messages = DEFAULT_LOCALE_MESSAGES;
    this.filter();
  }

  filter() {
    // Recursively traverses the locale strings object, substituting strings
    // when their key matches the respective key in the filtering table.
    function filterRec(obj, table) {
      const strings = obj;

      if (strings) {
        Object.entries(table).forEach(([key, value]) => {
          const stringValue = strings[key];
          if (stringValue) {
            if (value instanceof Object && stringValue instanceof Object) {
              filterRec(stringValue, value);
            } else {
              strings[key] = value;
            }
          }
        });
      }
    }

    this.filters.forEach(filter => {
      // Applies the filter for the current locale.
      filterRec(this.messages, filter.getTable());
    });
  }

  withFilter(filter) {
    const hasFilter = this.filters.find(({ name }) => filter.name === name);
    if (!hasFilter) {
      this.filters.push(filter);
      this.filter();
    }
  }

  t(id, keys) {
    try {
      const rawString = id
        .split('.')
        .reduce(
          (o, i) => o[i],
          this.messages,
        );
      return keys ? stringParser(rawString, keys) : rawString;
    } catch (err) {
      return id;
    }
  }

  tJSX(id, keys) {
    const text = this.t(id);
    return jsxParser(text, keys);
  }
}

const i18n = new I18n(DEFAULT_COUNTRY, DEFAULT_LANGUAGE, DEFAULT_LOCALE_MESSAGES);

export default i18n;
