import {
  all,
  put,
  race,
  retry,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  asyncEventFailed,
  asyncEventRequest,
  asyncEventRetry,
  asyncEventSuccess,
} from './actions';
import {
  MAX_TRIES,
  RETRY_DELAY,
} from './constants';

const getComponentError = (store, component) => store.events.asyncEvents[component].hasError;

const getComponentRetries = (
  store,
  component,
  functionIndex,
) => store.events.asyncEvents[component].backgroundFunctions[functionIndex].retries;

export function* generatorFunctionWithAutoRetry(asyncFunction, { component, isRefreshing }, functionIndex) {
  const isReduxAction = !(asyncFunction instanceof Function);

  let retries = yield select(store => getComponentRetries(store, component, functionIndex));

  try {
    retries += 1;

    if (isReduxAction) {
      const actionSuccess = asyncFunction.successActionCreator.toString();
      const actionFailed = asyncFunction.failureActionCreator.toString();

      yield put(asyncFunction.requestActionCreator(isRefreshing));

      const [, sagaError] = yield race([
        take([actionSuccess]),
        take([actionFailed]),
      ]);

      if (sagaError) {
        throw Error();
      }
    } else {
      yield asyncFunction(isRefreshing);
    }

    yield put(asyncEventRetry({
      component, functionIndex, retries, hasError: false,
    }));
  } catch (error) {
    if (retries < MAX_TRIES) {
      yield put(asyncEventRetry({
        component, functionIndex, retries, hasError: true,
      }));
      throw Error();
    } else {
      yield put(asyncEventFailed({ component, functionIndex, retries }));
    }
  }
}

function* handleAsyncEventExecution({ payload }) {
  const { asyncFunctions, component, isRefreshing } = payload;

  yield all(asyncFunctions.map(function* executeAsyncFunctions(asyncFunction, functionIndex) {
    yield retry(
      MAX_TRIES,
      RETRY_DELAY,
      generatorFunctionWithAutoRetry,
      asyncFunction,
      { component, isRefreshing },
      functionIndex,
    );
  }));

  const asyncFunctionError = yield select(store => getComponentError(store, component));
  if (!asyncFunctionError) {
    yield put(asyncEventSuccess({ component }));
  }
}

export function* watchAsyncEventExecution() {
  yield takeEvery(asyncEventRequest.toString(), handleAsyncEventExecution);
}
