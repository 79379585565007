import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import Avatar from '../../../../Avatar';
import {
  AccountProfileLink,
  Container,
  UserDataAndMenuListWrapper,
  UserDataWrapper,
  UserEmailText,
  UserNameText,
} from './styles';
import { makePathRelative } from '../../../../../utils/routing';
import { selectRootMatch } from '../../../../../selectors/routing';
import { PATHS } from '../../../../../utils/constants';
import i18n from '../../../../../utils/i18n';

const UserData = ({ user, userFullName, callback }) => {
  const dispatch = useDispatch();
  const match = useSelector(selectRootMatch);
  const relativePath = makePathRelative(match);

  return (
    <Container>
      <Avatar
        text={userFullName}
        fontSize={16}
        backgroundSize={48}
      />
      <UserDataAndMenuListWrapper>
        <UserDataWrapper>
          <UserNameText>
            {userFullName}
          </UserNameText>
          <UserEmailText>{user.email}</UserEmailText>
        </UserDataWrapper>
        <AccountProfileLink
          onClick={() => {
            callback();
            dispatch(push(relativePath(PATHS.ACCOUNT_DETAILS.PROFILE)));
          }}
        >
          {i18n.t('home.accountIcon.accountProfile')}
        </AccountProfileLink>
      </UserDataAndMenuListWrapper>
    </Container>
  );
};

UserData.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  userFullName: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default UserData;
