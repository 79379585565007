/* eslint-disable no-use-before-define */
import {
  curry,
  prop,
  propEq,
  reject,
} from 'ramda';
import { createReducer } from '../utils/redux';
import * as applicationActions from '../actions/application';

const initialState = {
  asyncArray: [],
  toasts: [],
};

const makeToast = curry((success, state, { message }) => ({
  ...state,
  toasts: [
    ...state.toasts,
    {
      success,
      message,
    },
  ],
}));

const handlers = {
  [applicationActions.addToAsyncArray]: addToAsyncArray,
  [applicationActions.removeFromAsyncArray]: removeFromAsyncArray,
  [applicationActions.failureToastNotification]: makeToast(false),
  [applicationActions.successToastNotification]: makeToast(true),
  [applicationActions.clearToastNotification]: clearToast,
};

const applicationReducer = createReducer(initialState, handlers);
export default applicationReducer;

function addToAsyncArray(state, { signal }) {
  return {
    ...state,
    asyncArray: [...state.asyncArray, signal],
  };
}

function removeFromAsyncArray(state, { signal }) {
  if (state.asyncArray.includes(signal)) {
    const asyncArray = [...state.asyncArray]
      .filter(sig => sig !== signal);
    return {
      ...state,
      asyncArray,
    };
  }
  return state;
}

function clearToast(state, { message }) {
  return {
    ...state,
    toasts: reject(propEq('message', message), prop('toasts', state)),
  };
}
