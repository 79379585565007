import styled from 'styled-components';
import Header from '@veneer/core/dist/scripts/header';

export const HeaderContent = styled(Header)`
  height: 68px;
  && {
    grid-template-columns: 96px auto 96px;
    -ms-grid-columns: 96px auto 96px;
    padding-left: 0;
    padding-top: 8px;
    padding-right: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEBEB;
    z-index: 1000;
    position: fixed;
    top: 0;
  }
`;

export const CenterAreaContent = styled.div`
  height: 48px;
  width: 100%;
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  color: #666;
  visibility: visible;
  font-size: max(min(4.35vw,16px), 12px);
`;
