import Types from './types';
import { makeActionCreator } from '../../../utils/redux';

export const accountStatusCardRefreshOnClick = makeActionCreator(Types.ACCOUNT_STATUS_CARD_REFRESH_ON_CLICK);
export const asyncEventRequest = makeActionCreator(Types.ASYNC_EVENT_REQUEST, 'payload');
export const asyncEventRetry = makeActionCreator(Types.ASYNC_EVENT_RETRY, 'payload');
export const asyncEventFailed = makeActionCreator(Types.ASYNC_EVENT_FAILED, 'payload');
export const asyncEventSuccess = makeActionCreator(Types.ASYNC_EVENT_SUCCESS, 'payload');

const Actions = {
  accountStatusCardRefreshOnClick,
};

export default Actions;
