const adsPlaceholders = require('./ads-placeholders.png');
const benefitsGreenCheck = require('./benefits-green-check.svg');
const benefitsHand = require('./benefits-hand.svg');
const benefitsTruck = require('./benefits-truck.svg');
const documentCopy = require('./document-copy.svg');
const genericPrinter = require('./generic-printer.svg');
const hpLogo = require('./hp_logo.svg');
const hpPlus = require('./hp-plus.svg');
const hpPlusLogo = require('./hp-plus-logo.svg');
const instantSupplies = require('./instant-supplies.svg');
const learnMore = require('./learn-more.svg');
const noNotifications = require('./no-notifications.svg');
const printAndScansPlaceholder = require('./print-and-scans-placeholder.png');
const printersCardIcon = require('./printers-icon.svg');
const printOnTheGo = require('./print-on-the-go.svg');
const printPhoto = require('./print-photo.svg');
const smartSecurity = require('./smart-security.svg');
const smartTasks = require('./smart-tasks.svg');
const logoBenefitsShield = require('./benefits-shield.svg');
const logoBenefitsCloudPrint = require('./benefits-cloudPrint.svg');
const logoBenefitsTruck = require('./benefits-truckPrint.svg');
const logoBenefitsEmailPrint = require('./benefits-emailPrint.svg');
const warrantyIcon = require('./warranty-icon.svg');
const sustainabilityIcon = require('./sustainability-icon.svg');
const recycleIcon = require('./recycle-icon.svg');
const printPlansIcon = require('./print-plans-icon.svg');
const sustainabilityBackground = require('./background-sustainability.svg');
const sustainabilityBackgroundForest = require('./sustainability-background-forest.png');
const forestFirstLogo = require('./forest-first-logo.svg');
const virtualAssistant = require('./virtual-assistant.svg');
const whatsAppSupport = require('./whatsapp-logo.svg');
const advanceScanLogo = require('./advance-scan-logo.svg');
const shortcutsLogo = require('./shortcuts-logo.svg');
const smartAdvanceLogo = require('./smart-adv-logo.svg');
const smartAdvanceIcon = require('./smart-adv-icon.svg');
const hpSecondOffer = require('./hp-second-offer.svg');
const hpPlusLogoWhite = require('./hp-plus-logo-white.svg');
const logoIIPlatinum = require('./logo-ii-platinum.png');
const allInPlanIcon = require('./all-in-plan-icon.svg');

const Images = {
  adsPlaceholders,
  benefitsGreenCheck,
  benefitsHand,
  benefitsTruck,
  documentCopy,
  genericPrinter,
  hpLogo,
  hpPlus,
  hpPlusLogo,
  instantSupplies,
  learnMore,
  noNotifications,
  printAndScansPlaceholder,
  printersCardIcon,
  printOnTheGo,
  printPhoto,
  smartSecurity,
  smartTasks,
  logoBenefitsShield,
  logoBenefitsCloudPrint,
  logoBenefitsTruck,
  logoBenefitsEmailPrint,
  warrantyIcon,
  sustainabilityIcon,
  sustainabilityBackground,
  sustainabilityBackgroundForest,
  recycleIcon,
  printPlansIcon,
  forestFirstLogo,
  virtualAssistant,
  whatsAppSupport,
  advanceScanLogo,
  shortcutsLogo,
  smartAdvanceLogo,
  smartAdvanceIcon,
  hpSecondOffer,
  hpPlusLogoWhite,
  logoIIPlatinum,
  allInPlanIcon,
};

export default Images;
