import {
  put, takeEvery, takeLeading,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ucdeUserActions, {
  initializeUcdeUser,
  loadUcdeUserProfile,
  saveUcdeUserProfile,
} from './actions';
import { getUcdePortalElementsRequest } from '../portalElements/actions';
import { getStratusAccessToken } from '../../../utils/auth';
import { setAuthorizationHeader } from '../../../api/UCDEGateway';
import { checkIfRouteNeedsPortalElements } from '../../../utils/routing';

function needPortalElements(path) {
  return checkIfRouteNeedsPortalElements(path);
}

function* manageUserInitialization({ path = '/ucde' }) {
  setAuthorizationHeader(getStratusAccessToken());

  yield put(ucdeUserActions.loadUcdeUserRequest());
  yield put(ucdeUserActions.getUcdeUser);

  if (needPortalElements(path)) {
    yield put(getUcdePortalElementsRequest());
  }

  yield put(push(path));
}

function* retrieveUcdeUserProfile({ userType = 'ucde' }) {
  if (userType === 'ucde') {
    yield put(ucdeUserActions.getUcdeUserProfile);
  }
}

function* saveUcdeUser(action) {
  yield put(ucdeUserActions.saveUcdeUser(action.payload));
}

export function* watchInitializeUcdeUser() {
  yield takeLeading(initializeUcdeUser.toString(), manageUserInitialization);
}

export function* watchSaveUcdeUserProfile() {
  yield takeLeading(saveUcdeUserProfile.toString(), saveUcdeUser);
}

export function* watchLoadUcdeUserProfile() {
  yield takeEvery(loadUcdeUserProfile.toString(), retrieveUcdeUserProfile);
}
