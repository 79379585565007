import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MobileView } from 'react-device-detect';
import i18n from '../../../utils/i18n';
import {
  CenterArea,
  CloseButton,
  HeaderContainer,
  RightArea,
} from './styles';
import ProfileMenu from './ProfileMenu';
import PageTitle from '../../PageTitle';
import {
  isJWebAndroidApp,
  isJWebDesktopApp,
  isJWebEventingPluginValid,
  isJWebiOSApp,
} from '../../../utils/auth';

import { closePortal } from '../../../store/modules/portalClose/actions';
import useFeatureFlags from '../../../utils/useFeatureFlags';
import ProfileMenuWithOrgSelector from './ProfileMenuWithOrgSelector';
import { getOrganizations } from '../../../api/UCDEGateway';
import { selectUcdeUser } from '../../../store/modules/ucdeUser/selectors';
import BellNotifications from '../../BellNotifications';
import selectUcdePortalElements from '../../../store/modules/portalElements/selectors';

const TopNav = () => {
  const [isFetchingOrgList, setIsFetchingOrgList] = useState(false);
  const [hasFetchedOrgList, setHasFetchedOrgList] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const userProfile = useSelector(selectUcdeUser);

  const dispatch = useDispatch();
  const { newTenancyModel, viewNotificationsPersonalAccount } = useFeatureFlags();
  const ucdePortalElements = useSelector(selectUcdePortalElements());

  const showNotificationsBell = viewNotificationsPersonalAccount
    ? ucdePortalElements.accountDetails.items?.notifications : true;

  useEffect(() => {
    async function fetchData() {
      if (!isFetchingOrgList && !hasFetchedOrgList) {
        setIsFetchingOrgList(true);
        try {
          let newOrgList = [];
          const response = await getOrganizations();

          if (response.status === 200) {
            newOrgList = response.data.map(org => ({
              ...org,
              active: org.nodeId === userProfile.tenantResourceId,
            }));
          }
          setOrgList(newOrgList);
        } finally {
          setIsFetchingOrgList(false);
          setHasFetchedOrgList(true);
        }
      }
    }
    if (newTenancyModel) {
      fetchData();
    }
  }, []);

  const headerRightContent = () => {
    if (isJWebiOSApp() || (isJWebAndroidApp() && isJWebEventingPluginValid())) {
      return (
        <>
          <CloseButton
            onClick={() => dispatch(closePortal())}
            data-testid="home-close-button"
          >
            {i18n.t('home.topNav.buttons.close')}
          </CloseButton>
        </>
      );
    }

    if (isJWebAndroidApp() || isJWebDesktopApp()) {
      return (
        <>
        </>
      );
    }

    return (
      <>
        {showNotificationsBell && <BellNotifications />}
        {newTenancyModel
          ? (
            <ProfileMenuWithOrgSelector
              isLoading={!hasFetchedOrgList}
              orgList={orgList}
            />
          ) : (
            <div
              data-testid="avatar-container"
            >
              <ProfileMenu />
            </div>
          )}
      </>
    );
  };

  return (
    <HeaderContainer
      data-testid="portal-top-menu"
      position="fixed"
      leadingArea={(<></>)}
      centerArea={(
        <MobileView>
          <CenterArea>
            <PageTitle />
          </CenterArea>
        </MobileView>
      )}
      trailingArea={(
        <RightArea data-testid="right-area">
          {headerRightContent()}
        </RightArea>
      )}
    />
  );
};

export default TopNav;
