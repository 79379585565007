import Config from '../config';
import createAxiosInstance from './api';

const stack = Config.CONTENT_STACK_ENV || Config.STACK;
const contentStackApi = createAxiosInstance(Config.CONTENT_STACK_URL);
const headers = {
  api_key: Config.CONTENT_STACK_KEY,
  access_token: Config.CONTENT_STACK_TOKEN,
};

export async function getHelpSections(locale = 'en-us') {
  const response = await contentStackApi.get(`content/v3/content_types/smart_help_sections/entries?locale=${locale}&environment=${stack}`, {
    headers,
  });
  return response.data;
}

export async function getMainContent(locale = 'en-us') {
  const response = await contentStackApi.get(`content/v3/content_types/smart_help/entries?locale=${locale}&environment=${stack}`, {
    headers,
  });
  return response.data;
}
