import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomImage from '@veneer/core/dist/scripts/custom_image/custom_image';
import IconChat from '@veneer/core/dist/scripts/icons/icon_chat';
import IconHelp from '@veneer/core/dist/scripts/icons/icon_help';
import LogoHp from '@veneer/core/dist/scripts/logos/logo_hp';

import { propOr } from 'ramda';
import useFeatureFlags from '../../../utils/useFeatureFlags';
import { useSelector } from '../../../hooks';
import { Link } from '../../../routing';
import { makePathRelative } from '../../../utils/routing';
import {
  ChatMenuItem,
  Container,
  Separator,
  SideMenuContainer,
  SideMenuItemChild,
  SideMenuItemParent,
  WhatsAppItem,
} from './styles';
import i18n from '../../../utils/i18n';
import selectUcdePortalElements, {
  selectHasHpOnePrintersActiveSubscription,
  selectHasPlatinumServiceActiveSubscription,
  selectHasPrintPlansActiveSubscription,
  selectPortalElementsProgramLevel,
} from '../../../store/modules/portalElements/selectors';
import { selectLocation } from '../../../selectors/routing';
import {
  collapseSideMenu,
} from './collapseSideMenu';
import { selectHelpLinks as selectHelpLinksCraft } from '../../../store/modules/helpCenter/selector';
import { selectHelpLinks as selectHelpLinksCs } from '../../../store/modules/helpCenter/contentStackSelector';
import getMenuItemsObject from './menuItems';
import Images from '../../../assets/images';
import { selectUcdeUser } from '../../../store/modules/ucdeUser/selectors';
import { getCookie } from '../../../utils/globals';
import { USER_TYPE } from '../../../constants/cookieNames';
import { canShowVA, openVA } from '../../../utils/virtualAgent';
import { WHATSAPP_SUPPORT_SUPPORTED_COUNTRIES } from '../../../constants/i18n';
import { getUserProfile } from '../../../api/UCDEGateway';
import { SERVICES } from '../../../utils/constants';

const SideNav = ({ match }) => {
  const programLevel = useSelector(selectPortalElementsProgramLevel) || getCookie(USER_TYPE);
  const ucdePortalElements = useSelector(selectUcdePortalElements());
  const [countryHasWhatsAppSupport, setCountryHasWhatsAppSupport] = useState(false);

  const [helpLinks, setHelpLinks] = useState([]);
  const flags = useFeatureFlags();
  const { whatsAppSupport, useContentStack } = flags;

  const selectHelpLinks = useContentStack ? selectHelpLinksCs : selectHelpLinksCraft;

  useEffect(() => {
    async function loadHelpLinks() {
      const helpLinksData = await selectHelpLinks({ programLevel, ucdePortalElements });
      setHelpLinks(helpLinksData);
    }
    loadHelpLinks();
  }, []);

  useEffect(() => {
    async function fetchWhatsAppSupport() {
      const userProfile = await getUserProfile();
      const userProfileData = propOr(null, 'data', userProfile);
      const userCountry = propOr(null, 'country', userProfileData);
      if (userCountry) {
        setCountryHasWhatsAppSupport(WHATSAPP_SUPPORT_SUPPORTED_COUNTRIES.includes(
          userCountry.toLowerCase(),
        ));
      }
    }
    fetchWhatsAppSupport();
  }, []);

  const location = useSelector(selectLocation);
  const printPlansActiveSubscription = useSelector(selectHasPrintPlansActiveSubscription);
  const platinumServiceActiveSubscription = useSelector(selectHasPlatinumServiceActiveSubscription);
  const hpOnePrintersActiveSubscription = useSelector(selectHasHpOnePrintersActiveSubscription);

  const ucdeUser = useSelector(selectUcdeUser);
  const relPath = makePathRelative(match);

  const menuItems = getMenuItemsObject({
    portalElements: ucdePortalElements,
    printPlansActiveSubscription,
    platinumServiceActiveSubscription,
    flags,
  });

  function getUserSideMenuItem() {
    const getSubMenus = subMenuItem => {
      const {
        pathname, labelId, dataTestId, onClick,
      } = subMenuItem;
      const label = i18n.t(labelId);

      return (
        <SideMenuItemChild
          className="caption"
          key={`${pathname}-${label}`}
          label={label}
          link={(
            <Link
              to={relPath(pathname)}
              onClick={onClick}
            />
          )}
          selected={relPath(pathname) === location.pathname}
          {...(dataTestId ? { 'data-testid': dataTestId } : {})}
        />
      );
    };

    // filter for side nav visibility only (reacheable menu items are used to determine top nav title)
    const hidePrintPlans = hpOnePrintersActiveSubscription && !printPlansActiveSubscription;
    const menuObject = Object.keys(ucdePortalElements)
      .filter(key => !(key === SERVICES.PRINT_PLANS && hidePrintPlans))
      .reduce((acc, key) => ([
        ...acc,
        menuItems[key],
      ]), [])
      .filter(Boolean);

    return menuObject.map(menuItem => {
      const {
        pathname, labelId, Icon, dataTestId, onClick,
      } = menuItem;

      const label = i18n.t(labelId);
      const relativePath = pathname ? relPath(pathname) : '';
      const subMenus = menuItem.subMenus || [];
      const expanded = subMenus.some(subMenu => relPath(subMenu.pathname) === location.pathname);

      return (
        <SideMenuItemParent
          className="caption"
          key={`${pathname}-${label}`}
          label={label}
          icon={Icon && <Icon />}
          selected={relativePath === location.pathname || expanded}
          expanded={expanded}
          {...(dataTestId ? { 'data-testid': dataTestId } : {})}
          {...(pathname ? {
            link:
            <Link
              to={relPath(relativePath)}
              onClick={onClick}
            />,
          } : {})}
        >
          {
            subMenus.length > 0 && subMenus.map(subMenuItem => getSubMenus(subMenuItem))
          }
        </SideMenuItemParent>
      );
    });
  }

  const openVAModal = () => {
    collapseSideMenu();
    openVA({
      parameters: {
        userId: ucdeUser.externalIdMap.hpid,
        location,
        match,
      },
    });
  };

  function buildSideMenu() {
    const isSelected = helpLinks.some(navLink => (navLink.pathname === location.pathname));
    const showVirtualAgent = canShowVA(match);
    const showWhatsAppSupport = whatsAppSupport && countryHasWhatsAppSupport;

    return (
      <SideMenuContainer
        fillSelectedIcons={false}
        id="portal-side-menu"
        data-testid="portal-side-menu"
        showIcons
        logo={<LogoHp />}
        appName={i18n.t('utils.pageTitle')}
        responsiveBreakpoint="1199px"
      >
        {getUserSideMenuItem()}
        <SideMenuItemParent
          className="caption"
          label={i18n.t('helpCenter.headers.mainHeader')}
          icon={<IconHelp />}
          selected={isSelected}
        >
          {helpLinks.map(navLink => (
            <SideMenuItemChild
              className="caption"
              key={navLink.pathname}
              label={navLink.label}
              selected={navLink.pathname === location.pathname}
              link={(
                <Link
                  smart
                  to={navLink.pathname}
                  onClick={collapseSideMenu}
                />
              )}
            />
          ))}
        </SideMenuItemParent>
        <Separator />
        {showVirtualAgent
          ? (
            <ChatMenuItem
              className="caption"
              data-testid="portal-side-menu-va-button"
              label={(
                <span>
                  {i18n.t('virtualAgent.headers.mainHeader')}
                  <br />
                  {i18n.t('virtualAgent.title')}
                </span>
              )}
              icon={<IconChat />}
              onClick={openVAModal}
            />
          )
          : <React.Fragment />}
        {/* TODO: use custom svg icon */}
        {showWhatsAppSupport
          ? (
            <WhatsAppItem
              className="caption"
              label={(
                <span>
                  {i18n.t('whatsAppSupport.line1')}
                  <br />
                  WhatsApp
                  <br />
                  <span>+44 20 3823 6694</span>
                </span>
              )}
              icon={<CustomImage src={Images.whatsAppSupport} />}
              link={(
                <Link
                  to="https://api.whatsapp.com/send?phone=442038236694"
                  rel="noopener noreferrer"
                />
              )}
              onClick={collapseSideMenu}
              urlTarget="_blank"
            />
          )
          : <React.Fragment />}
      </SideMenuContainer>
    );
  }

  return <Container>{buildSideMenu()}</Container>;
};

SideNav.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SideNav;
