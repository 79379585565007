import SideMenu from '@veneer/core/dist/scripts/side_menu';
import SideMenuItem from '@veneer/core/dist/scripts/side_menu_item';
import styled from 'styled-components';

export const Container = styled.div`
  && {
    .vn-side-menu__toggle {
      background-color: transparent;
      svg {
        fill: ${props => props.theme.main.hp.color};
      }
    }

    @media (min-width: 1200px) {
      ${props => props.theme.hostIsDesktopApp && `
        .vn-side-menu__toggle {
          visibility: hidden;
        }
      `}
    }
  }
`;

export const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid #EBEBEB;
  width: calc(100% - 2 * 15px);
  margin-top: 23px;
  margin-bottom: 20px;
`;

export const SideMenuItemParent = styled(SideMenuItem)`
  font-weight: 700;
`;

export const SideMenuItemChild = styled(SideMenuItem)`
  font-weight: 400;
`;

export const ChatMenuItem = styled(SideMenuItem)`
  * {
    color: #027AAE;
  }

  && a {
    align-items: center;
  }
`;

export const WhatsAppItem = styled(SideMenuItem)`
  span {
    span {
      color: #027AAE;
    }
  }
`;

export const SideMenuContainer = styled(SideMenu)`
`;
