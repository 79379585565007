import IconAccount from '@veneer/core/dist/scripts/icons/icon_person_circle';
import IconGlobe from '@veneer/core/dist/scripts/icons/icon_globe';
import IconHandStars from '@veneer/core/dist/scripts/icons/icon_hand_stars';
import IconHome from '@veneer/core/dist/scripts/icons/icon_home';
import IconPeople from '@veneer/core/dist/scripts/icons/icon_people';
import IconPrinter from '@veneer/core/dist/scripts/icons/icon_printer';
import IconTruck from '@veneer/core/dist/scripts/icons/icon_truck';
import IconStar from '@veneer/core/dist/scripts/icons/icon_star';
import IconHpOnePc from '@veneer/core/dist/scripts/icons/icon_devices_laptop_mouse';
import IconHpOnePrinter from '@veneer/core/dist/scripts/icons/icon_devices_laptop_printer';
import { path, pathOr } from 'ramda';
import {
  collapseSideMenu,
} from './collapseSideMenu';
import {
  HOME_PAGE,
  PATHS,
  SERVICES,
  SERVICE_TAGS,
} from '../../../utils/constants';
import { serviceEnabled } from '../../../utils/portalElements';
import { registerClickEvent } from '../../../utils/analytics';

function getPrintPlansMenuObject(printPlansActiveSubscription, flags) {
  const { instantInkMenusPhase1, instantInkMenusPhase2 } = flags;
  const baseMenuItemObject = {
    dataTestId: 'print-plans-menu',
    Icon: IconTruck,
    labelId: 'hpPrintPlans.headers.mainHeader',
  };

  if (printPlansActiveSubscription) {
    return {
      ...baseMenuItemObject,
      subMenus: [
        {
          pathname: PATHS.PRINT_PLANS,
          labelId: instantInkMenusPhase1 ? 'hpPrintPlans.headers.planOverviewII' : 'hpPrintPlans.headers.planOverview',
          dataTestId: 'plan-overview-submenu',
          onClick: collapseSideMenu,
        },
        {
          pathname: '/print_plans/my_account',
          labelId: instantInkMenusPhase1 ? 'hpPrintPlans.headers.changePlanII' : 'hpPrintPlans.headers.changePlan',
          dataTestId: 'change-plan-submenu',
          onClick: collapseSideMenu,
        },
        !instantInkMenusPhase2 && {
          pathname: '/print_plans/print_history',
          labelId: 'hpPrintPlans.headers.printHistory',
          dataTestId: 'print-history-submenu',
          onClick: collapseSideMenu,
        },
        instantInkMenusPhase2 && {
          pathname: '/print_plans/account_history',
          labelId: 'hpPrintPlans.headers.printPaymentHistory',
          dataTestId: 'print-history-submenu',
          onClick: collapseSideMenu,
        },
        {
          pathname: '/print_plans/ink_shipment',
          labelId: 'hpPrintPlans.headers.shipmentTracking',
          dataTestId: 'shipment-tracking-submenu',
          onClick: collapseSideMenu,
        },
        !instantInkMenusPhase2 && {
          pathname: '/print_plans/account_history',
          labelId: 'hpPrintPlans.headers.planActivity',
          dataTestId: 'plan-activity-submenu',
          onClick: collapseSideMenu,
        },
      ].filter(Boolean),
    };
  }

  return {
    ...baseMenuItemObject,
    pathname: '/print_plans',
    onClick: collapseSideMenu,
  };
}

function getPlatinumServicesMenuObject() {
  return {
    dataTestId: 'platinum-services-menu',
    Icon: IconStar,
    labelId: 'hpPlatinumService.headers.mainHeader',
    subMenus: [
      {
        pathname: `${PATHS.PAAS_DASHBOARD.OVERVIEW}`,
        labelId: 'hpPlatinumService.headers.planOverview',
        dataTestId: 'platinum-services-overview-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: `${PATHS.PAAS_DASHBOARD.UPDATE_PLAN}`,
        labelId: 'hpPlatinumService.headers.changePlan',
        dataTestId: 'platinum-services-update-plan-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: `${PATHS.PAAS_DASHBOARD.HISTORY}`,
        labelId: 'hpPlatinumService.headers.printPaymentHistory',
        dataTestId: 'platinum-services-print-and-payment-history-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: `${PATHS.PAAS_DASHBOARD.SHIPMENT_TRACKING}`,
        labelId: 'hpPlatinumService.headers.shipmentTracking',
        dataTestId: 'platinum-services-shipment-tracking-submenu',
        onClick: collapseSideMenu,
      },
    ],
  };
}

function getServicesMenuObject({ flags, portalElements }) {
  let subMenus = [];

  if (serviceEnabled(portalElements, SERVICES.PRINT_ON_THE_GO)) {
    subMenus = [
      {
        key: SERVICES.PRINT_ON_THE_GO,
        pathname: '/services/print-on-the-go',
        labelId: 'manageHPBenefits.headers.printAnywhere',
        subscription: true,
        dataTestId: 'print-on-the-go-submenu',
        onClick: collapseSideMenu,
      },
    ];
  }

  // While HPPKCLD-3756  is not done we need this flag so we can test SA in all stacks and to ensure SA is not show in Prod
  const showSmartAdvance = flags.hpSmartAdvance && serviceEnabled(portalElements, SERVICES.HP_SMART_ADVANCE);

  if (showSmartAdvance) {
    subMenus = [
      ...subMenus,
      {
        key: SERVICES.HP_SMART_ADVANCE,
        pathname: PATHS.SERVICES.HP_SMART_ADVANCE,
        labelId: 'manageHPBenefits.headers.hpSmartAdvance',
        dataTestId: 'hp-smart-advance-submenu',
        onClick: () => {
          registerClickEvent({
            linkPlacement: 'solutions-menu',
            linkID: 'hp-smart-advance',
          });
          collapseSideMenu();
        },
      },
    ];
  }

  if (serviceEnabled(portalElements, SERVICES.SMART_SECURITY)) {
    subMenus = [
      ...subMenus,
      {
        key: SERVICES.SMART_SECURITY,
        pathname: '/services/smart-security',
        labelId: 'manageHPBenefits.headers.securityMonitoring',
        subscription: true,
        dataTestId: 'smart-security-submenu',
        onClick: collapseSideMenu,
      },
    ];
  }

  if (serviceEnabled(portalElements, SERVICES.EPRINT)) {
    subMenus = [
      ...subMenus,
      {
        pathname: '/services/summary',
        labelId: 'home.benefitsStatus.otherFeatures',
        dataTestId: 'services-summary-submenu',
        onClick: collapseSideMenu,
      },
    ];
  }

  if (subMenus.length) {
    return {
      services: {
        labelId: 'manageHPBenefits.headers.mainHeaderV2',
        Icon: IconHandStars,
        dataTestId: 'services-menu',
        subMenus,
      },
    };
  }

  return {};
}

function getAccountDetailsMenuObject(
  printPlansActiveSubscription,
  platinumServiceActiveSubscription,
  portalElements,
  flags,
) {
  const {
    notificationSettings,
    useShippingBillingBeta,
    useStatementsBeta,
    viewNotificationsPersonalAccount,
    checkRoleForCommerceMenus,
  } = flags;
  const isOwner = !checkRoleForCommerceMenus || portalElements?.info?.role === 'Owner';

  const showOrders = isOwner && portalElements?.accountDetails?.items?.orders;
  const showInvoices = isOwner && portalElements?.accountDetails?.items?.invoices;
  const showShippingBilling = isOwner && portalElements?.accountDetails?.items?.shippingBilling;
  const showSubscriptions = isOwner && portalElements?.accountDetails?.items?.subscriptions;

  const showShippingMenu = portalElements?.accountDetails?.items?.shipping;
  const showBillingMenu = portalElements?.accountDetails?.items?.billing;

  const showProfileMenu = portalElements?.accountDetails?.items?.profile;
  const showUsersMenu = portalElements?.accountDetails?.items?.users;
  const showNotificationsMenu = viewNotificationsPersonalAccount
    ? portalElements?.accountDetails?.items?.notifications : true;

  const menuItemObject = {
    labelId: 'myAccount.headers.mainHeaderV2',
    Icon: IconAccount,
    dataTestId: 'my-account-menu',
    subMenus: [
      showSubscriptions && {
        pathname: PATHS.ACCOUNT_DETAILS.SUBSCRIPTIONS,
        labelId: 'myAccount.headers.subscriptions',
        dataTestId: 'subscriptions-submenu',
        onClick: collapseSideMenu,
      },
      showOrders && {
        pathname: PATHS.ACCOUNT_DETAILS.ORDERS,
        labelId: 'myAccount.headers.orders',
        dataTestId: 'orders-submenu',
        onClick: collapseSideMenu,
      },
      showInvoices && {
        pathname: PATHS.ACCOUNT_DETAILS.STATEMENTS,
        labelId: useStatementsBeta ? 'myAccount.headers.invoicesBeta' : 'myAccount.headers.invoices',
        dataTestId: 'invoices-submenu',
        onClick: collapseSideMenu,
      },
      showShippingBilling && {
        pathname: PATHS.ACCOUNT_DETAILS.SHIPPING_BILLING,
        labelId: useShippingBillingBeta ? 'myAccount.headers.shippingBillingBeta' : 'myAccount.headers.shippingBilling',
        dataTestId: 'shipping-billing-submenu',
        onClick: collapseSideMenu,
      },
      showBillingMenu && {
        pathname: PATHS.ACCOUNT_DETAILS.PRINT_PLANS_BILLING_INFORMATION,
        labelId: 'myAccount.headers.billing',
        dataTestId: 'billing-submenu',
        onClick: collapseSideMenu,
      },
      showShippingMenu && {
        pathname: PATHS.ACCOUNT_DETAILS.PRINT_PLANS_SHIPPING_INFORMATION,
        labelId: 'myAccount.headers.shipping',
        dataTestId: 'shipping-submenu',
        onClick: collapseSideMenu,
      },
      showProfileMenu && {
        pathname: PATHS.ACCOUNT_DETAILS.PROFILE,
        labelId: 'myAccount.headers.profile',
        dataTestId: 'profile-submenu',
        onClick: collapseSideMenu,
      },
      showUsersMenu && {
        pathname: PATHS.ACCOUNT_DETAILS.USERS,
        labelId: 'manageUsers.topOfPage.mainHeaderV2',
        dataTestId: 'users-submenu',
        onClick: collapseSideMenu,
      },
      showNotificationsMenu && {
        pathname: PATHS.ACCOUNT_DETAILS.VIEW_NOTIFICATIONS,
        labelId: 'myAccount.headers.viewNotifications',
        dataTestId: 'view-notifications-submenu',
        onClick: collapseSideMenu,
      },
      notificationSettings && {
        pathname: PATHS.ACCOUNT_DETAILS.NOTIFICATIONS_SETTINGS,
        labelId: 'myAccount.notificationSettings.mainHeader',
        dataTestId: 'notification-submenu',
        onClick: collapseSideMenu,
      },
    ].filter(Boolean),
  };

  return menuItemObject;
}

function getHpOnePCMenuObject(portalElements) {
  const useHpOnePCs = portalElements.hpOnePCs?.tags?.includes(SERVICE_TAGS.SEE_HP_ONE_PCS_MENU);
  return {
    ...(useHpOnePCs && {
      hpOnePCs: {
        dataTestId: 'hp-one-pcs-menu',
        Icon: IconHpOnePc,
        labelId: 'hpOnePC.headers.mainHeader',
        pathname: PATHS.HP_ONE_PC,
        onClick: collapseSideMenu,
      },
    }),
  };
}

function getHpOnePrinterMenuObject(portalElements, flags) {
  const {
    hpxHpOnePrintersPrintHistory,
  } = flags;
  const useHpOnePrinters = portalElements.hpOnePrinters?.tags?.includes(SERVICE_TAGS.SEE_HP_ONE_PRINTERS_MENU);
  return {
    ...(useHpOnePrinters && {
      hpOnePrinters: {
        dataTestId: 'hp-one-printers-menu',
        Icon: IconHpOnePrinter,
        labelId: 'hpOnePrinter.headers.mainHeader',
        subMenus: [
          {
            pathname: PATHS.HP_ONE_PRINTER.OVERVIEW,
            labelId: 'hpOnePrinter.headers.overview',
            dataTestId: 'hp-one-printers-overview-submenu',
            onClick: collapseSideMenu,
          },
          {
            pathname: PATHS.HP_ONE_PRINTER.UPDATE_PLAN,
            labelId: 'hpOnePrinter.headers.updatePlan',
            dataTestId: 'hp-one-printers-update-plan-submenu',
            onClick: collapseSideMenu,
          },
          hpxHpOnePrintersPrintHistory && {
            pathname: PATHS.HP_ONE_PRINTER.PRINT_HISTORY,
            labelId: 'hpOnePrinter.headers.printHistory',
            dataTestId: 'hp-one-printers-print-history-submenu',
            onClick: collapseSideMenu,
          },
          {
            pathname: PATHS.HP_ONE_PRINTER.SHIPMENT_TRACKING,
            labelId: 'hpOnePrinter.headers.shipmentTracking',
            dataTestId: 'hp-one-printers-shipment-tracking-submenu',
            onClick: collapseSideMenu,
          },
        ].filter(Boolean),
      },
    }),
  };
}

function getMenuItemsObject(opts = {}) {
  const {
    flags,
    portalElements = {},
    printPlansActiveSubscription = false,
    platinumServiceActiveSubscription = false,
  } = opts;
  const menuItems = {
    ...(getHpOnePCMenuObject(portalElements)),
    ...(getHpOnePrinterMenuObject(portalElements, flags)),
    printPlans: getPrintPlansMenuObject(printPlansActiveSubscription, flags),
    ...(flags.jshellPaaSDashboard
      ? { platinumServices: getPlatinumServicesMenuObject() }
      : {}),
    myPrinters: {
      pathname: '/my-printers',
      labelId: flags.hpxDevices ? 'devices.headers.mainHeader' : 'myPrinters.headers.mainHeaderV2',
      Icon: IconPrinter,
      dataTestId: 'my-printers-menu',
      onClick: collapseSideMenu,
    },
    // Features
    ...(getServicesMenuObject({ flags, portalElements })),
    // My Account
    accountDetails: getAccountDetailsMenuObject(
      printPlansActiveSubscription,
      platinumServiceActiveSubscription,
      portalElements,
      flags,
    ),
    // ForestFirst
    ...(flags.forestFirst && path(['sustainability', 'items', 'forestFirst'], portalElements)
      ? {
        sustainability: {
          labelId: 'sustainability.headers.mainHeader',
          Icon: IconGlobe,
          dataTestId: 'sustainability-menu',
          subMenus: [
            {
              pathname: '/sustainability/forest-first',
              labelId: 'sustainability.headers.forestFirst',
              Icon: IconPeople,
              dataTestId: 'forest-first-submenu',
              onClick: () => {
                registerClickEvent({
                  linkPlacement: 'sustainability-menu',
                  linkID: 'forest-first',
                });
                collapseSideMenu();
              },
            },
          ],
        },
      }
      : {}
    ),
  };

  if (pathOr([], ['home', 'tags', 0], portalElements) === HOME_PAGE.SMART_DASHBOARD
    || pathOr([], ['home', 'tags', 0], portalElements) === HOME_PAGE.STANDARD_DASHBOARD) {
    return {
      home: {
        pathname: '/',
        labelId: 'homev2.mainHeader',
        title: 'homev2.mainHeader',
        Icon: IconHome,
        dataTestId: 'home-menu',
        onClick: collapseSideMenu,
      },
      ...menuItems,
    };
  }

  return menuItems;
}

export default getMenuItemsObject;
