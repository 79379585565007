import { pathOr } from 'ramda';
import Config from '../config';
import { getVirtualAgentBotConversation } from '../api/UCDEGateway';
import {
  LANGUAGES,
  SIMPLIFIED_CHINESE_COUNTRIES,
  VIRTUAL_AGENT_SUPPORTED_LANGUAGES,
} from '../constants/i18n';
import VirtualAgent from '../components/VirtualAgent';
import { renderModal } from '../components/CustomModal';

const determineOS = () => {
  const { navigator } = window;
  if (navigator.appVersion.indexOf('Android') !== -1) {
    return 'Android';
  }
  if (navigator.appVersion.indexOf('iPhone') !== -1) {
    return 'iOS';
  }
  if (navigator.appVersion.indexOf('Win') !== -1) {
    return 'Windows';
  }
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }
  if (navigator.appVersion.indexOf('X11') !== -1) {
    return 'UNIX';
  }
  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return 'Linux';
  }

  return 'UnknownOS';
};

const vaLinkBase = Config.VIRTUAL_AGENT_BASE_URL;

const findVALaunchPoint = (match, location) => {
  const ucdeBase = `/${pathOr('us', ['params', 'country'], match)}/`
    + `${pathOr('en', ['params', 'language'], match)}/ucde`;
  if (location.pathname === ucdeBase) {
    return 'UCDEHome';
  }

  return location.pathname.slice(12);
};

export const createVALinkWithToken = async (match, location, userId, sessionID) => {
  const { language } = match.params;
  const launchPoint = findVALaunchPoint(match, location);
  const devOs = determineOS();

  const virtualAgentConversation = await getVirtualAgentBotConversation(userId, language, launchPoint, devOs);

  const vaLink = `${vaLinkBase}${virtualAgentConversation.launchUrl}`
    + `&sessionID=${sessionID}`;

  return vaLink;
};

export const canShowVA = ({ params: { language, country } }) => (
  VIRTUAL_AGENT_SUPPORTED_LANGUAGES.includes(language)
  && (language !== LANGUAGES.ZH || SIMPLIFIED_CHINESE_COUNTRIES.has(country))
);

export const openVA = ({ parameters }) => {
  renderModal({
    content: VirtualAgent,
    parameters,
  });
};
