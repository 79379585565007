const GET_DEVICES = '@ucde/GET_DEVICES';
const GET_DEVICES_FAILED = '@ucde/GET_DEVICES_FAILED';
const GET_DETAILED_DEVICES = '@ucde/GET_DETAILED_DEVICES';
const GET_DETAILED_DEVICES_FAILED = '@ucde/GET_DETAILED_DEVICES_FAILED';
const GET_DEVICE_SUPPLIES = '@ucde/GET_DEVICE_SUPPLIES';
const GET_DETAILED_DEVICES_SUCCESS = '@ucde/GET_DETAILED_DEVICES_SUCCESS';

export default {
  GET_DEVICES,
  GET_DEVICES_FAILED,
  GET_DETAILED_DEVICES,
  GET_DETAILED_DEVICES_FAILED,
  GET_DEVICE_SUPPLIES,
  GET_DETAILED_DEVICES_SUCCESS,
};
