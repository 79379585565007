import Types from './types';

const INITIAL_STATE = {};

export default function jWeb(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_JWEB_LANGUAGE_CODE_SUCCESS:
      return {
        ...state,
        languageCode: {
          ...action.payload.data,
        },
      };
    case Types.LOAD_JWEB_LANGUAGE_CODE_FAILED:
      return {
        ...state,
        languageCode: {},
      };
    case Types.LOAD_JWEB_SIGNIN_REQUEST:
      return {
        ...state,
        jWebSignInLoading: true,
        jWebSignInFailed: false,
      };
    case Types.LOAD_JWEB_SIGNIN_SUCCESS:
      return {
        ...state,
        jWebSignInLoading: false,
        jWebSignInFailed: false,
      };
    case Types.LOAD_JWEB_SIGNIN_FAILED:
      return {
        ...state,
        jWebSignInFailed: true,
        jWebSignInLoading: false,
      };
    default:
      return state;
  }
}
