import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '@veneer/core/dist/scripts/button';
import { selectUcdeUser, selectUcdeUserFullName } from '../../../../store/modules/ucdeUser/selectors';
import { MenuContainer, MenuModal } from './styles';
import AvatarWithOrgSelector from '../../../AvatarWithOrgSelector';
import UserData from './UserData';
import OrgList from './OrgList';
import { makePathRelative } from '../../../../utils/routing';
import { selectRootMatch } from '../../../../selectors/routing';
import { PATHS } from '../../../../utils/constants';
import i18n from '../../../../utils/i18n';
import useFeatureFlags from '../../../../utils/useFeatureFlags';

const ProfileMenuWithOrgSelector = ({ orgList, isLoading }) => {
  const dispatch = useDispatch();
  const match = useSelector(selectRootMatch);
  const relPath = makePathRelative(match);

  const [showModal, setShowModal] = useState(false);

  const userFullName = useSelector(selectUcdeUserFullName);
  const userProfile = useSelector(selectUcdeUser);
  const { newTenancyModel } = useFeatureFlags();

  const closeMenuCallback = () => setShowModal(false);

  const logout = () => {
    dispatch(push(relPath(PATHS.SIGNOUT)));
  };

  window.addEventListener('click', e => {
    if (!document.getElementById('menu-avatar-container')?.contains(e.target)) {
      setShowModal(false);
    }
  });

  return (
    <MenuContainer
      id="menu-avatar-container"
    >
      <AvatarWithOrgSelector
        data-testid="avatar-with-org-selector"
        userFullName={userFullName}
        isLoading={isLoading}
        {...(!isLoading
          && { onClick: () => setShowModal(prev => !prev) }
        )}
      />
      {showModal && (
        <MenuModal
          data-testid="menu-modal"
          appearance="dropShadow"
          content={(
            <>
              <UserData
                data-testid="user-data"
                user={userProfile}
                userFullName={userFullName}
                callback={closeMenuCallback}
              />
              {newTenancyModel && (
                <OrgList
                  data-testid="org-list"
                  orgList={orgList}
                />
              )}
              <Button
                tabIndex={-1}
                onClick={logout}
                appearance="secondary"
                expanded
              >
                {i18n.t('home.accountIcon.overlayMenu')}
              </Button>
            </>
          )}
        />
      )}
    </MenuContainer>
  );
};

ProfileMenuWithOrgSelector.propTypes = {
  orgList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
};

ProfileMenuWithOrgSelector.defaultProps = {
  isLoading: false,
};

export default ProfileMenuWithOrgSelector;
