import styled from 'styled-components';
import Modal from '@veneer/core/dist/scripts/modal/modal';

export const SubHeader = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`;

export const OrgSelectorModal = styled(Modal)`
  .vn-modal--dialog {
    width: 100%;

    .vn-modal--content {
      width: calc(100% - 48px);
      max-width: 480px;
    }
  }

  @media (max-width: 767px) {
    && {
      .vn-modal--dialog {
        width: 100%;

        .vn-modal--content {
          width: calc(100% - 48px);
          word-wrap: break-word;
        }
      }
    }
  }
`;
