import React from 'react';
import PropTypes from 'prop-types';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import Avatar from '@veneer/core/dist/scripts/avatar';
import { Container } from './styles';

function formatText(text) {
  if (!text) {
    return '';
  }

  const splitted = (text.indexOf(' ') === 0) ? text : text.split(' ');
  const len = splitted.length;

  if (len === 1) {
    return splitted[0].substring(0, 1).toUpperCase();
  }

  return splitted[0].substring(0, 1).concat(splitted[len - 1].substring(0, 1)).toUpperCase();
}

const AvatarWithOrgSelector = ({
  userFullName, onClick, isLoading,
}) => (
  <Container
    isLoading={isLoading}
  >
    <Avatar
      onClick={onClick}
      {...(isLoading
        ? { icon: <ProgressIndicator /> }
        : { label: formatText(userFullName) }
      )}
    />
  </Container>
);

AvatarWithOrgSelector.propTypes = {
  userFullName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AvatarWithOrgSelector.defaultProps = {
  isLoading: false,
};

export default AvatarWithOrgSelector;
