import { push } from 'connected-react-router';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import crypto from 'crypto';
import { jWebReady } from '@jarvis/jweb-core';
import {
  has,
  path,
  pathSatisfies,
} from 'ramda';
import { store } from '../../App';
import { getExchangeToken, tokenExchange } from '../../api/HPCSession';
import { getCookie, setCookie } from '../globals';
import {
  SELECTED_ORG_ID,
  STRATUS_ACCESS_TOKEN,
  STRATUS_BASE_TOKEN, STRATUS_ID_TOKEN, USER_TYPE,
} from '../../constants/cookieNames';
import SCOPES from '../../constants/localStorage';
import Config from '../../config';
import {
  encodeAuthState,
  encodeSignInState,
  makePathRelative,
} from '../routing';
import { JWEB_EVENTS, PATHS } from '../constants';
import { selectPathname, selectRootMatch } from '../../selectors/routing';
import refreshToken from './refreshToken';
import { getProgramLevel, getUserProfile } from '../../api/UCDEGateway';
import { JWEB_MAX_THRESHOLD_TIME, JWEB_THRESHOLD_TIME } from '../../constants/criticalScopes';
import { initializeUcdeUser } from '../../store/modules/ucdeUser/actions';

const refreshThreshold = 5 * 60000; // 5 minutes

export const isJWebNative = () => window.JWeb?.isNative;
const getJWebAuthPlugin = () => window.JWeb?.Plugins?.Auth;
export const getStratusAccessToken = () => getCookie(STRATUS_ACCESS_TOKEN);
export const setStratusAccessToken = token => setCookie(STRATUS_ACCESS_TOKEN, token);
export const getStratusIdToken = () => getCookie(STRATUS_ID_TOKEN);
export const getStratusBaseToken = () => getCookie(STRATUS_BASE_TOKEN);

export const getSelectedOrgId = () => getCookie(SELECTED_ORG_ID);
export const setSelectedOrgId = id => setCookie(SELECTED_ORG_ID, id);

export const removeLocalStorageScopesItem = () => localStorage.removeItem(SCOPES);

export const decodeToken = token => {
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    return null;
  }
};

export const getDecodedStratusAccessToken = () => {
  const token = getStratusAccessToken();
  return decodeToken(token);
};

export const isStratusAccessTokenV1 = (token = getDecodedStratusAccessToken()) => !has('tenant_id', token);

export const isStratusAccessTokenV2Base = (token = getDecodedStratusAccessToken()) => pathSatisfies(
  tentantId => tentantId === ' ', ['tenant_id'], token,
);

export const isStratusAccessTokenExpired = (token = getDecodedStratusAccessToken()) => {
  if (!token) {
    return false;
  }

  const decodedExpiration = token.exp;
  const decodedExpirationInMilli = decodedExpiration * 1000;
  const exp = new Date(decodedExpirationInMilli);
  return new Date() > exp;
};

export const isStratusAccessTokenRefreshable = (token = getDecodedStratusAccessToken()) => {
  if (!token) {
    return false;
  }

  const decodedExpiration = token.exp;
  const decodedExpirationInMilli = decodedExpiration * 1000;
  const minimumToRefresh = new Date(decodedExpirationInMilli - refreshThreshold);
  return new Date() > minimumToRefresh;
};

export const ifJWebAvailable = (jWebReadyCallback, jWebMissingCallback) => {
  jWebReady
    .then(jweb => {
      if (jweb?.isNative) {
        jWebReadyCallback();
      } else {
        jWebMissingCallback();
      }
    })
    .catch(() => {
      jWebMissingCallback();
    });
};

const getTokenProviderOptions = (requireFreshToken, skipTokenRefresh, tenantId) => {
  const options = {
    allowUserInteraction: true,
    skipTokenRefresh,
  };

  // token-exchange
  if (tenantId) {
    options.tenantID = tenantId;

    return {
      tokenProviderOptions: options,
    };
  }

  // refresh token
  if (requireFreshToken) {
    options.tokenLifetimeRequirements = [{ type: 'preferredMaximumSecondsSinceIssued', timeInterval: 0 }];
  }

  // sign-in screen
  if (requireFreshToken && skipTokenRefresh) {
    options.scopesRequested = ['openid'];
  }

  return {
    tokenProviderOptions: options,
  };
};

export const getJWebAccessToken = async (requireFreshToken = false, skipTokenRefresh = false, tenantId = '') => {
  const Auth = getJWebAuthPlugin();
  const tokenProviderOptions = getTokenProviderOptions(requireFreshToken, skipTokenRefresh, tenantId);
  const accessToken = await Auth.getToken(tokenProviderOptions);
  return accessToken;
};

export const exchangeToken = async accessToken => {
  await getExchangeToken(accessToken);
};

export const exchangeOrgToken = async org => {
  await tokenExchange(org);

  const programLevelResponse = await getProgramLevel();

  if (path(['status'], programLevelResponse) === 200) {
    setCookie(USER_TYPE, path(['data'], programLevelResponse));
  }
};

export const jwebSignIn = async ({ requireFreshToken = false, skipTokenRefresh = false, tenantId = '' } = {}) => {
  if (!getStratusAccessToken() || isStratusAccessTokenExpired() || requireFreshToken || tenantId) {
    const accessToken = await getJWebAccessToken(requireFreshToken, skipTokenRefresh, tenantId);
    const { tokenValue, error } = accessToken;
    if (error) {
      throw error;
    }
    await exchangeToken(tokenValue);
  }
};

// When we are calling it we already made the async check for jweb availability,
// so we assume that JWeb is available or not
export const isJWebDesktopApp = () => !isMobile && isJWebNative();

export const isJWebiOSApp = () => isIOS && isJWebNative();

export const isJWebAndroidApp = () => isAndroid && isJWebNative();

export const isJWebApp = () => isJWebDesktopApp() || isJWebAndroidApp() || isJWebiOSApp();

export const isJWebEventingPluginValid = () => window.JWeb?.Plugins?.Eventing;

export const dispatchJWebCloseEvent = () => {
  const Eventing = isJWebEventingPluginValid();
  if (!Eventing) {
    console.error('JWeb.Plugins.Eventing is not valid.');
    return;
  }

  try {
    Eventing.dispatchEvent({ name: JWEB_EVENTS.JARVIS_FINISHED, data: {} });
    Eventing.dispatchEvent({ name: JWEB_EVENTS.CLOSE, data: {} });
  } catch (err) {
    console.error(`Error dispatching close events to JWeb:\n${err}`);
  }
};

export const dispatchJWebCleanupAccount = () => {
  const Eventing = isJWebEventingPluginValid();
  if (!Eventing) {
    console.error('JWeb.Plugins.Eventing is not valid.');
    return;
  }

  try {
    Eventing.dispatchEvent({ name: JWEB_EVENTS.CLEANUP_ACCOUNT_DELETION, data: {} });
  } catch (err) {
    console.error(`Error dispatching close events to JWeb:\n${err}`);
  }
};

export const loginUrlBuilder = async (shouldExchangeToken, shouldForceLogin, baseUrl, redirectUrl = '',
  stratusClientId) => {
  const relPath = makePathRelative({ url: baseUrl });
  const redirectUrlRelativePath = relPath(redirectUrl);
  if (isJWebNative()) {
    await jwebSignIn({ requireFreshToken: true, skipTokenRefresh: true });
    return redirectUrlRelativePath;
  }
  const handshake = crypto.randomBytes(32).toString('hex');
  const state = encodeAuthState(handshake, redirectUrlRelativePath);
  const signinUrl = Config.createSigninUrl(state, shouldForceLogin, stratusClientId);
  if (shouldExchangeToken) {
    const token = getStratusAccessToken();
    await getExchangeToken(token);
  }

  return signinUrl;
};

export const redirectToSignIn = ({ forceLogin, url } = {}) => {
  const reduxState = store.getState();
  const rootMatch = selectRootMatch(reduxState);
  const currentPath = selectPathname(reduxState);

  const relPath = makePathRelative(rootMatch);

  // If no url was supplied, return to the current page.
  const redirectUrl = url || currentPath;

  const state = encodeSignInState(relPath(redirectUrl), forceLogin);
  store.dispatch(push(relPath(`${PATHS.SIGNIN}?state=${state}`)));
};

// Gets the Stratus access token from the cookies.
// If requireRefresh is set, will refresh the cookie and then return it.
export const getAccessToken = async ({ requireRefresh } = {}) => {
  if (Config.isHpxApp) {
    const Auth = getJWebAuthPlugin();

    const getTokenOptions = {
      tokenProviderOptions: {
        skipTokenRefresh: true,
      },
    };

    const accessToken = await Auth.getToken(getTokenOptions);
    const { tokenValue, error } = accessToken;
    if (error) {
      throw error;
    }

    return tokenValue;
  }

  await refreshToken({ requireRefresh });
  return getStratusAccessToken();
};

export const getOrglessAccessToken = async ({ requireRefresh } = {}) => {
  await refreshToken({ requireRefresh });
  return getStratusBaseToken();
};

export const jarvisAuthProvider = {
  getAccessToken: async requireRefresh => (
    getAccessToken({ requireRefresh })
  ),
  forceLogin: url => (
    redirectToSignIn({ forceLogin: true, url })
  ),
  getOrglessAccessToken: async requireRefresh => (
    getOrglessAccessToken({ requireRefresh })
  ),
};

export const getUniqueUserKey = async () => {
  if (!getStratusAccessToken() || isStratusAccessTokenV2Base()) {
    return null;
  }

  const userProfile = await getUserProfile();
  const country = path(['data', 'country'], userProfile);

  if (country) {
    const idHasher = crypto.createHash('sha256');
    idHasher.update(country);
    return idHasher.digest('hex');
  }

  return null;
};

export const getDateDifferenceInSeconds = (firstDate, secondDate) => {
  const differenceInMilliseconds = firstDate - secondDate;
  return Math.floor(differenceInMilliseconds / 1000);
};

export const getForceLoginThreshold = () => {
  if (isJWebNative()) {
    const decodedAccessToken = getDecodedStratusAccessToken();
    const tokenCreationDate = new Date(decodedAccessToken.iat * 1000);
    return Math.min(JWEB_MAX_THRESHOLD_TIME,
      Math.max(0, JWEB_THRESHOLD_TIME - getDateDifferenceInSeconds(new Date(), tokenCreationDate)));
  }

  return 0;
};

export const forceLogin = async (args = {}) => {
  const {
    nativeErrorCallback = () => {},
    nativePostLoginCallback = () => {},
    url,
  } = args;

  const reduxState = store.getState();
  const rootMatch = selectRootMatch(reduxState);
  const currentPath = selectPathname(reduxState);
  const relPath = makePathRelative(rootMatch);
  const redirectUrl = url || currentPath;

  if (isJWebNative()) {
    try {
      const tenantId = getDecodedStratusAccessToken()?.tenant_id?.trim();
      await jwebSignIn({ requireFreshToken: true, skipTokenRefresh: true });
      await jwebSignIn({ tenantId });
      nativePostLoginCallback();
      store.dispatch(initializeUcdeUser(relPath(redirectUrl)));
    } catch (error) {
      console.error(error);
      nativeErrorCallback();
    }
  } else {
    const state = encodeSignInState(relPath(redirectUrl), true);
    store.dispatch(push(relPath(`${PATHS.SIGNIN}?state=${state}`)));
  }
};

export const isCriticalScopesValid = () => {
  const decodedAccessToken = getDecodedStratusAccessToken();
  const criticalScopesExpirationDate = new Date(decodedAccessToken.ca_exp * 1000);
  return criticalScopesExpirationDate > new Date();
};

export const ensureCriticalScopes = async ({
  nativeErrorCallback = () => {},
  nativePostLoginCallback = () => {},
  successCallback = () => {},
  waitCallback = () => {},
} = {}) => {
  if (isCriticalScopesValid()) {
    await successCallback();
    return;
  }

  if (isJWebNative()) {
    const threshold = getForceLoginThreshold();

    if (threshold <= 0) {
      await forceLogin({ nativePostLoginCallback, nativeErrorCallback });
    } else {
      waitCallback();
    }
  } else {
    await forceLogin();
  }
};
