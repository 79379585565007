import { put, takeEvery } from 'redux-saga/effects';
import { getUser, initializeUser } from '../actions/userInfo';
import ucdeUserActions from '../store/modules/ucdeUser/actions';

function* manageUserInitialization({ userType = 'legacy' }) {
  if (userType === 'legacy') {
    yield put(getUser());
  }

  if (userType === 'ucde') {
    yield put(ucdeUserActions.getUcdeUser);
  }
}

export default function* watchInitializeUser() {
  yield takeEvery(initializeUser.toString(), manageUserInitialization);
}
