import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 16px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  gap: 8px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

export const AvatarLoaderContainer = styled.div`
  position: relative;
  display: flex;
  display: -ms-flex;
  justify-content:center;
  align-items: center;
  align-self: center;
`;

export default Container;
