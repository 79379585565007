import React from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Button from '@veneer/core/dist/scripts/button';
import IconChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left';
import { useSelector } from '../../../hooks';
import { selectPortalTakeoverProps } from '../../../store/modules/portal/selectors';
import { kebabCase } from '../../../store/modules/helpCenter/selector';
import { CenterAreaContent, HeaderContent } from './styles';

const PortalTakeover = () => {
  const takeoverProps = useSelector(selectPortalTakeoverProps);
  const dispatch = useDispatch();
  if (!takeoverProps) {
    return null;
  }

  return (
    <HeaderContent
      position="fixed"
      leftArea={(
        <Button
          onClick={() => dispatch(goBack())}
          leadingIcon={<IconChevronLeft />}
          negative
        />
      )}
      centerArea={(
        <CenterAreaContent data-testid={kebabCase(takeoverProps)}>
          {takeoverProps}
        </CenterAreaContent>
      )}
      rightArea={<div />}
    />
  );
};

export default PortalTakeover;
