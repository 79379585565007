/* eslint-disable import/prefer-default-export */
import { gateway, ucde } from '../utils/request';
import { getCookie } from '../utils/globals';
import { STRATUS_ACCESS_TOKEN } from '../constants/cookieNames';

export const getCurrentUserLegacy = async () => {
  const url = '/user/current';
  return gateway({ url, version: '1' });
};

export const getCurrentUserUCDE = async () => {
  const url = '/user/me';
  return ucde({ url });
};

export const exchangeToken = async () => {
  const mockRefreshToken = await setTimeout(() => getCookie(STRATUS_ACCESS_TOKEN), 500);
  return mockRefreshToken;
};
