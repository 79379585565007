import styled from 'styled-components';
import Footer from '@veneer/core/dist/scripts/footer';
import { tokens } from '@veneer/tokens';
import { Link as RoutingLink } from '../../../routing';

export const Link = styled(RoutingLink)`
  &&&& {
    font-size: ${tokens.fontSize2};
    color: ${tokens.colorGray7};
  }
`;

export const StyledLink = styled(RoutingLink)`
  && {
    font-size: ${tokens.fontSize2};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
    :active {
      text-decoration: underline;
    }
  }
`;

export const CopyrightText = styled.span`
  font-size: ${tokens.fontSize1};
  color: ${tokens.colorGray7};
`;

export const StyledFooter = styled(Footer)`
  margin-top: auto;
  && {
    > div {
      background-color: transparent;
      border: none;
      flex-wrap: wrap;
      margin-bottom: ${props => (props.showFixedFooter ? '48px' : 'initial')};

      > div {
        :first-child {
          order: 2;
        }
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }

    @media (min-width: ${tokens.xlMin}) {
      margin-left: 262px;
      > div {
        margin-bottom: initial;

        > div {
          width: auto;
          :first-child {
            order: 0;
          }
        }
      }
    }
  }
`;

export const StyledFixedFooter = styled.div`
  @media (min-width: ${tokens.xlMin}) {
    display: none;
  }
`;
