import React, { useState } from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ifJWebAvailable } from '../../utils/auth';
import { encodeSignInState, makePathRelative } from '../../utils/routing';
import { selectUcdeUserLoggedIn } from '../../store/modules/ucdeUser/selectors';
import { selectPathnameWithQuery, selectRootMatch } from '../../selectors/routing';
import Config from '../../config';

function isPathUnchanged(oldProps, newProps) {
  return oldProps.path === newProps.path;
}

const ProtectRoute = React.memo(({
  path, component, disabled,
}) => {
  const [loginRoute, setLoginRoute] = useState(undefined);

  const isUcdeUserLoggedIn = useSelector(selectUcdeUserLoggedIn);
  const match = useSelector(selectRootMatch);
  const { pathname, queryParams } = useSelector(selectPathnameWithQuery);

  if (!isUcdeUserLoggedIn && !Config.isHpxApp) {
    const redirectTo = pathname && pathname.replace(/\/$/, '');
    const relativePath = makePathRelative(match);
    const state = encodeSignInState(redirectTo, false, queryParams);

    if (!loginRoute) {
      ifJWebAvailable(() => {
        setLoginRoute(relativePath(`/signin?state=${state}`));
      }, () => {
        setLoginRoute(relativePath(`/loggedin?state=${state}`));
      });

      return null;
    }

    return (
      <Redirect
        from={path}
        to={loginRoute}
      />
    );
  }

  if (disabled) {
    return <Redirect from={path} to={match.url} />;
  }

  return (
    <Route path={path}>
      {component}
    </Route>
  );
}, isPathUnchanged);

ProtectRoute.defaultProps = {
  disabled: false,
};

ProtectRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.any,
  ]).isRequired,
  disabled: PropTypes.bool,
};

export default ProtectRoute;
