import styled from 'styled-components';
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle';
// Importing ucde theme directly, since modal error is a standalone react component
import { UCDEMainTheme } from '../../pages/styles';

// some of this values will be refactored and the format of this definition maybe will be
// changed according the design instructions (e.g. another types of alerts)
const errorColor = '#C70000';

export const Icon = styled(IconMinusCircle)`
  && {
    fill: ${errorColor};
    overflow: unset;
  }
`;

export const Content = styled.div`
  text-align: center;

  > div {
    p {
      font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
      font-size: 14px;
      line-height: 24px;
      color: ${UCDEMainTheme.main.hp.fontColor};
      margin: 0 20px 20px;
    }
  }
`;
