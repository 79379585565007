import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocation, selectRootMatch } from '../../selectors/routing';
import { selectUcdeUser } from '../../store/modules/ucdeUser/selectors';
import { openVA } from '../../utils/virtualAgent';
import { StyledFooter, StyledVAButton } from './styles';
import i18n from '../../utils/i18n';

const FixedFooter = () => {
  const ucdeUser = useSelector(selectUcdeUser);
  const location = useSelector(selectLocation);
  const match = useSelector(selectRootMatch);

  const openVAModal = () => (
    openVA({
      parameters: {
        userId: ucdeUser.externalIdMap.hpid,
        location,
        match,
      },
    })
  );

  return (
    <StyledFooter
      data-testid="portal-fixed-footer"
      appearance="minimal"
      mainContent={
        {
          rightArea: (
            <StyledVAButton
              data-testid="portal-fixed-footer-va-button"
              onClick={openVAModal}
              type="button"
            >
              {i18n.t('virtualAgent.title')}
            </StyledVAButton>
          ),
        }
      }
    />
  );
};

export default FixedFooter;
