const AR = 'ar';
const BG = 'bg';
const CA = 'ca';
const CS = 'cs';
const DA = 'da';
const DE = 'de';
const EL = 'el';
const EN = 'en';
const ES = 'es';
const ET = 'et';
const FI = 'fi';
const FR = 'fr';
const IT = 'it';
const JA = 'ja';
const HE = 'he';
const HR = 'hr';
const HU = 'hu';
const ID = 'id';
const KO = 'ko';
const LV = 'lv';
const LT = 'lt';
const NB = 'nb';
const NL = 'nl';
const NO = 'no';
const PL = 'pl';
const PT = 'pt';
const RO = 'ro';
const RU = 'ru';
const SI = 'si';
const SK = 'sk';
const SL = 'sl';
const SV = 'sv';
const TH = 'th';
const TR = 'tr';
const ZH = 'zh';

export const LANGUAGES_ARRAY = [
  AR,
  BG,
  CA,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HE,
  HR,
  HU,
  ID,
  JA,
  KO,
  LT,
  LV,
  IT,
  NB,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SI,
  SK,
  SL,
  SV,
  TH,
  TR,
  ZH,
];
export const LANGUAGES_SET = new Set(LANGUAGES_ARRAY);

export const LANGUAGES = {
  AR,
  BG,
  CA,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HE,
  HR,
  HU,
  ID,
  JA,
  KO,
  LT,
  LV,
  IT,
  NB,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SI,
  SK,
  SL,
  SV,
  TH,
  TR,
  ZH,
};

const AT = 'at';
const AU = 'au';
const BE = 'be';
const BR = 'br';
const CH = 'ch';
const CN = 'cn';
const CZ = 'cz';
const DK = 'dk';
const EE = 'ee';
const GB = 'gb';
const GR = 'gr';
const HK = 'hk';
const IE = 'ie';
const IL = 'il';
const IN = 'in';
const JP = 'jp';
const KR = 'kr';
const LU = 'lu';
const MI = 'mi';
const MT = 'mt';
const MX = 'mx';
const NZ = 'nz';
const SE = 'se';
const SG = 'sg';
const UK = 'uk';
const US = 'us';
const SA = 'sa';
const TW = 'tw';

export const COUNTRIES_ARRAY = [
  AT,
  AU,
  BE,
  BG,
  BR,
  CA,
  CH,
  CN,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GB,
  GR,
  HK,
  HU,
  IE,
  IL,
  IN,
  IT,
  JP,
  KR,
  LT,
  LU,
  LV,
  MI,
  MX,
  NO,
  NZ,
  NL,
  PL,
  PT,
  RU,
  SA,
  SE,
  SG,
  TR,
  TW,
  US,
  UK,
];

export const COUNTRIES_SET = new Set(COUNTRIES_ARRAY);

export const COUNTRIES = {
  AT,
  AU,
  BE,
  BG,
  BR,
  CA,
  CH,
  CN,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GB,
  GR,
  HK,
  HU,
  IE,
  IL,
  IN,
  IT,
  JP,
  KR,
  LT,
  LU,
  LV,
  MI,
  MX,
  NO,
  NZ,
  NL,
  PL,
  PT,
  RU,
  SA,
  SE,
  SG,
  TR,
  TW,
  US,
  UK,
};

export const BASE_SITE_SUPPORTED_LANGUAGES = {
  [EN]: [US, AU, CA, IN, IE, NZ, UK, SG, HK],
  [DA]: [DK],
  [DE]: [DE, CH, AT],
  [ES]: [ES, MX],
  [FI]: [FI],
  [FR]: [FR, BE, CA, LU, CH],
  [IT]: [IT, CH],
  [JA]: [JP],
  [KO]: [KR],
  [NL]: [BE, NL],
  [NO]: [NO],
  [PL]: [PL],
  [PT]: [BR, PT],
  [RU]: [RU],
  [SV]: [SE],
  [ZH]: [CN],
};

export const DATA_COLLECTION_NOTICE_SUPPORTED_LANGUAGES = {
  ...BASE_SITE_SUPPORTED_LANGUAGES,
  [AR]: [SA, MI],
  [BG]: [BG],
  [CS]: [CZ],
  [ES]: [ES, MX, CA],
  [CA]: [ES],
  [ET]: [EE],
  [EL]: [GR],
  [HE]: [IL],
  [HR]: [HR],
  [ID]: [ID],
  [LT]: [LT],
  [LV]: [LV],
  [RO]: [RO],
  [SK]: [SK],
  [SL]: [SI],
  [TH]: [TH],
  [TR]: [TR],
  [HU]: [HU],
  [ZH]: [HK, CN, TW, SG],
};

export const IN_APP_HELP_SUPPORTED_LANGUAGES = {
  ...BASE_SITE_SUPPORTED_LANGUAGES,
  [BG]: [BG],
  [CA]: [ES],
  [CS]: [CZ],
  [EL]: [GR],
  [ET]: [EE],
  [HR]: [HR],
  [HU]: [HU],
  [ID]: [ID],
  [LT]: [LT],
  [LV]: [LV],
  [NB]: [NO],
  [RO]: [RO],
  [SK]: [SK],
  [SL]: [SL],
  [TH]: [TH],
  [TR]: [TR],
  [ZH]: [CN, HK, TW],
};

export const TANGO_SUPPORTED_LANGUAGES = {
  ...BASE_SITE_SUPPORTED_LANGUAGES,
  [AR]: [SA, MI],
  [EN]: [US, AU, CA, IN, IE, NZ, UK, SG, HK, SA, MI],
  // [EN]: [...BASE_SITE_SUPPORTED_LANGUAGES.EN, SA, MI] // webpack and/or babel doesn't like this syntax
};

export const TOU_SUPPORTED_LANGUAGES = {
  ...BASE_SITE_SUPPORTED_LANGUAGES,
  // [AR]: [SA, MI],
  [CS]: [CZ],
  [EL]: [GR],
  // [HE]: [IL],
  // [HR]: [HR],
  [HU]: [HU],
  // [ID]: [ID],
  // [RO]: [RO],
  // [SK]: [SK],
  // [SL]: [SI],
  // [TH]: [TH],
  [TR]: [TR],
  [ZH]: [HK, CN, TW, SG],
};

export const COUNTRY_DOMINANT_LANGUAGE = {
  [JP]: JA,
  [BE]: FR,
  [CA]: EN,
  [CH]: DE,
  [HK]: EN,
  [SG]: EN,
  [US]: EN,
  [AU]: EN,
  [IN]: EN,
  [IE]: EN,
  [NZ]: EN,
  [UK]: EN,
  [GB]: EN,
  [FR]: FR,
  [LU]: FR,
  [DE]: DE,
  [AT]: DE,
  [NL]: NL,
  [BR]: PT,
  [PT]: PT,
  [ES]: ES,
  [MX]: ES,
  [IT]: IT,
  [DK]: DA,
  [NO]: NO,
  [PL]: PL,
  [FI]: FI,
  [SE]: SV,
  [RU]: RU,
  [KR]: KO,
  [CN]: ZH,
  [GR]: EL,
  [CZ]: CS,
  [HU]: HU,
  [TR]: TR,
  [SA]: AR,
  [MI]: AR,
};

// in-app-help // EXCLUDE FROM ALL PAGES OTHER THAN in-app-help
//  zh_hk
//  el_gr
//  cs_cz
//  hu_hu
//  tr_tr

export const countryVariants = {
  gb: 'uk',
  // eventually adding support for
  // 'hk': "cn" //// this one does work
};

export const reverseCountryVariants = {
  uk: 'gb',
};

export const languageVariants = {
  // if lang, if country, convert lang to lang
  // if lang='nb', if country='no', convert lang'nb' to lang'no'
  nb: { no: 'no' },
};

export const COUNTRIES_AND_LANGUAGES_SET = new Set([...COUNTRIES_ARRAY, ...LANGUAGES_ARRAY]);

export const RTL = 'rtl';
export const LTR = 'ltr';

export const SIMPLIFIED_CHINESE_COUNTRIES = new Set([CN, SG]);

export const INSTANT_INK_COUNTRIES = [
  US,
  CA,
  AT,
  BE,
  LU,
  DE,
  ES,
  NL,
  DK,
  FR,
  IE,
  IT,
  NO,
  PT,
  CH,
  SE,
  FI,
  UK,
];

export const VIRTUAL_AGENT_SUPPORTED_LANGUAGES = [
  DE,
  EN,
  ES,
  FR,
  JA,
  NL,
  PT,
  ZH,
];

export const VIRTUAL_AGENT_SUPPORTED_LOCALES = {
  [DE]: DE,
  [EN]: US,
  [ES]: ES,
  [FR]: FR,
  [JA]: JP,
  [NL]: NL,
  [PT]: BR,
  [ZH]: CN,
};

export const WHATSAPP_SUPPORT_SUPPORTED_COUNTRIES = [
  BG,
  HR,
  EE,
  LT,
  LV,
  MT,
  SI,
];
