const Types = {
  INITIALIZE_UCDE_USER: '@ucdeUser/INITIALIZE_UCDE_USER',
  LOAD_UCDE_USER_REQUEST: '@ucdeUser/LOAD_UCDE_USER_REQUEST',
  LOAD_UCDE_USER_SUCCESS: '@ucdeUser/LOAD_USER_SUCCESS',
  LOAD_UCDE_USER_FAILED: '@ucdeUser/LOAD_USER_FAILED',
  LOAD_UCDE_USER_PROFILE_REQUEST: '@ucdeUser/LOAD_USER_PROFILE_REQUEST',
  LOAD_UCDE_USER_PROFILE_SUCCESS: '@ucdeUser/LOAD_USER_PROFILE_SUCCESS',
  LOAD_UCDE_USER_PROFILE_FAILED: '@ucdeUser/LOAD_USER_PROFILE_FAILED',
  SAVE_UCDE_USER_REQUEST: '@ucdeUser/SAVE_USER_REQUEST',
  SAVE_UCDE_USER_SUCCESS: '@ucdeUser/SAVE_USER_SUCCESS',
  SAVE_UCDE_USER_FAILED: '@ucdeUser/SAVE_USER_FAILED',
  REMOVE_UCDE_USER: '@ucdeUser/REMOVE_UCDE_USER',
};

export default Types;
