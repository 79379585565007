import React from 'react';
import SignOut from '../global/signout';
import Config from '../../config';
import { deleteCookie } from '../../utils/globals';
import { COMFE_ENABLED } from '../../constants/cookieNames';
import { removeLocalStorageScopesItem } from '../../utils/auth';

export default () => {
  const signOutUrl = `${Config.SESSION_SERVICE_BASE_URL}/logout`;
  // TODO: Remove it when complete migration to COMFE
  deleteCookie(COMFE_ENABLED);
  removeLocalStorageScopesItem();

  return <SignOut loc={signOutUrl} />;
};
