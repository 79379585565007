import { useEffect, useRef } from 'react';
import { prop } from 'ramda';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return prop('current', ref);
};

export default usePrevious;
