import React from 'react';
import PropTypes from 'prop-types';
import VeneerContextualMenu from '@veneer/core/dist/scripts/contextual_menu';
import { registerClickEvent, registerPageView } from '../../../../utils/analytics';

export const ContextualMenu = ({ children, onClick, ...props }) => (
  <VeneerContextualMenu
    onClick={(e, option) => {
      if (option.udlPageView) {
        registerPageView(option.udlPageView);
      }

      if (option.udlEvent) {
        registerClickEvent(option.udlEvent);
      }

      onClick(e, option);
    }}
    {...props}
  >
    {children}
  </VeneerContextualMenu>
);

ContextualMenu.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ContextualMenu.defaultProps = {
  onClick: () => { },
};

export default ContextualMenu;
