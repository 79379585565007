import { createSelector } from 'reselect';
import { selectLocaleParamsFromRoute } from '../../../selectors/routing';
import Config from '../../../config';

export const selectNotificationProps = createSelector(
  selectLocaleParamsFromRoute,
  localeParamsFromRoute => ({
    baseURLProvider: Config.baseUrl,
    language: localeParamsFromRoute.language,
    country: localeParamsFromRoute.country,
    currentStack: Config.STACK,
  }),
);

export default selectNotificationProps;
