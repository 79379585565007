import { isJWebAndroidApp, isJWebDesktopApp, isJWebiOSApp } from '../../utils/auth';
import Config from '../../config';

const environmentConfig = (objConfig = {}) => (
  {
    isNative: window.JWeb && window.JWeb.isNative,
    isJWebDesktopApp: isJWebDesktopApp(),
    isJWebiOSApp: isJWebiOSApp(),
    isJWebAndroidApp: isJWebAndroidApp(),
    stack: Config.STACK,
    ...objConfig,
  }
);

export default environmentConfig;
