import React from 'react';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import useFeatureFlags from './utils/useFeatureFlags';
import i18n from './utils/i18n';
import UCDEMainRoutes from './routing/UCDEMainRoutes';
import createRootReducer from './reducers';
import rootSaga from './sagas';
import { defaultRoute } from './utils/routing';
import initilizeAdapter from './utils/adapter';
import SignOut from './components/SignOut';
import './assets/globals/styles/main.scss';
import '@veneer/core/dist/css/veneer.css';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory({ basename: '/' });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancers = composeEnhancers(
  applyMiddleware(
    thunk,
    sagaMiddleware,
    routerMiddleware(history),
  ),
);

export const store = createStore(
  createRootReducer(history),
  composedEnhancers,
);

sagaMiddleware.run(rootSaga);

const App = () => {
  const {
    hpxMyAccount,
    hpxMyAccountAppView,
    jshellPaaSDashboard,
    publishedLocales,
    useSmartSecurityMfe,
  } = useFeatureFlags();

  if (jshellPaaSDashboard || hpxMyAccount || hpxMyAccountAppView || useSmartSecurityMfe) {
    initilizeAdapter({ history });
  }

  i18n.setPublishedLocales(publishedLocales);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            path="/:country/:language/(ucde|gateway)/signout"
            component={SignOut}
          />
          <Route path="/:country/:language/:root(ucde|gateway)">
            {
              routeProps => <UCDEMainRoutes match={routeProps.match} />
            }
          </Route>
          <Route path="/">
            {
              props => <Redirect to={defaultRoute(props)} />
            }
          </Route>
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
