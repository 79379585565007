import { createSelector } from 'reselect';
import { prop } from 'ramda';

const selectorsBase = {
  GET_CLOSE: prop('portalClose'),
};

export const selectComponentCanClose = componentName => createSelector(
  selectorsBase.GET_CLOSE,
  portalClose => portalClose.components[componentName].canClose,
);

export const selectPortalCanClose = createSelector(
  selectorsBase.GET_CLOSE,
  prop('canClose'),
);

export const selectPortalCloseAttempt = createSelector(
  selectorsBase.GET_CLOSE,
  prop('closeAttempt'),
);
