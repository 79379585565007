import { useFlags } from 'launchdarkly-react-client-sdk';

// These are the default feature flags for the production environment.
const bootstrap = {
  accountDeletionPage: false,
  blueDashboard: false,
  comfeEnable: false,
  comfeEnableByQueryString: false,
  enableCheckoutMFE: false,
  forceNonIiEligible: true,
  forestFirst: false,
  hideCountryLanguageInFooterAndForm: false,
  hpPlusSecondOffer: false,
  hpSmartAdvance: true, // SDASH-2763 evaluate if this flag can be removed
  instantInkMenusPhase1: false,
  instantInkMenusPhase2: false,
  jshellPaaSDashboard: false,
  jshellPaaSShowBillingAndShippingMenu: false,
  newTenancyModel: false,
  notificationSettings: false,
  orderHistory: false,
  publishedLocales: null,
  releaseVersionHpSmartAdvance: 'published', // SDASH-2763 evaluate if this flag can be removed
  removePrinter: false,
  stratusClientId: null,
  transferPrinterConsumerModalOrganizationInput: false,
  transferPrinterConsumerModalRequestTransferConfirmation: false,
  useSmartSecurityMfe: false,
  whatsAppSupport: false,
  useShippingBillingBeta: false,
  useStatementsBeta: false,
  viewNotificationsPersonalAccount: false,
  checkRoleForCommerceMenus: false,
  useHpOnePrintersCard: false,
  usePrinterInfoCardWith4Columns: false,
};

const useFeatureFlags = () => {
  const flags = useFlags();
  return {
    ...bootstrap,
    ...flags,
  };
};

export default useFeatureFlags;
