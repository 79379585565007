import { overrideInterface, storeConfiguration } from '@jarvis/shell-adapter';
import { jarvisAuthProvider, removeLocalStorageScopesItem } from '../auth';
import adapterAccessControl from './adapterAccessControl';
import Environments from '../../config/environments';
import Config from '../../config';

const manifestAssets = {
  [Environments.prd.STACK]: () => import('./manifests/manifest.prod.json'),
  [Environments.stg.STACK]: () => import('./manifests/manifest.stg.json'),
  [Environments.dev.STACK]: () => import('./manifests/manifest.dev.json'),
  [Environments.pie.STACK]: () => import('./manifests/manifest.pie.json'),
};

const importmapAssets = {
  [Environments.prd.STACK]: () => import('./importmaps/importmap.prod.json'),
  [Environments.stg.STACK]: () => import('./importmaps/importmap.stg.json'),
  [Environments.dev.STACK]: () => import('./importmaps/importmap.dev.json'),
  [Environments.pie.STACK]: () => import('./importmaps/importmap.pie.json'),
};

function getImportmap(stack) {
  return importmapAssets[stack]?.();
}

function getManifest(stack) {
  return manifestAssets[stack]?.();
}

export default async ({ history } = {}) => {
  let importmap = await (getImportmap(Config.STACK) || getImportmap(Environments.dev.STACK));
  let manifest = await (getManifest(Config.STACK) || getManifest(Environments.dev.STACK));

  importmap = importmap && importmap.default;
  manifest = manifest && manifest.default;

  overrideInterface(1, 'authProvider', () => jarvisAuthProvider);
  overrideInterface(1, 'accessControl', () => adapterAccessControl());

  storeConfiguration({
    importmap,
    manifest,
    defaultProps: {
      history,
      removeLocalStorageScopesItem,
    },
    afterInit: async ({ interfaces, services }) => {
      const sessionData = await services.session.getSessionData();
      const { store } = interfaces?.v1 || {};

      store.setState({
        manifest,
        session: sessionData,
      });
    },
  });
};
