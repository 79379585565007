import { createSelector } from 'reselect';
import base64url from 'base64url';
import crypto from 'crypto';
import {
  prop,
} from 'ramda';
import { isMobile } from 'react-device-detect';
import { selectUcdePortalElementsLoading } from '../portalElements/selectors';
import {
  selectLocation,
  selectQueryParameters,
  selectRootMatch,
} from '../../../selectors/routing';
import {
  selectAnalyticsData,
} from '../../../selectors/analytics';
import { encodeAuthState } from '../../../utils/routing';
import { canShowVA } from '../../../utils/virtualAgent';

export const selectPortalProps = createSelector(
  selectUcdePortalElementsLoading,
  selectLocation,
  (loading, location = {}) => (
    {
      loading,
      takeover: location.state && location.state.takeover,
    }
  ),
);

export const selectPortalTakeoverProps = createSelector(
  selectLocation,
  (location = {}) => location.state && location.state.takeover,
);

const determineRedirectTo = ({
  url, country, language, decodedRedirectTo,
}) => {
  if (
    url === `/${country}/${language}`
    || url === `/${country}/${language}/ucde`
    || url === `/${country}/${language}/gateway`
    || url === `/${country}/${language}/signin`
    || url === `/${country}/${language}/ucde/signin`
    || url === `/${country}/${language}/gateway/signin`
  ) {
    return decodedRedirectTo;
  }
  return decodedRedirectTo || url;
};

export const selectSignInProps = createSelector(
  selectRootMatch,
  selectQueryParameters,
  selectAnalyticsData,
  (rootMatch, queryParams, analyticsData) => {
    if (!rootMatch) {
      return {};
    }
    const {
      url,
      params: {
        country,
        language,
      },
    } = rootMatch;
    const {
      state,
    } = queryParams;
    const stateDecoded = state ? JSON.parse(base64url.decode(state)) : {};

    const decodedRedirectTo = prop('redirectTo', stateDecoded);
    const promptLogin = prop('promptLogin', stateDecoded);
    const redirectTo = determineRedirectTo({
      url,
      country,
      language,
      decodedRedirectTo,
    });

    const baseBackendRedirect = `/${country}/${language}/ucde/loggedin`;

    const redirectQueryParams = Object.entries({ ...analyticsData })
      .reduce((acc, [key, value]) => (acc ? `${acc}&${key}=${value}` : `${key}=${value}`), '');

    const urlQueryParams = redirectQueryParams ? `?${redirectQueryParams}` : ''; // Don't add '?' if there's no query parameter present
    const backendRedirect = `${window.location.protocol}//${window.location.host}${baseBackendRedirect}${urlQueryParams}`;
    const handshake = crypto.randomBytes(32).toString('hex');

    const encodedState = encodeAuthState(handshake, redirectTo, backendRedirect);
    return {
      handshake,
      redirectTo,
      encodedState,
      promptLogin,
    };
  },
);

export const canShowFixedFooter = createSelector(selectRootMatch, rootMatch => (
  canShowVA(rootMatch) && isMobile
));
