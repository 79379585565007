import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Link } from '../../../../shared-components/Analytics/Link';
import { UCDEMainTheme } from '../../../../../pages/styles';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const ProfileMenuCommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UserDataAndMenuListWrapper = styled(ProfileMenuCommonWrapper)``;

export const UserDataWrapper = styled(ProfileMenuCommonWrapper)`
  gap: 4px;
`;

export const UserNameText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-family: ${tokens.fontFamilyRegular};
`;

export const UserEmailText = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-family: ${tokens.fontFamilyLight};

  @media (max-width: 392px) {
    word-break: break-all;
  }
`;

export const AccountProfileLink = styled(Link)`
  color: ${UCDEMainTheme.main.hp.color};
  font-family: ${UCDEMainTheme.main.hp.fontFamily};
  display: block;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  padding-bottom: 16px;
`;
