import { takeEvery } from 'redux-saga/effects';
import ActionTypes from '../constants/ActionTypes';
import {
  getMetaTagByName,
  setTagAttribute,
} from '../utils/globals';

function* handleUpdateShortTitleMeta({ title }) {
  const tag = yield getMetaTagByName('simple_title');

  yield setTagAttribute(tag, 'content', title);
}

export default function* watchUpdateShortTitleMeta() {
  yield takeEvery(ActionTypes.UPDATE_SHORT_TITLE_META, handleUpdateShortTitleMeta);
}
