import * as actions from './actions';
import { createReducer } from '../../../utils/redux';

const INITIAL_STATE = [];

function overallStatusRequest(state) {
  return state;
}

function overallStatusFailed(state) {
  return state;
}

function overallStatusSuccess(state, action) {
  return action.payload;
}

const handlers = {
  [actions.getOverallStatus]: overallStatusRequest,
  [actions.getOverallStatusFailed]: overallStatusFailed,
  [actions.getOverallStatusSuccess]: overallStatusSuccess,
};

const eventsReducer = createReducer(INITIAL_STATE, handlers);

export default eventsReducer;
