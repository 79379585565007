import Types from './types';
import { makeActionCreator } from '../../../utils/redux';
import {
  makeApiCall,
} from '../../../actions/application';
import {
  getUserData,
  getUserProfile,
  saveUserData,
} from '../../../api/UCDEGateway';

export const initializeUcdeUser = makeActionCreator(
  Types.INITIALIZE_UCDE_USER,
  'path',
);

const loadUcdeUserRequest = makeActionCreator(Types.LOAD_UCDE_USER_REQUEST);
const loadUcdeUserSuccess = makeActionCreator(Types.LOAD_UCDE_USER_SUCCESS, 'payload');
const loadUcdeUserFailed = makeActionCreator(Types.LOAD_UCDE_USER_FAILED, 'payload');
const getUcdeUser = makeApiCall({
  asyncFn: getUserData,
  successActionCreator: loadUcdeUserSuccess,
  failureActionCreator: loadUcdeUserFailed,
});

export const loadUcdeUserProfile = makeActionCreator(
  Types.LOAD_UCDE_USER_PROFILE_REQUEST,
  'userType',
);
const loadUcdeUserProfileSuccess = makeActionCreator(
  Types.LOAD_UCDE_USER_PROFILE_SUCCESS,
  'payload',
);
const loadUcdeUserProfileFailed = makeActionCreator(
  Types.LOAD_UCDE_USER_PROFILE_FAILED,
  'payload',
);
const getUcdeUserProfile = makeApiCall({
  asyncFn: getUserProfile,
  successActionCreator: loadUcdeUserProfileSuccess,
  failureActionCreator: loadUcdeUserProfileFailed,
});

export const saveUcdeUserProfile = makeActionCreator(Types.SAVE_UCDE_USER_REQUEST, 'payload');
const saveUcdeUserSuccess = makeActionCreator(Types.SAVE_UCDE_USER_SUCCESS, 'payload');
const saveUcdeUserFailed = makeActionCreator(Types.SAVE_UCDE_USER_FAILED, 'payload');
const saveUcdeUser = userData => makeApiCall({
  asyncFn: saveUserData,
  args: userData,
  successActionCreator: saveUcdeUserSuccess,
  failureActionCreator: saveUcdeUserFailed,
});

const removeUcdeUser = makeActionCreator(Types.REMOVE_UCDE_USER);

const Actions = {
  getUcdeUser,
  getUcdeUserProfile,
  loadUcdeUserRequest,
  loadUcdeUserSuccess,
  loadUcdeUserProfileSuccess,
  removeUcdeUser,
  saveUcdeUser,
  saveUcdeUserSuccess,
};

export default Actions;
