import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import ActionTypes from '../constants/ActionTypes';
import { selectUDLQueryParams } from '../selectors/routing';
import { saveUDLParams } from '../actions/analytics';
import { encrypt } from '../services/encryption';

function* handleManageUDLParams() {
  const queryParams = yield select(selectUDLQueryParams);

  if (queryParams.appInstanceID) {
    yield put(saveUDLParams(queryParams));
  } else {
    const unEncodedAppInstanceID = uuidv4();
    const { data: { appInstanceID } } = yield call(encrypt, { appInstanceID: unEncodedAppInstanceID });

    yield put(saveUDLParams({ appInstanceID }));
  }
}

export default function* watchCaptureUDLParams() {
  yield takeEvery(ActionTypes.CAPTURE_UDL_PARAMS, handleManageUDLParams);
}
