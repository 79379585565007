import {
  call,
  put,
  takeLeading,
} from 'redux-saga/effects';
import {
  getDevicesTelemetry,
} from '../../../api/UCDEGateway';
import {
  getTelemetry,
  getTelemetryFailed,
  getTelemetrySuccess,
} from './actions';

function* fetchDevicesTelemetry({ payload }) {
  try {
    const telemetryResponse = yield call(getDevicesTelemetry, payload.accountId, payload.isRefresh);

    yield put(getTelemetrySuccess(telemetryResponse));
  } catch (e) {
    yield put(getTelemetryFailed());
  }
}

function* watchDevicesTelemetry() {
  yield takeLeading(getTelemetry.toString(), fetchDevicesTelemetry);
}

export default watchDevicesTelemetry;
