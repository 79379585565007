import { fork } from 'redux-saga/effects';

import watchApiCallActions from './apiCallActions';
import initializeUser from './initializeUser';
import watchUpdateShortTitleMeta from './updateMetaTag';
import {
  watchInitializeUcdeUser,
  watchLoadUcdeUserProfile,
  watchSaveUcdeUserProfile,
} from '../store/modules/ucdeUser/sagas';
import watchCaptureUDLParams from './handleAnalyticsParams';
import watchUcdeUserPortalElements from '../store/modules/portalElements/sagas';
import {
  watchGetDetailedDevices,
  watchGetDeviceSupplies,
} from '../store/modules/devices/sagas';
import { initializeJWeb, watchJWebSignIn } from '../store/modules/jweb/sagas';
import watchGetDashboardFeatures from '../store/modules/dashboardFeatures/sagas';
import { watchAsyncEventExecution } from '../store/modules/events/sagas';
import watchDevicesOverallStatus from '../store/modules/devicesOverallStatus/sagas';
import watchDevicesTelemetry from '../store/modules/devicesTelemetry/sagas';
import watchGetUsers from '../store/modules/users/sagas';
import watchClosePortal from '../store/modules/portalClose/sagas';
import watchHpAdvancedSubscription from '../store/modules/hpAdvancedSubscription/sagas';

export default function* rootSaga() {
  yield fork(initializeJWeb);
  yield fork(watchJWebSignIn);
  yield fork(initializeUser);
  yield fork(watchUpdateShortTitleMeta);
  yield fork(watchApiCallActions);
  yield fork(watchInitializeUcdeUser);
  yield fork(watchUcdeUserPortalElements);
  yield fork(watchSaveUcdeUserProfile);
  yield fork(watchLoadUcdeUserProfile);
  yield fork(watchGetDetailedDevices);
  yield fork(watchGetDeviceSupplies);
  yield fork(watchCaptureUDLParams);
  yield fork(watchGetDashboardFeatures);
  yield fork(watchAsyncEventExecution);
  yield fork(watchDevicesOverallStatus);
  yield fork(watchDevicesTelemetry);
  yield fork(watchGetUsers);
  yield fork(watchClosePortal);
  yield fork(watchHpAdvancedSubscription);
}
