import React from 'react';
import { useSelector } from 'react-redux';
import {
  CopyrightText,
  Link,
  StyledFixedFooter,
  StyledFooter,
  StyledLink,
} from './styles';
import { selectRootMatch } from '../../../selectors/routing';
import i18n from '../../../utils/i18n';
import { PATHS } from '../../../utils/constants';
import { makePathRelative } from '../../../utils/routing';
import FixedFooter from '../../FixedFooter';
import { canShowFixedFooter } from '../../../store/modules/portal/selectors';
import useFeatureFlags from '../../../utils/useFeatureFlags';

const getCountryAndLanguage = () => {
  const languageAndCountry = i18n.t(`myAccount.profile.languages.${i18n.locale()}`);
  const regExp = new RegExp('(.*)\\s+\\((.*)\\)', 'g');
  const match = regExp.exec(languageAndCountry);
  if (match && match[1] && match[2]) {
    return `${match[2]} (${match[1]})`;
  }
  return languageAndCountry;
};

const Footer = () => {
  const match = useSelector(selectRootMatch);
  const showFixedFooter = useSelector(canShowFixedFooter);
  const relPath = makePathRelative(match);
  const currentYear = new Date().getFullYear();
  const locale = {
    language: i18n.getLanguage(),
    country: i18n.getCountry().toLowerCase(),
  };
  const flags = useFeatureFlags();

  const showPrivacyChoices = locale.country === 'us' && locale.language === 'en';

  return (
    <>
      <StyledFooter
        showFixedFooter={showFixedFooter}
        data-testid="portal-footer"
        mainContent={{
          leadingArea: (
            <CopyrightText>
              {i18n.t('footer.copyRightYear', { year: currentYear })}
            </CopyrightText>
          ),
          links: [{
            label: 'HP.com',
            element: <Link to="https://www.hp.com" target="_blank" />,
          }, {
            label: `${i18n.t('footer.wirelessPrintCenter')}`,
            element: <Link to="https://www.hpsmart.com/wireless-printing" target="_blank" />,
          }, {
            label: `${i18n.t('footer.helpCenter')}`,
            element: <Link to="/ucde/help/about-hp-smart" />,
          }, {
            label: `${i18n.t('footer.termsOfUse')}`,
            element: <Link to="https://www.hpsmart.com/tou" target="_blank" />,
          }, {
            label: `${i18n.t('footer.hpPrivacy')}`,
            element: <Link to="https://www.hp.com/go/privacy" target="_blank" />,
          }, (showPrivacyChoices && {
            label: `${i18n.t('footer.hpYourPrivacyChoices')}`,
            element: <Link to="https://www.hp.com/us-en/privacy/your-privacy-choices.html" target="_blank" />,
          })],
          ...(!flags.hideCountryLanguageInFooterAndForm
            && {
              trailingArea: (
                <StyledLink data-testid="portal-language" to={relPath(PATHS.ACCOUNT_DETAILS.PROFILE)}>
                  {getCountryAndLanguage()}
                </StyledLink>
              ),
            }
          ),
        }}
        appearance="minimal"
      />
      {showFixedFooter && (
        <StyledFixedFooter>
          <FixedFooter />
        </StyledFixedFooter>
      )}
    </>
  );
};

export default Footer;
