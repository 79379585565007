export const COMFE_ENABLED = 'comfe-enabled';
export const DASHBOARD_VERSION = 'dashboard_version';
export const HPC3_SESSION_TYPE = 'hpc3-session-type';
export const IS_AGENT_SESSION = 'is_agent_session';
export const IS_INVITE = 'is-invite';
export const SELECTED_ORG_ID = 'selected-org-id';
export const STRATUS_ACCESS_TOKEN = 'stratus-access-token';
export const STRATUS_BASE_TOKEN = 'stratus-base-token';
export const STRATUS_ID_TOKEN = 'stratus-id-token';
export const STRATUS_SESSION_ID = 'stratus-session-id';
export const USER_TYPE = 'user-level';
export const VISION_SESSION = 'VISION_SESSION';
