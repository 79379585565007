import React, { useEffect, useState } from 'react';
import { JarvisInboxNotifications } from '@jarvis/react-atlas-notifications';
import { useSelector } from 'react-redux';
import {
  jarvisAuthProvider,
} from '../../utils/auth';
import { selectNotificationProps } from '../../store/modules/notification/selector';
import {
  OnRegisterEvent,
  notificationMenuOption,
  shouldShowAction,
} from '../shared-components/NotificationActionComponent';
import { saveMonitoringLogOnError } from '../../utils/apiErrorHandling';
import { onClickActionFunction } from '../../utils/deeplinks';
import useFeatureFlags from '../../utils/useFeatureFlags';
import i18n from '../../utils/i18n';

const notificationLinkPlacement = 'inbox-notification';

const linkIDs = {
  newNotificationClick: 'new-notification-click',
  noNewNotificationClick: 'no-new-notification-click',
  remove: 'remove',
  clearAll: 'clear-all',
};

const BellNotifications = () => {
  const [pollingEnabled, setPollingEnabled] = useState(true);

  const enablePolling = () => setPollingEnabled(true);
  const disablePolling = () => setPollingEnabled(false);

  useEffect(() => {
    window.Portal.events.addEventListener('onIdle', disablePolling);
    window.Portal.events.addEventListener('onActive', enablePolling);
    return () => {
      window.Portal.events.removeEventListener('onIdle', disablePolling);
      window.Portal.events.removeEventListener('onActive', enablePolling);
    };
  }, []);

  const {
    country,
    language,
    baseURLProvider,
  } = useSelector(selectNotificationProps);

  const { notificationSettings } = useFeatureFlags();
  const notificationMenuOptions = notificationMenuOption(notificationSettings);

  return (
    <JarvisInboxNotifications
      data-testid="jarvis-inbox-notifications"
      country={country}
      language={language}
      authProvider={jarvisAuthProvider}
      baseURLProvider={baseURLProvider}
      shouldShowAction={shouldShowAction()}
      onClickActionFunction={onClickActionFunction(notificationLinkPlacement)}
      onError={async error => saveMonitoringLogOnError('JarvisInboxNotifications', error)}
      onRegisterEvent={async linkID => OnRegisterEvent(notificationLinkPlacement, linkID)}
      eventLinkIDs={linkIDs}
      clearAllOption={{ allow: true, label: i18n.t('home.topNav.notifications.clearAll') }}
      menuOptions={notificationMenuOptions.map(item => ({
        value: item.value,
        label: i18n.t(item.stringId),
        onClick: item.OnClick(item.linkTo),
      }))}
      autoRefresh={pollingEnabled}
    />
  );
};

export default BellNotifications;
