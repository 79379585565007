/* eslint-disable import/prefer-default-export */
import { util } from '../utils/request';
import Config from '../config';

export const encrypt = data => {
  const auth = Config.BASIC_AUTH;
  return util({
    url: '/encrypt?purpose=ga',
    method: 'POST',
    headers: {
      Authorization: `Basic ${auth}`,
    },
    data,
  });
};
