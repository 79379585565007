import * as actions from './actions';
import { createReducer } from '../../../utils/redux';

const INITIAL_STATE = {};

function telemetryRequest(state) {
  const newState = {
    ...state,
    isLoading: true,
  };

  return newState;
}

function telemetryFailed(state) {
  const newState = {
    ...state,
    isLoading: false,
    error: true,
  };

  return newState;
}

function telemetrySuccess(state, action) {
  const newState = {
    ...state,
    isLoading: false,
    error: false,
    lastFetch: new Date(),
    data: action.payload,
  };

  return newState;
}

const handlers = {
  [actions.getTelemetry]: telemetryRequest,
  [actions.getTelemetryFailed]: telemetryFailed,
  [actions.getTelemetrySuccess]: telemetrySuccess,
};

const eventsReducer = createReducer(INITIAL_STATE, handlers);

export default eventsReducer;
