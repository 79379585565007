export default function injectOneTrust(identifier) {
  const oneTrustScript = document.getElementById('one-trust-script');

  if (!oneTrustScript) {
    const script = document.createElement('script');

    script.setAttribute('id', 'one-trust-script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    script.setAttribute('data-domain-script', identifier);

    if (document.head.prepend) {
      document.head.prepend(script);
    } else {
      document.head.appendChild(script);
    }
  }
}
