import {
  identity,
  path,
} from 'ramda';
import { createSelector } from 'reselect';
import { selectPageViewPathInfo } from './routing';

const SELECTORS_BASE = {
  selectAnalytics: path(['analytics']),
};

export const selectAnalyticsData = createSelector(
  SELECTORS_BASE.selectAnalytics,
  identity,
);

export const selectPageViewEventInfo = createSelector(
  SELECTORS_BASE.selectAnalytics,
  selectPageViewPathInfo,
  (analyticsObj, { pathname, eventInfo }) => (
    {
      ...analyticsObj,
      pathname,
      eventInfo,
    }
  ),
);
