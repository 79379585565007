import { path } from 'ramda';
import {
  getDecodedStratusAccessToken,
  isJWebNative,
  isStratusAccessTokenRefreshable,
  jwebSignIn,
  redirectToSignIn,
} from '.';
import { refreshToken as hpcRefreshToken } from '../../api/HPCSession';
import { REFRESH_TOKEN_RETRIES } from '../constants';

const refreshController = {
  requestQueue: [],
  isRefreshing: false,
  isRedirecting: false,
  processRequests: () => {
    refreshController.requestQueue.forEach(request => {
      request.resolve();
    });

    refreshController.requestQueue = [];
  },
  clearQueue: () => {
    refreshController.requestQueue.forEach(request => {
      request.reject();
    });
  },
};

const refreshToken = async (options = {}, retries = 0) => {
  const {
    requireRefresh,
  } = options;

  const decodedToken = getDecodedStratusAccessToken();
  const isTokenRefreshable = isStratusAccessTokenRefreshable(decodedToken);

  if (requireRefresh || isTokenRefreshable) {
    // there's no need to pass auth as a header since it get the cookies

    if (refreshController.isRefreshing && retries === 0) {
      return new Promise((resolve, reject) => {
        refreshController.requestQueue.push({ resolve, reject });
      });
    }

    refreshController.isRefreshing = true;

    try {
      await hpcRefreshToken(options);

      refreshController.processRequests();
      refreshController.isRefreshing = false;
    } catch (error) {
      const status = path(['response', 'status'], error);
      const canRetry = status && status >= 500 && status < 600 && retries < REFRESH_TOKEN_RETRIES;

      if (canRetry) {
        await refreshToken(options, retries + 1);
      } else {
        refreshController.isRefreshing = false;

        if (isJWebNative()) {
          await jwebSignIn({ requireFreshToken: true });
          refreshController.processRequests();
        } else {
          redirectToSignIn();
          refreshController.clearQueue();
        }
      }
    }
  }

  return Promise.resolve();
};

export default refreshToken;
