import {
  curry,
  find,
  map,
  prop,
  propEq,
} from 'ramda';
import { matchPath } from 'react-router';
import getSectionsFromMock from './mock-smart-help-sections-cs.json';
import getMainContentFromMock from './mock-smart-help-cs.json';
import { SERVICES } from '../../../utils/constants';
import { serviceEligible, serviceEnabled } from '../../../utils/portalElements';
import { getHelpSections, getMainContent } from '../../../api/ContentStack';
import {
  contentStackRedundantLocales,
} from '../../../locale/index';

// Change mock to true if you want to test help center on local dev environment
const mock = false;
const URL_HP_PLUS = '/hp-plus'; // have to confirm if this is correct
const URL_HP_INSTANT_INK = '/hp-instant-ink';
const URL_HP_SMART_ADVANCE = '/hp-smart-advance';
const URL_ABOUT_HP_SMART = '/about-hp-smart';

const PROGRAM_LEVEL_HP_PLUS = 'hpplus';

const HELP_TOPIC_HP_SMART_ADVANCE = 'HP Smart Advance';
const HELP_TOPIC_HP_INSTANT_INK = 'HP Instant Ink';
const HELP_TOPIC_HP_SMART_SECURITY = 'HP Smart Security';

const getPathname = () => window.location.pathname;
const selectRootMatch = () => matchPath(getPathname(), { path: '/:country/:language/:resource?', exact: false });
const selectHelpCenterMatch = () => (
  matchPath(getPathname(), { path: '/:country/:language/:resource/help/:topic?/:subtopic?', exact: true })
);

const fixAllUrls = html => html.replace(/http:\/\//ig, 'https://');

const getCountryLanguage = () => {
  if (selectRootMatch()) {
    const { country, language } = selectRootMatch().params;
    return { country, language };
  }
  return { country: 'us', language: 'en' };
};

const getLocale = () => {
  const { country, language } = getCountryLanguage();
  const localeKey = `${language}_${country?.toUpperCase()}`;
  const substitutedLocale = contentStackRedundantLocales[localeKey] || localeKey;
  return substitutedLocale.replace('_', '-').toLowerCase();
};

const filterSectionsFromSource = ({ sections, programLevel = undefined, ucdePortalElements }) => sections
  .sort((a, b) => a.index - b.index)
  .filter(section => {
    const { url = '' } = section;
    if (programLevel && typeof programLevel === 'string' && url === URL_HP_PLUS) {
      return programLevel.toLowerCase() === PROGRAM_LEVEL_HP_PLUS;
    }
    if (url === URL_HP_INSTANT_INK && !serviceEligible(ucdePortalElements, SERVICES.PRINT_PLANS)) {
      return false;
    }
    if (url === URL_HP_SMART_ADVANCE && !serviceEligible(ucdePortalElements, SERVICES.HP_SMART_ADVANCE)) {
      return false;
    }
    return true;
  });

export const getSectionsFromSource = async ({ programLevel, ucdePortalElements }) => {
  let sections = [];

  if (mock) {
    sections = getSectionsFromMock.entries;
  } else {
    try {
      const { entries } = await getHelpSections(getLocale());
      sections = entries;
    } catch (e) {
      const { entries } = await getHelpSections();
      sections = entries;
    }
  }

  return filterSectionsFromSource({ sections, programLevel, ucdePortalElements });
};

export const getMainContentFromSource = async () => {
  let entries = [];

  if (mock) {
    entries = getMainContentFromMock.entries;
  } else {
    try {
      ({ entries } = await getMainContent(getLocale()));
    } catch (e) {
      ({ entries } = await getMainContent());
    }
  }

  return entries;
};

const findTopicContentFromSections = curry((sections, topic) => find(
  propEq('url', topic),
  sections,
));

export const selectHelpCenterPageProps = async sections => {
  const match = selectHelpCenterMatch();

  if (!match || !match.params || !sections) {
    return {};
  }

  const { params } = match;

  if (params.topic) {
    params.topic = `/${params.topic}`;
  }

  const {
    topic,
    subtopic,
    country,
    language,
  } = params;

  const firstSlug = sections && sections[0] ? sections[0].url : URL_ABOUT_HP_SMART;

  const findTopicContent = findTopicContentFromSections(sections);
  const validTopics = map(prop('url'), sections);
  const topicIsValid = topic && validTopics.includes(topic);

  const { title } = topicIsValid ? findTopicContent(topic) : {};

  return {
    firstSlug,
    topicIsValid,
    topic,
    subtopic,
    title,
    country,
    language,
    rightColumnLinks: [{
      title: 'My Account',
      links: [{
        to: '#',
        text: 'Can\'t access your account',
      }, {
        to: '#',
        text: 'Learn how to change your password and fix login issues.',
      }],
    }, {
      title: 'Billing',
      links: [{
        to: '#',
        text: 'Resolve issues when credit card failed?',
      }, {
        to: '#',
        text: 'Change the credit card on file for your account?',
      }],
    }, {
      title: 'Plans & Pricing',
      links: [{
        to: '#',
        text: 'Update your Instant Supplies plan',
      }, {
        to: '#',
        text: 'How to log into the account at hpinstantink.com',
      }],
    }],
  };
};

const getTitles = data => ({
  help_for_android: data?.[0]?.smart_help_app_section_titles?.[0]?.smart_titles?.android_app_section_title,
  help_for_desktop: data?.[0]?.smart_help_app_section_titles?.[0]?.smart_titles?.desktop_app_section_title,
  help_for_ios: data?.[0]?.smart_help_app_section_titles?.[0]?.smart_titles?.ios_app_section_title,
  help_for_mobile: data?.[0]?.smart_help_app_section_titles?.[0]?.smart_titles?.mobile_app_section_title,
});

const contentKeys = [
  'help_for_all_apps',
  'help_for_android',
  'help_for_desktop',
  'help_for_ios',
  'help_for_mobile',
];

const siftData = (titles, data, useNoHpPlusText) => contentKeys.reduce((accum, key) => {
  const noHpPlusKey = 'help_for_all_apps_no_hp_plus';
  const finalKey = useNoHpPlusText ? noHpPlusKey : key;
  const { helpSection } = data;

  if (helpSection[key]) {
    return key === 'help_for_all_apps'
      ? [
        {
          id: finalKey,
          title: helpSection.helpTopic,
          content: fixAllUrls(helpSection[finalKey]),
        },
        ...accum,
      ]
      : [
        ...accum,
        {
          title: prop(finalKey, titles),
          id: finalKey,
          content: fixAllUrls(helpSection[finalKey]),
        },
      ];
  }
  return accum;
}, []);

export const kebabCase = str => str
  .split(' ')
  .map(word => word.toLowerCase())
  .join('-');

const filterHelpSections = ({ sections, ucdePortalElements }) => sections.filter(section => {
  if (section.helpTopic === HELP_TOPIC_HP_SMART_ADVANCE
    && !serviceEnabled(ucdePortalElements, SERVICES.HP_SMART_ADVANCE)) {
    return false;
  }
  if (section.helpTopic === HELP_TOPIC_HP_INSTANT_INK
    && !serviceEligible(ucdePortalElements, SERVICES.PRINT_PLANS)) {
    return false;
  }
  if (section.helpTopic === HELP_TOPIC_HP_SMART_SECURITY
    && !serviceEnabled(ucdePortalElements, SERVICES.SMART_SECURITY)) {
    return false;
  }
  return section;
});

export const selectHelpCenterMainProps = async ({ ucdePortalElements }, main, sections) => {
  const match = selectHelpCenterMatch();

  if (!match || !match.params || !main || !sections) {
    return [];
  }

  const topic = `/${match?.params?.topic}`;
  const titles = getTitles(main);
  const findTopicContent = findTopicContentFromSections(sections);
  const { app_help_section: appHelpSection = [] } = (findTopicContent(topic) || {});
  const filteredHelpSection = filterHelpSections({ sections: appHelpSection, ucdePortalElements });

  const programLevel = ucdePortalElements?.info?.programLevel;
  const useNoHpPlusText = (!programLevel || programLevel.toLowerCase() !== PROGRAM_LEVEL_HP_PLUS)
    && topic === URL_HP_INSTANT_INK;

  return filteredHelpSection.map(section => {
    const { Help_link: helpLink } = section;
    if (helpLink?.help_link_text) {
      return {
        title: {
          text: helpLink.help_link_text,
          to: fixAllUrls(helpLink.help_link_address),
        },
        dataAnalyticsTag: helpLink.analytics_id,
        id: kebabCase(helpLink.analytics_id),
      };
    }

    const {
      help_topic: title,
      analytics_id: dataAnalyticsTag,
    } = section.helpSection;
    return {
      title,
      dataAnalyticsTag,
      id: kebabCase(dataAnalyticsTag),
      content: siftData(titles, section, useNoHpPlusText),
    };
  });
};

export const selectHelpCenterMobileProps = async ({ ucdePortalElements }, main, sections) => {
  const match = selectHelpCenterMatch();

  if (!match || !match.params || !sections) {
    return [];
  }

  const { params } = match;

  if (params.topic) {
    params.topic = `/${params.topic}`;
  }

  const {
    resource = 'ucde',
    topic,
    subtopic,
    country,
    language,
  } = params;

  if (subtopic && !main) {
    return [];
  }

  const findTopicContent = findTopicContentFromSections(sections);

  const { app_help_section: appHelpSection = [] } = (findTopicContent(topic) || {});

  const filteredHelpSection = filterHelpSections({ sections: appHelpSection, ucdePortalElements });

  const programLevel = ucdePortalElements?.info?.programLevel;
  const useNoHpPlusText = (!programLevel || programLevel.toLowerCase() !== PROGRAM_LEVEL_HP_PLUS)
    && topic === URL_HP_INSTANT_INK;

  if (subtopic) {
    const subSection = filteredHelpSection.find(section => {
      const sectionData = section.helpSection || section.Help_link;
      const { analytics_id: analyticsID } = sectionData;
      return subtopic === kebabCase(analyticsID);
    });
    if (subSection) {
      const titles = getTitles(main);
      return siftData(titles, subSection, useNoHpPlusText).map(packet => ({
        ...packet,
        title: {
          text: packet.title,
        },
      }));
    }
  }
  return filteredHelpSection.map(section => {
    const sectionData = section.helpSection || section.Help_link;

    const {
      analytics_id: analyticsID,
      help_link_text: helpLinkText,
      help_link_address: helpLinkAddress,
      help_topic: helpTopic,
    } = sectionData;

    const kebabbedId = kebabCase(analyticsID);
    const sectionMeta = {
      id: kebabbedId,
      dataAnalyticsTag: analyticsID,
    };
    if (helpLinkText) {
      return {
        ...sectionMeta,
        title: {
          text: helpLinkText,
          to: fixAllUrls(helpLinkAddress),
        },
      };
    }
    const pathname = `/${country}/${language}/${resource}/help${topic}/${kebabbedId}`;
    const titles = getTitles(main);
    const uniqueItem = filteredHelpSection.length === 1;
    const content = uniqueItem ? siftData(titles, section, useNoHpPlusText)[0]?.content : null;

    return {
      ...sectionMeta,
      title: {
        text: helpTopic,
        destination: {
          pathname,
          state: {
            takeover: helpTopic,
          },
        },
      },
      content: uniqueItem && content,
    };
  });
};

export const selectHelpLinks = async ({ programLevel, ucdePortalElements }) => {
  const match = selectRootMatch() || {};
  const sections = await getSectionsFromSource({ programLevel, ucdePortalElements });

  if (!match || !match.params || !sections) {
    return [];
  }

  const {
    country = 'us',
    language = 'en',
    resource = 'ucde',
  } = match.params;

  const links = sections?.map(({ url, title }) => {
    const pathname = ['/', country, '/', language, '/', resource, '/help', url].join('');
    return {
      label: title,
      pathname,
    };
  });

  return links;
};
