import {
  always,
  compose,
  curry,
  head,
} from 'ramda';
import ReactDOMServer from 'react-dom/server';
import {
  LTR,
  RTL,
} from '../constants/i18n';

const getTags = tagName => always(document.getElementsByTagName(tagName));

export const getMetaTagByName = name => {
  const tags = getTags('meta')();
  return tags[name];
};

export const setTagAttribute = (tag, attributeName, attributeValue) => {
  if (tag) {
    tag.setAttribute(attributeName, attributeValue);
  }
};

const getHtmlAttribute = curry(attribute => {
  const htmlTag = compose(
    head,
    getTags('html'),
  )();
  return htmlTag.getAttribute(attribute);
});

const setHtmlAttribute = curry((attribute, value) => {
  const htmlTag = compose(
    head,
    getTags('html'),
  )();
  return htmlTag.setAttribute(attribute, value);
});

export const getHtmlDir = () => getHtmlAttribute('dir');

export const getHtmlLang = () => getHtmlAttribute('lang');

export const setHtmlDirRtl = () => setHtmlAttribute('dir', RTL);

export const setHtmlDirLtr = () => setHtmlAttribute('dir', LTR);

export const setHtmlLang = setHtmlAttribute('lang');

const encodeProperty = property => encodeURIComponent(property);

export const getCookie = cookieName => {
  const { cookie = '' } = document;
  // From https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
  // the craft routing stuff references the "gnb_locale" cookie, that will be our cookie-source of language truth
  const cookieNameEncoded = encodeProperty(cookieName);
  const contentLanguage = decodeURIComponent(
    cookie.replace(
      new RegExp(`(?:(?:^|.*;)\\s*${cookieNameEncoded}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      '$1',
    ),
  ) || '';
  const cookieContent = (Array.isArray(contentLanguage) && contentLanguage.includes(','))
    ? contentLanguage.split(',')[0]
    : contentLanguage;
  return cookieContent;
};

const getDomainName = () => {
  let hostName = window.location.hostname;
  let domainName = '';
  if (hostName.split('.').length >= 3) {
    hostName = hostName.split('.');
    const { length } = hostName;
    domainName = `.${hostName[length - 2]}.${hostName[length - 1]}`;
  } else {
    domainName = `.${hostName}`;
  }
  return domainName;
};

const getExpiration = (age = undefined) => {
  let expiration = ''; // session by default
  if (age) {
    expiration = `max-age=${age};`;
  }
  return expiration;
};

export const setCookie = (cookieName, cookieValue, age = undefined) => {
  const expiration = getExpiration(age);
  const domainName = getDomainName();
  document.cookie = `${encodeProperty(cookieName)}=${encodeProperty(cookieValue)};${expiration}path=/;domain=${domainName}`;
};

export const deleteCookie = cookieName => {
  setCookie(cookieName, '', -1);
};

export const setCsrfState = value => sessionStorage.setItem('csrfState', value);
export const getCsrfState = () => sessionStorage.getItem('csrfState');

export const strValueToBool = value => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  const valueNormalized = String(value).toLocaleLowerCase();
  if (/^f(alse)?$|^off$|^no?$|^0$|^null$/.test(valueNormalized)) {
    return false;
  }
  if (/^t(rue)?$|^on$|^y(es)?$|^1$/.test(valueNormalized)) {
    return true;
  }
  return undefined;
};

export const randomStringId = () => Math.random().toString(36).substr(2, 8);

export const extractParamsFromQueryString = queryString => {
  if (!queryString) {
    return {};
  }

  const paramsArray = queryString.split('&');
  const params = paramsArray.reduce((acc, param) => {
    const [key, value] = param.split('=');
    acc[key] = value;
    return acc;
  }, {});

  return params;
};

export const cropText = ({
  data,
  limit,
  startWithEllipsis,
  showEllipsis,
}) => {
  const nodeIdLenght = data.length || 0;
  const ellipsis = showEllipsis ? '...' : '';

  if (nodeIdLenght > limit) {
    if (startWithEllipsis) {
      return `${ellipsis}${data?.substr(nodeIdLenght - limit)}`;
    }
    return `${data?.substr(0, limit)}${ellipsis}`;
  }

  return data;
};

export const splitLocaleIntoCountryAndLanguage = (locale = '') => {
  const [, language, country] = /^([^-_]{2})(?:(?:[-_][^-_]+)?(?:[-_]([^-_]{2})))?$/
    .exec(locale.toLocaleLowerCase()) || [];
  return { language, country };
};

export const getBrowserLocale = () => {
  const browserLocaleString = window.navigator.browserLanguage || window.navigator.language;
  const {
    country,
    language,
  } = splitLocaleIntoCountryAndLanguage(browserLocaleString);

  return {
    country,
    language,
  };
};

export const convertComponentToStaticMarkup = component => ReactDOMServer.renderToStaticMarkup(component);
