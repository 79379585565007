import {
  pathOr,
} from 'ramda';

export const serviceEnabled = (portalElements, service = '') => (
  ['published', 'enabled'].includes(
    pathOr('', ['services', 'cards', service, 'state'], portalElements).toLowerCase(),
  )
);

export const serviceEligible = (portalElements, service = '') => (
  ['published', 'enabled', 'disabled', 'pending'].includes(
    pathOr('', ['services', 'cards', service, 'state'], portalElements).toLowerCase(),
  )
);

export const isItemEnabled = (ffEnableItem, ffUseIsEnabled, isEnabled) => {
  if (ffEnableItem) {
    if (ffUseIsEnabled) {
      return isEnabled;
    }
  }
  return ffEnableItem;
};

export default {
  serviceEnabled,
};
