import styled from 'styled-components';
import Header from '@veneer/core/dist/scripts/header';
import Images from '../../../assets/images';

// AppBar Styles
export const HeaderContainer = styled(Header)`
  height: 68px;

  && {
    grid-template-columns: 96px auto 96px;
    -ms-grid-columns: 96px auto 96px;
    padding: 8px 19px 10px 19px;
    border-bottom: 1px solid #EBEBEB;
    z-index: 1000;
    position: fixed;
    top: 0;
  }

  .vn-notification-center__no-notifications {
    padding: 90px 0 30px 0;
    background: #ffffff url(${Images.noNotifications}) no-repeat center 20px;
  }

  @media (min-width: 1200px) {
    ${props => (props.theme.hostIsDesktopApp ? 'visibility: hidden;' : '')};
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  margin-top: 6px;
  margin-left: 10px;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  background: transparent;
  color:  ${props => props.theme.main.hp.color};
  font-family: ${props => props.theme.main.hp.fontFamily};
  border: 0;
  cursor: pointer;
  height: 48px;
  padding: 0;
`;

export const RightArea = styled.div`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: inline-flex;
`;

export const CenterArea = styled.div`
  width: 100%;

  > * {
    width: 100%;
  }
`;
