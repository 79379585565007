import { put, takeLeading } from 'redux-saga/effects';
import {
  getHpAdvancedSubscription,
  getHpAdvancedSubscriptionRequest,
} from './actions';

function* loadHpAdvancedSubscription() {
  yield put(getHpAdvancedSubscription);
}

export default function* watchHpAdvancedSubscription() {
  yield takeLeading(
    getHpAdvancedSubscriptionRequest.toString(),
    loadHpAdvancedSubscription,
  );
}
