import { isEmpty, or } from 'ramda';
import Types from './types';

const INITIAL_STATE = {
  // When true, no component is blocking the closing of the Portal due
  // to unsaved changes or some other condition.
  canClose: true,
  closeAttempt: false,
  // Stores a boolean for each component that represents if
  // that component is allowing or not the Portal to close.
  components: {},
};

export default function portalClose(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CLOSE_PORTAL:
      return {
        ...state,
        closeAttempt: !state.canClose,
      };
    case Types.SET_COMPONENT_CAN_CLOSE:
      return {
        ...state,
        canClose: or(
          isEmpty(state.components),
          Object
            .entries(state.components)
            // If every component 'canClose' is true, set global 'canClose' to true.
            .every(([componentName, canClose]) => (componentName === action.componentName
              ? action.canClose : canClose)),
        ),
        components: {
          ...state.components,
          [action.componentName]: action.canClose,
        },
      };
    case Types.SET_PORTAL_CLOSE_ATTEMPT:
      return {
        ...state,
        closeAttempt: action.closeAttempt,
      };
    default:
      return state;
  }
}
