import {
  identity,
  path,
  pathOr,
} from 'ramda';
import base64url from 'base64url';
import { createSelector } from 'reselect';
import { selectOnboardingRouteProps } from './routing';
import { extractParamsFromQueryString, getCsrfState } from '../utils/globals';
import Config from '../config';

const SELECTORS_BASE = {
  selectUserInfo: path(['userInfo', 'userInfoData']),
  selectUserCurrentLocale: pathOr('', ['userInfo', 'userInfoData', 'locale']),
  selectOnboardingStep: pathOr(null, ['userInfo', 'step']),
};

export const selectUserLoggedIn = createSelector(
  SELECTORS_BASE.selectUserInfo,
  userInfo => Boolean(userInfo),
);

export const selectUserCurrentLocale = createSelector(
  SELECTORS_BASE.selectUserCurrentLocale,
  identity,
);

export const selectUserInfoData = createSelector(
  SELECTORS_BASE.selectUserInfo,
  identity,
);

export const transformOnboardingProps = ({
  encodedState,
  country,
  language,
}, storedCsrfMatch) => {
  const decodedState = base64url.decode(encodedState);
  const parsedState = JSON.parse(decodedState || '{}');
  const csrf = parsedState.csrfToken;
  const { redirectTo } = parsedState;
  const { baseUrl } = Config;
  const uiExtensionInfoId = 'ucde.user.portal.onboard';

  let callType;
  let callTypeValue;

  if (parsedState.queryParams && parsedState.queryParams.ct) {
    const { ct, v } = parsedState.queryParams;
    callType = ct;
    callTypeValue = v;
  } else if (redirectTo) {
    const [, queryString] = redirectTo.split('?');
    if (queryString) {
      const { ct, v } = extractParamsFromQueryString(queryString);
      callType = ct;
      callTypeValue = v;
    }
  }

  return ({
    country,
    language,
    baseUrl,
    uiExtensionInfoId,
    csrfMatch: csrf === storedCsrfMatch,
    redirectTo,
    encodedState,
    callType,
    callTypeValue,
  });
};

export const selectOnboardingProps = () => createSelector(
  selectOnboardingRouteProps,
  getCsrfState,
  transformOnboardingProps,
);
