import {
  call,
  put,
  takeLeading,
} from 'redux-saga/effects';
import {
  propOr,
} from 'ramda';
import {
  getDevicesOverallStatus,
} from '../../../api/UCDEGateway';
import {
  getOverallStatus,
  getOverallStatusFailed,
  getOverallStatusSuccess,
} from './actions';

function* fetchDevicesOverallStatus(action) {
  try {
    const response = yield call(getDevicesOverallStatus, action.payload);
    const statusesData = propOr('', 'data', response);

    yield put(getOverallStatusSuccess(propOr('', 'devicesStatuses', statusesData)));
  } catch (e) {
    yield put(getOverallStatusFailed());
  }
}

function* watchDevicesOverallStatus() {
  yield takeLeading(getOverallStatus.toString(), fetchDevicesOverallStatus);
}

export default watchDevicesOverallStatus;
