import React, { useEffect, useState } from 'react';
import Button from '@veneer/core/dist/scripts/button';
import PropTypes from 'prop-types';
import { createVALinkWithToken } from '../../utils/virtualAgent';
import i18n from '../../utils/i18n';
import Images from '../../assets/images';
import Loader from '../shared-components/Loader';
import {
  Body,
  Footer,
  Header,
} from './styles';
import { getSessionID } from './helpers/getSessionID';

const VirtualAgent = ({
  match,
  location,
  userId,
  removeModal,
  newApiLanguages,
}) => {
  const [vaState, setVAState] = useState({ loading: true, error: null, link: null });

  const sessionID = getSessionID();

  const close = () => {
    if (removeModal) {
      removeModal();
    }
  };

  useEffect(() => {
    let subscribed = true;
    async function loadLink() {
      try {
        if (vaState.loading) {
          const vaLinkWithToken = await createVALinkWithToken(
            match,
            location,
            userId,
            sessionID,
            newApiLanguages,
          );
          if (subscribed) {
            setVAState({ loading: false, error: null, link: vaLinkWithToken });
          }
        }
      } catch (e) {
        if (subscribed) {
          setVAState({ loading: false, error: e, link: null });
        }
      }
    }
    loadLink();
    return () => {
      subscribed = false;
    };
  }, [vaState.loading]);

  if (vaState.error) {
    return (
      <>
        <Header>{i18n.t('virtualAgent.dialog.fail.header')}</Header>
        <Body>{i18n.t('virtualAgent.dialog.fail.body')}</Body>
        <Footer>
          <a
            className="button secondary"
            href="https://support.hp.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={close}
          >
            {i18n.t('virtualAgent.dialog.fail.secondary')}
          </a>
          <Button
            aria-label={i18n.t('virtualAgent.dialog.fail.primary')}
            onClick={close}
          >
            {i18n.t('virtualAgent.dialog.fail.primary')}
          </Button>
        </Footer>
      </>
    );
  }

  if (vaState.loading) {
    return (
      <>
        <Body>
          <Loader />
        </Body>
        {i18n.t('virtualAgent.dialog.load.body')}
      </>
    );
  }

  return (
    <>
      <Header>{i18n.t('virtualAgent.dialog.ready.header')}</Header>
      <Body>
        <img alt="Virtual Assistant" src={Images.virtualAssistant} />
        {i18n.t('virtualAgent.dialog.ready.body')}
      </Body>
      <Footer>
        <Button
          aria-label={i18n.t('virtualAgent.dialog.ready.secondary')}
          appearance="secondary"
          onClick={close}
        >
          {i18n.t('virtualAgent.dialog.ready.secondary')}
        </Button>
        <a
          className="button"
          href={vaState.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={close}
        >
          {i18n.t('virtualAgent.dialog.ready.primary')}
        </a>
      </Footer>
    </>
  );
};

VirtualAgent.defaultProps = {
  removeModal: null,
  newApiLanguages: [],
};

VirtualAgent.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  removeModal: PropTypes.func,
  newApiLanguages: PropTypes.arrayOf(PropTypes.string),
};

export default VirtualAgent;
