export function makeActionCreator(type, ...argNames) {
  function createAction(...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[arg] = args[index];
    });
    return action;
  }

  createAction.toString = () => type;

  return createAction;
}

export function createReducer(initialState = {}, handlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
