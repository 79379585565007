import { call, put, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getDecodedStratusAccessToken, isJWebNative, jwebSignIn } from '../../../utils/auth';
import { initializeUcdeUser } from '../ucdeUser/actions';
import jWebActions, { jwebSignInFailed, jwebSignInRequest, jwebSignInSuccess } from './actions';
import Config from '../../../config';

export function* initializeJWeb() {
  yield put(jWebActions.getJWebLanguageCode);
}

export function* initializeJWebSignIn({
  path = '/ucde', requireFreshToken = false, skipTokenRefresh = false,
}) {
  try {
    const decodedToken = getDecodedStratusAccessToken();
    const tenantId = decodedToken?.tenant_id;

    yield call(jwebSignIn, { requireFreshToken, skipTokenRefresh });

    // When requireFreshToken and skipTokenRefresh are true, the user is sent to the login screen.
    // When the portal is using jweb, after returning from the login screen it is necessary to perform a token-exchange with the jweb getToken method.
    if (requireFreshToken && skipTokenRefresh && isJWebNative() && tenantId?.trim()) {
      yield call(jwebSignIn, { tenantId });
    }

    yield put(jwebSignInSuccess());

    if (Config.isHpxApp) {
      yield put(push(path));
    } else {
      yield put(initializeUcdeUser(path));
    }
  } catch (jwebSigninError) {
    yield put(jwebSignInFailed());
  }
}

export function* watchJWebSignIn() {
  yield takeLeading(jwebSignInRequest.toString(), initializeJWebSignIn);
}

export default initializeJWeb;
