import types from './types';
import { makeActionCreator } from '../../../utils/redux';

export const getUsersAction = makeActionCreator(types.GET_USERS, 'payload');

export const getUsersActionSuccess = makeActionCreator(types.GET_USERS_SUCCESS, 'payload');

export const getUsersActionFailed = makeActionCreator(types.GET_USERS_FAILED, 'payload');

export const getUsersAutoRetry = {
  requestActionCreator: getUsersAction,
  successActionCreator: getUsersActionSuccess,
  failureActionCreator: getUsersActionFailed,
};
