import Types from './types';

const INITIAL_STATE = {};

export default function ucdeUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_UCDE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.LOAD_UCDE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
      };
    case Types.LOAD_UCDE_USER_FAILED:
      return {
        ...state,
        loadUserApiError: true,
      };
    case Types.LOAD_UCDE_USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.LOAD_UCDE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload.data,
      };
    case Types.LOAD_UCDE_USER_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        profileApiError: true,
      };
    case Types.SAVE_UCDE_USER_SUCCESS:
      return {
        ...state,
        saving: false,
        ...action.payload.data,
      };
    case Types.SAVE_UCDE_USER_FAILED:
      return {
        ...state,
        profileApiError: true,
        isLoading: false,
        saving: false,
      };
    case Types.REMOVE_UCDE_USER:
      return {
        ucdeUser: {},
      };
    default:
      return state;
  }
}
