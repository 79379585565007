import * as actions from './actions';
import { createReducer } from '../../../utils/redux';

const INITIAL_STATE = {};

function getUsersRequest(state) {
  return state;
}

function getUsersFailed(state) {
  return state;
}

function getUsersSuccess(state, action) {
  return action.payload;
}

const handlers = {
  [actions.getUsersAction]: getUsersRequest,
  [actions.getUsersActionFailed]: getUsersFailed,
  [actions.getUsersActionSuccess]: getUsersSuccess,
};

const usersReducer = createReducer(INITIAL_STATE, handlers);

export default usersReducer;
