import styled from 'styled-components';
import { UCDEMainTheme } from '../../pages/styles';

export const Header = styled.h4`
  padding-bottom: 20px;
`;

export const Footer = styled.div`
  padding-top: 10px;

  > button {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  > a.button {
    align-items: center;
    background-color: ${UCDEMainTheme.main.hp.color};
    border-color: ${UCDEMainTheme.main.hp.color};
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: ${UCDEMainTheme.main.hp.fontFamily};
    font-size: ${UCDEMainTheme.main.hp.fontSize};;
    justify-content: center;
    line-height: 24px;
    margin: 10px 5px 0 5px;
    min-width: 112px;
    padding: 11px 31px;
    position: relative;
    text-align: center;
    transition: background-color 0.3s ease,border-color 0.3s ease,color 0.3s ease;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: 0;
    box-shadow: none;

    &:active {
      background-color: #014667;
      border-color: #014667;
    }

    &:hover {
      background-color: #035c84;
      border-color: #035c84;
      text-decoration: none;
    }

    &.secondary {
      background-color: transparent;
      color: ${UCDEMainTheme.main.hp.color};

      &:active {
        background-color: ${UCDEMainTheme.main.hp.lightColor};
        color: #014667;
      }

      &:hover {
        background-color: ${UCDEMainTheme.main.hp.lightColor};
        color: #035c84;
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    padding-right: 15px;
    width: 60px;
  }
`;
