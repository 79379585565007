import base64url from 'base64url';
import {
  curry,
  last,
  pathOr,
  prop,
} from 'ramda';
import { matchPath } from 'react-router';
import {
  GATEWAY_PATHS_ENABLED_FOR_BASE_USERS,
  HP_PLUS_SECOND_OFFER_PUBLIC_PATH,
  MOBILE_FAX_PUBLIC_PATH,
  PAPER_SUBSCRIPTION_PUBLIC_PATH,
  PATHS,
  ROUTES_NO_NEED_PORTAL_ELEMENTS,
} from './constants';
import { getBrowserLocale } from './globals';
import i18n from './i18n';

export const createPathWithLocalePrefix = curry(({ country, language }, etc) => {
  let prefix = '';

  if (country && language) {
    prefix = `/${country}/${language}`;
    if (etc.startsWith(prefix)) {
      return etc;
    }
  }

  return `${prefix}${etc.startsWith('/') ? etc : `/${etc}`}`;
});

export const formatFinalPath = path => {
  const pathSlashed = path.startsWith('/') ? path : `/${path}`;
  return last(pathSlashed) === '/' ? pathSlashed.substring(0, pathSlashed.length - 1) : pathSlashed;
};

export const mergePaths = (base, end) => {
  if (end === '/' || end === '') { // Returns base without slash at the end.
    return formatFinalPath(base);
  }

  const baseSlashed = last(base) === '/' ? base : `${base}/`; // Adds slash at the end if none present
  const endTrimmed = end.startsWith('/') ? end.substring(1) : end; // Removes slash at the front if present
  return formatFinalPath(`${baseSlashed}${endTrimmed}`);
};

const getPrefix = (match, pathname) => {
  const url = prop('url', match);
  const country = pathOr('', ['params', 'country'], match);
  const language = pathOr('', ['params', 'language'], match);
  const pathnameStartsWithMatchUrl = pathname.startsWith(url) || pathname.startsWith(`/${country}/${language}/gateway/`);
  return pathnameStartsWithMatchUrl ? '' : url;
};

export const makePathRelative = curry((match, pathname) => {
  const prefix = getPrefix(match, pathname);
  const relPath = mergePaths(prefix, pathname);
  return relPath;
});

export const getBasePath = () => {
  const match = window.location.pathname;
  const locale = match.substring(0, 7);

  if (match.includes('ucde')) {
    return `${locale}ucde/`;
  }

  return `${locale}gateway/`;
};

export const checkCountryLanguageAndGetFallbacks = (countryParam, languageParam) => {
  const country = countryParam;
  const language = languageParam;
  const defaultCountry = i18n.getDefaultCountry().toLowerCase();

  if (language) {
    // If language + country is a published locale, go with that.
    if (country && i18n.isLocalePublished(country, language)) {
      return { country, language };
    }

    // Does this language have a fallback country?
    if (i18n.isLanguageSupported(language)) {
      const fallbackCountry = i18n.getFallbackCountry(language).toLowerCase();

      // The combination of the fallback country and the language might not be a published locale,
      // so we need to check if the locale is actually published.
      if (i18n.isLocalePublished(fallbackCountry, language)) {
        return {
          country: fallbackCountry,
          language,
        };
      }
    }
  }

  // If locale is not published/not defined, return default locale.
  return {
    country: defaultCountry,
    language: i18n.getDefaultLanguage(),
  };
};

export const createRelativePath = (match, path) => mergePaths(match.url, path);

export const defaultRoute = props => {
  const {
    location: {
      pathname,
      search,
    },
  } = props;
  const url = search ? `${pathname}${search}` : pathname;
  let { country, language } = getBrowserLocale();

  ({ country, language } = checkCountryLanguageAndGetFallbacks(country, language));

  return createPathWithLocalePrefix({ country, language }, url === '/' ? `${url}ucde` : url);
};

export const encodeSignInState = (url, promptLogin, queryParams) => base64url.encode(JSON.stringify({
  redirectTo: url,
  promptLogin,
  queryParams,
}));

export const encodeAuthState = (csrfToken, url, backendRedirect) => base64url.encode(JSON.stringify({
  redirectTo: url,
  csrfToken,
  ...backendRedirect && { redirect_uri: backendRedirect },
}));

export const removeCountryAndLanguage = (path = '') => path
  .replace(/^\/..\/../, '')
  .replace(/\?.*/, '');

export const splitRouteIntoCountryAndLanguage = (path = '') => {
  let [country, language] = path.toLowerCase().split('/').slice(1, 3) || [];

  if (!path || country.length !== 2 || language.length !== 2) {
    return [];
  }
  ({ country, language } = checkCountryLanguageAndGetFallbacks(country, language));

  return [country, language];
};

export const isHpxMyAccountRoute = path => (
  path.replace(/\/(gateway)/, '').includes(PATHS.HPX_MY_ACCOUNT)
);

export const gatewayRouteEnabledForBaseUser = (path = '') => (
  GATEWAY_PATHS_ENABLED_FOR_BASE_USERS.some(match => matchPath(path, `/(ucde|gateway)${match}`))
);

export const gatewayOnlyRouteEnabledForBaseUser = (path = '') => (
  GATEWAY_PATHS_ENABLED_FOR_BASE_USERS.some(match => matchPath(path, `*/(gateway)${match}`))
);

export const signInOrOnboardingRoute = path => (
  [PATHS.ONBOARDING, PATHS.SIGNIN].includes(path.replace(/\/(ucde|gateway)/, ''))
);

export const checkIfRouteNeedsPortalElements = (path = '') => (
  !(ROUTES_NO_NEED_PORTAL_ELEMENTS.some(match => matchPath(path, `*/(gateway)${match}`)))
);

// hpc-frontend doesn't support redirecting from a public page to a public page, so when coming from HP+ Second Offer
// or Mobile Fax public pages we need to redirect to /ucde/my-printers (SDASH-4489, SDASH-4497)
export const shouldRedirectToMyPrintersPage = (path = '') => {
  const [country, language] = path.toLowerCase().split('/').slice(1, 3) || [];

  if (!path || country.length !== 2 || language.length !== 2) {
    return false;
  }

  const hpPlusSecondOfferPublicPath = `/${country}/${language}${HP_PLUS_SECOND_OFFER_PUBLIC_PATH}`;
  const mobileFaxPublicPath = `/${country}/${language}${MOBILE_FAX_PUBLIC_PATH}`;
  const paperSubscriptionPublicPath = `/${country}/${language}${PAPER_SUBSCRIPTION_PUBLIC_PATH}`;

  return [
    hpPlusSecondOfferPublicPath,
    mobileFaxPublicPath,
    paperSubscriptionPublicPath].some(item => item === path);
};
