import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from './redux';
import { selectPageViewEventInfo } from '../selectors/analytics';
import { updateShortTitleMeta } from '../actions/analytics';
import { usePrevious } from './utility';
import injectUDL from '../utils/injectUDL';
import Config from '../config';

export const useHandlePageViewTracking = (customAnalyticsParams = {}) => {
  const dispatch = useDispatch();
  const [initialPathname, setInitialPathname] = useState(null);

  const {
    pathname,
    eventInfo,
    ...rest
  } = useSelector(selectPageViewEventInfo);
  const prevPath = usePrevious(pathname);
  const pageViewEvent = {
    event: 'e_pageView',
    ...eventInfo,
    ...rest,
  };

  if (customAnalyticsParams.userTypeSession !== '') {
    pageViewEvent.userTypeSession = customAnalyticsParams.userTypeSession;
  }

  if (customAnalyticsParams.appBuild) {
    pageViewEvent.v151 = customAnalyticsParams.appBuild;
  }

  if (customAnalyticsParams.jWebVersion) {
    pageViewEvent.v152 = customAnalyticsParams.jWebVersion;
  }

  useEffect(() => {
    if (window.dataLayer && eventInfo && pathname !== prevPath && !Config.isHpxApp) {
      window.dataLayer.push(pageViewEvent);
      dispatch(updateShortTitleMeta(pageViewEvent.pageNameL8));
    }
  }, [pathname]);

  if (!initialPathname) {
    injectUDL({
      callback: () => setInitialPathname(pathname),
    });
  }
};

export default useHandlePageViewTracking;
