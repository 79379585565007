import { isMobile } from 'react-device-detect';

const windowOpen = (
  url,
  target = '_blank',
  rel = 'noopener noreferrer',
) => {
  if (isMobile) {
    document.location.href = url;
    document.location.target = target;
    document.location.rel = rel;
  } else {
    window.open(url, target, rel);
  }
};

export default windowOpen;
