import windowOpen from './windowOpener';
import { history } from '../App';
import { getBasePath, makePathRelative } from './routing';
import { DEEPLINK_MAP } from './constants';
import { registerUDLEvent } from './registerUDLEvent';

const HP_SMART_APP_SCHEMA = 'hp-smart-app';
const HPDL_SCHEMA = 'hpdl';

export const isValidSchema = schema => (
  schema === HP_SMART_APP_SCHEMA || schema === HPDL_SCHEMA
);

export const buildLink = ({
  schema,
  key,
  path,
  parameters,
  fragment,
  link,
}) => {
  let resultLink = link;
  if (isValidSchema(schema)) {
    const base = DEEPLINK_MAP[key];
    if (typeof base !== 'undefined') {
      resultLink = `${base}${path ? `/${path}` : ''}${parameters ? `?${parameters}` : ''}${fragment ? `#${fragment}` : ''}`;
    } else {
      console.error('Invalid action link key');
      resultLink = '';
    }
  }
  return resultLink;
};

export const onClickActionFunction = linkPlacement => {
  const buildRelativePath = makePathRelative({ url: getBasePath() });

  return ({
    schema,
    key,
    path,
    parameters,
    fragment,
    link,
    eventLinkPlacement = linkPlacement,
  }) => {
    const resultLink = buildLink({
      schema,
      key,
      path,
      parameters,
      fragment,
      link,
      eventLinkPlacement,
    });

    if (typeof resultLink === 'undefined') {
      return;
    }

    if (isValidSchema(schema)) {
      registerUDLEvent({
        'data-udl-link-id': 'action-click',
        'data-udl-link-placement': eventLinkPlacement,
      });
      history.push(buildRelativePath(resultLink));
    } else {
      // TODO: investigate if we need to register an event for external link
      //       (will be addressed in another user story)
      windowOpen(resultLink, '_blank');
    }
  };
};
