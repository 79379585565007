const Environments = {
  common: {
    IDLE_TIMEOUT: 1000 * 60 * 10,
    RESPONSE_TYPE: 'code',
    STRATUS_CLIENT_ID: 'Jkdv8YL9VEqmgA9wpZus9SjuUck2hr3m',
    STRATUS_SCOPE: 'openid+offline_access+user.profile.write+profile+email',
    VIRTUAL_AGENT_BASE_URL: 'https://virtualagent-stg.hpcloud.hp.com',
    // MFEs
    HP_SMART_ADVANCE_MANIFEST_URL: `${process.env.PUBLIC_URL}/hp-smart-advance/asset-manifest.json`,
    CHECKOUT_MANIFEST_URL: 'https://static.hpsmart.com/jarvis/react-checkout/latest/asset-manifest.json',
    ORDERS_MANIFEST_URL:
      'https://static.hpsmart.com/monetization/order-management-react/latest/asset-manifest.json',
    FOREST_FIRST_MANIFEST_URL:
      'https://static.hpsmart.com/ucde-portal/forest-first/0.3.0/asset-manifest.json',
    HP_PLUS_SECOND_OFFER_MANIFEST_URL:
      'https://static.hpsmart.com/jarvis/react-consumer-hp-plus-second-offer/4.4.2/asset-manifest.json',
    // Relative URLs - All below keys should be referenced on the constant relativeUrlsKeys (see file index.js)
    AUTHZ_OPENID_REDIRECT_URL: '/api/2/authorize',
    LANGUAGE_FILES_URL: '/ucde/locales',
    MY_PRINTERS_URL: '/myprinters',
    MY_PRINTER_URL: '/myprinter',
    HPC_GATEWAY_URL: '/api',
    UCDE_GATEWAY_URL: '/ucde/api',
    SESSION_SERVICE_BASE_URL: '/api/2/authorize',
    CONTENT_STACK_URL: '/',
    CONTENT_STACK_KEY: 'bltcad87795ce18c6ce',
  },
  local: {
    // Relative URLs
    AUTHZ_OPENID_REDIRECT_URL: 'http://local-hpc3-be.hpconnectedpie.com:3008/api/2/authorize',
    HPC_GATEWAY_URL: 'http://api.local-hpc3-be.hpconnectedpie.com:9000/api',
    LANGUAGE_FILES_URL: ':9005/locales',
    MY_PRINTERS_URL: 'https://www.hpsmartdev.com/myprinters',
    MY_PRINTER_URL: 'https://www.hpsmartdev.com/myprinter',
    SESSION_SERVICE_BASE_URL: 'http://api.local-hpc3-be.hpconnectedpie.com:3008/api/2/authorize',
    UCDE_GATEWAY_URL: 'http://api.local-hpc3-be.hpconnectedpie.com:9002',
    // Absolute URLs
    AUTHZ_ONBOARDING_BASE_URL: 'https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.pie-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://pie.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    COMFE_MANIFEST_URL: 'https://assets-pie1.instantink.com/comfe/manifest.json',
    // Use the following if you're running Forest First locally
    // FOREST_FIRST_MANIFEST_URL: 'http://localhost:9012/asset-manifest.json',
    // Use the following if you're running HP+ Second Offer locally
    // HP_PLUS_SECOND_OFFER_MANIFEST_URL: 'http://localhost:9010/asset-manifest.json',
    // Use the following if you're running HP Smart Advance locally
    // HP_SMART_ADVANCE_MANIFEST_URL: 'http://localhost:9008/asset-manifest.json',
    IDLE_TIMEOUT: 1000 * 10,
    INSTANTINK_MANIFEST_URL: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76282c547cbb0adf1ac04f',
    ONE_TRUST_SPECIFIC_IDENTIFIER: 'b85c78b8-7005-445f-82f5-3f018f598132-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
    STACK: 'dev',
    STRATUS_BASE_URL: 'https://pie-us1.api.ws-hp.com',
    STRATUS_CLIENT_ID: 'eb8E8ZJHU3zu44377NZnraHpf8qHpqhg',
    CONTENT_STACK_URL: 'https://www.hpsmartdev.com/',
    CONTENT_STACK_TOKEN: 'cs8e7a3b88dc78dbe7eb302cbe',
  },
  local_v2: {
    // Relative URLs
    AUTHZ_OPENID_REDIRECT_URL: 'https://api.local-hpsmart.com:8443/api/2/authorize',
    HPC_GATEWAY_URL: ':8443/api',
    LANGUAGE_FILES_URL: 'https://www.local-hpsmart.com:8443/ucde/locales',
    MY_PRINTERS_URL: 'https://www.hpsmartdev.com/myprinters',
    MY_PRINTER_URL: 'https://www.hpsmartdev.com/myprinter',
    SESSION_SERVICE_BASE_URL: ':8443/api/2/authorize',
    UCDE_GATEWAY_URL: ':8443/ucde/api',
    // Absolute URLs
    AUTHZ_ONBOARDING_BASE_URL: 'https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.pie-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://pie.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    COMFE_MANIFEST_URL: 'https://assets-pie1.instantink.com/comfe/manifest.json',
    // Use the following if you're running Forest First locally
    // FOREST_FIRST_MANIFEST_URL: 'http://localhost:9012/asset-manifest.json',
    // Use the following if you're running HP+ Second Offer locally
    // HP_PLUS_SECOND_OFFER_MANIFEST_URL: 'http://localhost:9010/asset-manifest.json',
    // Use the following if you're running HP Smart Advance locally
    // HP_SMART_ADVANCE_MANIFEST_URL: 'http://localhost:9008/asset-manifest.json',
    IDLE_TIMEOUT: 1000 * 10,
    INSTANTINK_MANIFEST_URL: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76282c547cbb0adf1ac04f',
    ONE_TRUST_SPECIFIC_IDENTIFIER: 'b85c78b8-7005-445f-82f5-3f018f598132-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
    STACK: 'dev',
    STRATUS_BASE_URL: 'https://pie-us1.api.ws-hp.com',
    STRATUS_CLIENT_ID: 'eb8E8ZJHU3zu44377NZnraHpf8qHpqhg',
    CONTENT_STACK_URL: 'https://www.hpsmartdev.com/',
    CONTENT_STACK_TOKEN: 'cs8e7a3b88dc78dbe7eb302cbe',
  },
  local_jweb_android: {
    // Relative URLs
    AUTHZ_OPENID_REDIRECT_URL: ':3008/api/2/authorize',
    HPC_GATEWAY_URL: ':9000/api',
    LANGUAGE_FILES_URL: ':9005/locales',
    MY_PRINTERS_URL: 'https://www.hpsmartdev.com/myprinters',
    MY_PRINTER_URL: 'https://www.hpsmartdev.com/myprinter',
    SESSION_SERVICE_BASE_URL: ':3008/api/2/authorize',
    UCDE_GATEWAY_URL: ':9002',
    // Absolute URLs
    AUTHZ_ONBOARDING_BASE_URL: 'https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.pie-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://pie.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    COMFE_MANIFEST_URL: 'https://assets-pie1.instantink.com/comfe/manifest.json',
    INSTANTINK_MANIFEST_URL: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76282c547cbb0adf1ac04f',
    ONE_TRUST_SPECIFIC_IDENTIFIER: 'b85c78b8-7005-445f-82f5-3f018f598132-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
    STACK: 'dev',
    STRATUS_BASE_URL: 'http://10.0.2.2:9903',
    CONTENT_STACK_URL: 'https://www.hpsmartdev.com/',
    CONTENT_STACK_TOKEN: 'cs8e7a3b88dc78dbe7eb302cbe',
  },
  local_jweb_windows: {
    // Relative URLs
    AUTHZ_OPENID_REDIRECT_URL: ':3008/api/2/authorize',
    HPC_GATEWAY_URL: ':9000/api',
    LANGUAGE_FILES_URL: ':9005/locales',
    MY_PRINTERS_URL: 'https://www.hpsmartdev.com/myprinters',
    MY_PRINTER_URL: 'https://www.hpsmartdev.com/myprinter',
    SESSION_SERVICE_BASE_URL: ':3008/api/2/authorize',
    UCDE_GATEWAY_URL: ':9002',
    // Absolute URLs
    AUTHZ_ONBOARDING_BASE_URL: 'https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.pie-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://pie.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    COMFE_MANIFEST_URL: 'https://assets-pie1.instantink.com/comfe/manifest.json',
    INSTANTINK_MANIFEST_URL: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76282c547cbb0adf1ac04f',
    ONE_TRUST_SPECIFIC_IDENTIFIER: 'b85c78b8-7005-445f-82f5-3f018f598132-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
    STACK: 'dev',
    STRATUS_BASE_URL: 'http://localhost:9903',
    CONTENT_STACK_URL: 'https://www.hpsmartdev.com/',
    CONTENT_STACK_TOKEN: 'cs8e7a3b88dc78dbe7eb302cbe',
  },
  dev: {
    API_DOMAIN: 'api.dev.myaccount.hp.com',
    AUTHZ_ONBOARDING_BASE_URL: ' https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.pie-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://pie.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    COMFE_MANIFEST_URL: 'https://assets-pie1.instantink.com/comfe/manifest.json',
    INSTANTINK_MANIFEST_URL: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76282c547cbb0adf1ac04f',
    ONE_TRUST_SPECIFIC_IDENTIFIER: 'b85c78b8-7005-445f-82f5-3f018f598132-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
    STACK: 'dev',
    STRATUS_BASE_URL: 'https://pie-us1.api.ws-hp.com',
    CONTENT_STACK_TOKEN: 'cs8e7a3b88dc78dbe7eb302cbe',
    MY_PRINTERS_URL_FOR_MY_ACCOUNT: 'https://www.hpsmartdev.com/myprinters',
    MY_PRINTER_URL_FOR_MY_ACCOUNT: 'https://www.hpsmartdev.com/myprinter',
  },
  pie: {
    API_DOMAIN: 'api.pie.myaccount.hp.com',
    AUTHZ_ONBOARDING_BASE_URL: 'https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.pie-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://pie.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    COMFE_MANIFEST_URL: 'https://assets-pie1.instantink.com/comfe/manifest.json',
    INSTANTINK_MANIFEST_URL: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76283caa01970b37262c7d',
    ONE_TRUST_SPECIFIC_IDENTIFIER: '70d7ac1f-0a3d-4f86-8850-152b28c616e3-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
    STACK: 'pie',
    STRATUS_BASE_URL: 'https://pie-us1.api.ws-hp.com',
    CONTENT_STACK_TOKEN: 'cs8fd1a66d22569cf483d771f3',
    MY_PRINTERS_URL_FOR_MY_ACCOUNT: 'https://www.hpsmartpie.com/myprinters',
    MY_PRINTER_URL_FOR_MY_ACCOUNT: 'https://www.hpsmartpie.com/myprinter',
  },
  stg: {
    API_DOMAIN: 'api.stage.myaccount.hp.com',
    AUTHZ_ONBOARDING_BASE_URL: 'https://stage-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.stage-us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://stage.authz.wpp.api.hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
    CHECKOUT_MANIFEST_URL: 'https://static.hpsmart.com/jarvis/react-checkout/1.0.1/asset-manifest.json',
    COMFE_MANIFEST_URL: 'https://assets-stage1.instantink.com/comfe/manifest.json',
    INSTANTINK_MANIFEST_URL: 'https://assets-stage1.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76284cf46fca0be90fdabb',
    ONE_TRUST_SPECIFIC_IDENTIFIER: '417ace04-4f97-4434-832a-7c4fecd841ef-test',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://api-staging.ogre.hpalpine.com/potg/asset-manifest.json',
    STACK: 'stage',
    STRATUS_BASE_URL: 'https://stage-us1.api.ws-hp.com',
    CONTENT_STACK_TOKEN: 'csa99792b06c7a723246302136',
    MY_PRINTERS_URL_FOR_MY_ACCOUNT: 'https://www.hpsmartstage.com/myprinters',
    MY_PRINTER_URL_FOR_MY_ACCOUNT: 'https://www.hpsmartstage.com/myprinter',
  },
  prd: {
    API_DOMAIN: 'api.myaccount.hp.com',
    AUTHZ_ONBOARDING_BASE_URL: 'https://us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL: 'https://devices.us1.api.ws-hp.com/devices',
    AUTHZ_OPENID_URL: 'https://authz.api.ws-hp.com',
    CHANGE_PASSWORD_URL: 'https://myaccount.id.hp.com/uaa/change-password',
    CHECKOUT_MANIFEST_URL: 'https://static.hpsmart.com/jarvis/react-checkout/1.0.1/asset-manifest.json',
    // COMFE_MANIFEST_URL value is just a placeholder untill we will informed of its final value
    COMFE_MANIFEST_URL: 'https://assets-stage1.instantink.com/comfe/manifest.json',
    INSTANTINK_MANIFEST_URL: 'https://assets.instantink.com/vulcan/manifest.json',
    LAUNCHDARKLY_CLIENT_SIDE_ID: '5f76285824340c0bac31aa8d',
    ONE_TRUST_SPECIFIC_IDENTIFIER: '98068558-46e9-4f0a-8642-65f132a88c13',
    PRINT_ON_THE_GO_MANIFEST_URL: 'https://roam.api.hp.com/potg/asset-manifest.json',
    STACK: 'prod',
    STRATUS_BASE_URL: 'https://us1.api.ws-hp.com',
    VIRTUAL_AGENT_BASE_URL: 'https://virtualagent.hpcloud.hp.com',
    CONTENT_STACK_ENV: 'production',
    CONTENT_STACK_TOKEN: 'csa674c4b13d19219db6fa4e0b',
    MY_PRINTERS_URL_FOR_MY_ACCOUNT: 'https://www.hpsmart.com/myprinters',
    MY_PRINTER_URL_FOR_MY_ACCOUNT: 'https://www.hpsmart.com/myprinter',
  },
};

export default Environments;
