import axios from 'axios';

axios.defaults.xsrfHeaderName = '';

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const rawCookies = document.cookie;
    const cookies = rawCookies.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const nameVal = cookies[i].split('=');
      if (nameVal[0].trim() === name) {
        [, cookieValue] = nameVal;
      }
    }
  }
  return cookieValue;
}

export function getConsentCookie() {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const rawCookies = document.cookie;
    const cookies = rawCookies.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const nameVal = cookies[i].split('groups='); // =
      if (nameVal.length === 2) {
        const prep = unescape(nameVal[1].split('&')[0]).split(',');
        const toMultiArray = {};
        prep.forEach(element => {
          toMultiArray[element.slice(0, 1)] = element.slice(2);
        });
        cookieValue = toMultiArray;
      }
    }
  }

  // Whitelist the subdomains for internal testing
  const hostSubDomain = window.location.hostname.split('.')[0];
  const hostDomain = window.location.hostname.split('.')[1];
  if (hostSubDomain === 'local-hpc3'
    || hostSubDomain === 'hpc-pie1'
    || hostSubDomain === 'hpc3-dev'
    || hostSubDomain === 'hpc-stage1'
    || hostDomain === 'hpsmartdev'
    || hostDomain === 'hpconnectedpie'
    || hostDomain === 'hpsmartpie'
  ) {
    // console.log("> Read Optanon cookieValue", cookieValue)
    // console.log("> Optanon hostSubDomain", hostSubDomain)
    cookieValue = cookieValue === null ? [null, null, '1'] : cookieValue; // this causes the tracking to work all the time, when OptanonConsent cookie is not set by OneTrust
    // console.log("> Optanon hostSubDomain:", hostSubDomain, " > Set internal Optanon cookieValue:", cookieValue)
  } else {
    // if we are not in test envirnonment AND the cookieValue is null, insert off-state
    cookieValue = cookieValue === null ? [null, null, '0'] : cookieValue; // insert cookie in off-state
  }

  return cookieValue;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  let hostName = window.location.hostname;
  let domainName = '';
  if (hostName.split('.').length >= 3) {
    hostName = hostName.split('.');
    const { length } = hostName;
    domainName = `.${hostName[length - 2]}.${hostName[length - 1]}`;
  } else {
    domainName = `.${hostName}`;
  }
  // console.log("domainName: ", domainName)
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${domainName}`;
}

export function setOnboardingCookie(printerId, this_callback = () => { }) {
  // console.log(`setOnboardingCookie(printerId = "${printerId}", this_callback = "${this_callback}")`)
  let thisSession = getCookie('hpc3-csrf-token');
  // let thisSession = null
  if (thisSession !== null) {
    [thisSession] = thisSession.split('-'); // .join('')
  } else {
    thisSession = Math.trunc(Date.now() / 1000000);
    // console.log("thisSession = Date.now()",    thisSession )
  }
  // const thisSession = getCookie("hpc3-csrf-token").split('-')[0]
  // console.log("thisSession", thisSession)
  const currentPrinterId = printerId.split('-').join('');
  // console.log("currentPrinterId", currentPrinterId)

  const arraysToString = arrayOfArrays => {
    const tempArray = [];
    for (let i = 0; i < arrayOfArrays.length; i += 1) {
      tempArray.push(arrayOfArrays[i].join('.'));
    }
    return tempArray.join('-');
  };

  const stringToArrays = string => {
    const stringArray = string.split('-');
    const newArray = [];
    for (let i = 0; i < stringArray.length; i += 1) {
      newArray[i] = stringArray[i].split('.');
    }
    return newArray;
  };

  const getOnboardingCookie = () => {
    let val = getCookie('onboarded');
    val = !val ? `initialized.${thisSession}` : val;
    return stringToArrays(val);
  };

  /* eslint no-underscore-dangle: "off" */
  const _setOnboardingCookie = cookieValue => {
    setCookie('onboarded', cookieValue, 1024);
  };

  const onboardingCookie = getOnboardingCookie();

  const activateOnboarding = () => {
    _setOnboardingCookie(arraysToString(onboardingCookie));
  };

  let idFound = -1;
  for (let i = 0; i < onboardingCookie.length; i += 1) {
    if (onboardingCookie[i].indexOf(currentPrinterId) > -1) {
      idFound = i;
    }
  }

  // if session id found in onboardingCookie
  if (idFound !== -1) {
    // if length is exactly 2 then add a new session entry to index 2
    if (onboardingCookie[idFound].length === 2) {
      /* eslint camelcase: "off" */
      if (this_callback === undefined) {
        onboardingCookie[idFound].push(thisSession);
        activateOnboarding();
      } else if (onboardingCookie[idFound][1] !== thisSession) {
        onboardingCookie[idFound].push(thisSession);
        activateOnboarding();
        this_callback();
      } else {
        // do nothing
      }
    } else {
      // do nothing
    }
  } else {
    onboardingCookie.push([currentPrinterId, thisSession]);
    activateOnboarding();
    this_callback();
  }
}
