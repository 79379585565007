import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Loader from '../shared-components/Loader';
import { UCDEMainTheme } from '../../pages/styles';

const SignOut = ({ loc }) => {
  useEffect(() => {
    window.location.href = loc;
  }, []);

  return (
    <ThemeProvider theme={UCDEMainTheme}>
      <Loader fullScreen />
    </ThemeProvider>
  );
};

SignOut.propTypes = {
  loc: PropTypes.string.isRequired,
};

export default SignOut;
