const axiosOptionsCacheControl = (options = {}, noCache = false) => {
  const { headers = {} } = options;
  const newOptions = noCache ? {
    ...options,
    headers: {
      ...headers,
      'Cache-Control': 'no-store',
    },
  } : {};

  // the inline comment below is a conditional compilation flag for IE and should not be removed
  const isIE = /* @cc_on!@ */false || !!document.documentMode;
  if (isIE) {
    newOptions.params = {
      t: Date.now(),
    };
  }

  return newOptions;
};

export default axiosOptionsCacheControl;
