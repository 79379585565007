import { splitLocaleIntoCountryAndLanguage } from '../utils/globals';
import i18n from '../utils/i18n';
import {
  gatewayOnlyRouteEnabledForBaseUser,
} from '../utils/routing';

const handleLocaleInPath = async ({
  urlCountry,
  urlLanguage,
  ucdeUserLocale,
  jWebLocaleString,
  pathnameWithoutLocale,
  urlQueryString,
  redirect,
}) => {
  const validGatewayRoute = gatewayOnlyRouteEnabledForBaseUser(pathnameWithoutLocale);

  const {
    country,
    language,
  } = jWebLocaleString.value
    ? splitLocaleIntoCountryAndLanguage(jWebLocaleString.value)
    : {
      country: urlCountry,
      language: urlLanguage,
    };

  await i18n.setSupportedLocale(country, language, ucdeUserLocale, validGatewayRoute);

  // If URL locale does not match current locale, return the corrected path.
  if (i18n.getCountry().toLowerCase() !== urlCountry || i18n.getLanguage() !== urlLanguage || redirect) {
    return `/${i18n.getCountry().toLowerCase()}/${i18n.getLanguage()}${pathnameWithoutLocale}${urlQueryString}`;
  }

  return null;
};

export default handleLocaleInPath;
