import types from './types';
import { makeActionCreator } from '../../../utils/redux';

export const getOverallStatus = makeActionCreator(types.GET_OVERALL_STATUS, 'payload');

export const getOverallStatusSuccess = makeActionCreator(types.GET_OVERALL_STATUS_SUCCESS, 'payload');

export const getOverallStatusFailed = makeActionCreator(types.GET_OVERALL_STATUS_FAILED, 'payload');

export const getOverallStatusAutoRetry = {
  requestActionCreator: getOverallStatus,
  successActionCreator: getOverallStatusSuccess,
  failureActionCreator: getOverallStatusFailed,
};
