import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from '../shared-components/Loader';

const SignIn = ({ loc }) => {
  useEffect(() => {
    window.location.href = loc;
  }, []);

  return <Loader fullScreen />;
};

SignIn.propTypes = {
  loc: PropTypes.string.isRequired,
};

export default SignIn;
