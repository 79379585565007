import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  font-family: ${tokens.fontFamilyRegular};
  position: relative;
  color: ${props => (props.active ? tokens.colorHpBlue8 : tokens.colorGray7)};
  background:${props => (props.active ? 'rgba(2, 122, 174, 0.1)' : '')};

  svg {
    color: ${props => (props.active ? tokens.colorHpBlue8 : tokens.colorGray7)};
  }

  :hover {
    background: ${props => (props.active ? '' : 'rgba(33, 33, 33, 0.05)')};
  }
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 392px) {
    word-break: break-all;
  }
`;

export const SideContentWrapper = styled.div`
  width: 24px;
  height: 24px;

  img {
    vertical-align: middle;
  }
`;
