import './utils/GridAnnotator';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-promise/auto';
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';
import 'polyfill-queryselector';
import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import Environments from './config/environments';
import Config from './config';
import App from './App';
import { LD_ANONYMOUS_USER_KEY } from './utils/constants';
import ErrorBoundary from './components/ErrorBoundary';
import { getUniqueUserKey } from './utils/auth';
import splunkRUMInitialize from './setupSplunk';

global.EventSource = NativeEventSource || EventSourcePolyfill;

function isStreamingEnabledFor(stack) {
  return [
    Environments.dev.STACK,
  ].includes(stack);
}

(async () => {
  const uniqueUserKey = await getUniqueUserKey();
  const LDProvider = await asyncWithLDProvider({
    clientSideID: Config.LAUNCHDARKLY_CLIENT_SIDE_ID,
    options: {
      // setting [streaming] to true will make the sdk to keep trying to retrieve
      // the flags in case of failure otherwise a page refresh is needed to reload
      streaming: isStreamingEnabledFor(Config.STACK),
      // Note: The bootstrap values now are defined on the file .\utils\useFeatureFlags.js
      // to ensure that LDProvider will do the first call to LD site getting the initial values
      // but still having default bootstrap/fallback values when using the method useFeatureFlags().
    },
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
    context: {
      kind: 'user',
      key: uniqueUserKey || LD_ANONYMOUS_USER_KEY,
    },
  });

  splunkRUMInitialize();

  ReactDOM.render(
    <ErrorBoundary>
      <React.StrictMode>
        <LDProvider>
          <App />
        </LDProvider>
      </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
})();
