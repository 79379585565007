import React from 'react';
import PropTypes from 'prop-types';
import { Link as RoutingLink } from '../../../../routing';
import { registerUDLEvent } from '../../../../utils/registerUDLEvent';

export const Link = ({ children, onClick, ...props }) => (
  <RoutingLink
    onClick={() => {
      registerUDLEvent(props);
      onClick();
    }}
    {...props}
  >
    {children}
  </RoutingLink>
);

Link.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  onClick: () => {},
};

export default Link;
