export const getLanguageCode = async () => {
  const devicePlugin = window.JWeb?.Plugins?.Device;

  if (devicePlugin) {
    const deviceInfo = await devicePlugin.getInfo();
    if (deviceInfo?.locale?.languageTag) {
      return {
        data: {
          value: deviceInfo.locale.languageTag,
        },
      };
    }

    // getLanguageCode is deprecated but is kept here for backward compatibility
    const languageCode = await devicePlugin.getLanguageCode();
    return { data: languageCode };
  }

  return null;
};

export const jWebGetDeviceInfo = async () => {
  const devicePlugin = window.JWeb?.Plugins?.Device;

  if (!devicePlugin) {
    return {};
  }

  const deviceInfo = await devicePlugin.getInfo();
  return deviceInfo || {};
};

export const jWebAddListener = (eventListenerName, listenerFunc) => {
  const app = window.JWeb?.Plugins?.App;
  if (app) {
    try {
      return app.addListener(eventListenerName, listenerFunc);
    } catch (error) {
      console.error('jWebAddListener error', error);
    }
  }
  return null;
};
