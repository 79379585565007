import { createSelector } from 'reselect';
import { prop } from 'ramda';

const selectorsBase = {
  GET_JWEB: prop('jWeb'),
};

export const selectJWebLanguageCode = createSelector(
  selectorsBase.GET_JWEB,
  jWeb => (jWeb && jWeb.languageCode) || {},
);

export const selectJWebSignInStatus = createSelector(
  selectorsBase.GET_JWEB,
  jWeb => ({ jWebSignInLoading: jWeb.jWebSignInLoading, jWebSignInFailed: jWeb.jWebSignInFailed }),
);

export default selectJWebLanguageCode;
