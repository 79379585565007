import styled from 'styled-components';
import Card from '@veneer/core/dist/scripts/card';

export const MenuModal = styled(Card)`
  position: absolute;
  z-index: 99;
  top: calc(100% + 7px);
  right: 4px;
  background: #ffffff;
  padding: 16px;
  border-radius: 16px;
  min-width: 320px;
  overflow: hidden;

  @media (max-width: 392px) {
    min-width: 0;
    width: calc(100vw - 48px);
  }

  @media (max-width: 280px) {
    min-width: 0;
    width: calc(100vw - 38px);
  }

  li {
    list-style: none;
  }
`;

export const MenuContainer = styled.div`
  position: relative;
  cursor: pointer;
  align-selft: center;
`;
