import dashboardFeatures from './dashboardFeatures/reducer';
import events from './events/reducer';
import jWeb from './jweb/reducer';
import microfrontends from './microfrontends/reducer';
import ucdeUser from './ucdeUser/reducer';
import ucdeDevices from './devices/reducer';
import ucdePortalElements from './portalElements/reducer';
import devicesOverallStatus from './devicesOverallStatus/reducer';
import devicesTelemetry from './devicesTelemetry/reducer';
import users from './users/reducer';
import portalClose from './portalClose/reducer';
import hpAdvancedSubscription from './hpAdvancedSubscription/reducer';

export default {
  dashboardFeatures,
  events,
  jWeb,
  microfrontends,
  ucdeUser,
  ucdeDevices,
  ucdePortalElements,
  devicesOverallStatus,
  devicesTelemetry,
  users,
  portalClose,
  hpAdvancedSubscription,
};
