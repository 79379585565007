import styled from 'styled-components';
import Footer from '@veneer/core/dist/scripts/footer';

export const StyledFooter = styled(Footer).attrs(() => ({
  customStyle: {
    height: '48px',
    padding: '0 16px',
  },
}))`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 -3px 6px #0000000D;
`;

export const StyledVAButton = styled.button`
  font-family: ${props => props.theme.main.hp.fontFamily};
  font-size: 16px;
  color: ${props => props.theme.main.hp.color};
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-align: right;
`;
