import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { identity, is, prop } from 'ramda';
import PropTypes from 'prop-types';
import { usePathCorrection } from '../hooks/routeCorrections';
import { useSelector } from '../hooks';
import { selectPathname } from '../selectors/routing';

const toContainsCurrentLocation = (currentPath, target) => {
  if (is(String, target)) {
    return currentPath === target;
  }
  const toProp = prop('to', target);
  if (toProp) {
    return currentPath === toProp;
  }

  return false;
};

const Link = props => {
  const {
    to,
    className,
    onClick,
    children,
    smart,
    ...restProps
  } = props;
  const correctedTo = usePathCorrection(to);
  const currentPath = useSelector(selectPathname);
  const dispatch = useDispatch();

  const toIsAbsoluteURL = is(String, to) && (to.startsWith('https://') || to.startsWith('http://'));

  const handleClick = e => {
    if (!toIsAbsoluteURL) {
      if (e.nativeEvent) {
        e.nativeEvent.stopImmediatePropagation();
      }
      e.stopPropagation();
      e.preventDefault();
      dispatch(push(correctedTo));
    }
    onClick();
  };

  return (
    <a
      className={classNames(
        className,
        {
          active: smart && toContainsCurrentLocation(currentPath, to),
        },
      )}
      {...restProps}
      href={is(String, to) ? to : prop('pathname', to)}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape({
        takeover: PropTypes.string,
      }),
    }),
  ]).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  smart: PropTypes.bool,
};

Link.defaultProps = {
  className: null,
  children: null,
  onClick: identity,
  smart: false,
};

export default Link;
