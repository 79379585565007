import { Service } from 'axios-middleware';
import { pathOr } from 'ramda';
import createAxiosInstance from './api';
import deviceCacheDevicesMock from '../mock/get-device-cache-devices.json';
import userInfoMock from '../mock/user.json';
import Config from '../config';
import axiosOptionsCacheControl from '../utils/axiosOptionsCacheControl';
import portalElementsMock from '../mock/users-portal-elements.json';
import getUsersMock from '../mock/get-users.json';
import hpAdvSubscriptionInfo from '../mock/hp-adv-subscription-info.json';
import userIISubscriptionMock from '../mock/user-ii-subscription.json';
import getCountriesMock from '../mock/countries_get.json';
import getDeviceCompleteInfoMock from '../mock/get-device-complete-info.json';
import { parseUcdeGatewayApiError } from '../utils/apiErrorHandling';
import {
  getStratusAccessToken, getStratusBaseToken, isStratusAccessTokenV2Base, redirectToSignIn,
} from '../utils/auth';
import refreshToken from '../utils/auth/refreshToken';

const mock = false;
const HOME_TIMEOUT = 15000;

const ucdeGatewayApi = createAxiosInstance(Config.UCDE_GATEWAY_URL);

const service = new Service(ucdeGatewayApi);

const apiVersion = version => `/${version || Config.UCDEGatewayApiVersion}`;

const tokenOrglessEndpointsInterceptor = url => {
  const regexExpGetOrganizations = /.*\/users\/me\/organizations$/g;
  const regexExpTokenExchange = /.*\/auth\/token-exchange$/g;
  const isMatching = regexExpGetOrganizations.test(url) || regexExpTokenExchange.test(url);

  if (!isStratusAccessTokenV2Base() && isMatching) {
    return getStratusBaseToken();
  }

  return '';
};

function setAuthorizationHeaderInInterceptor(interceptorConfig) {
  const { headers, url } = interceptorConfig;
  const token = tokenOrglessEndpointsInterceptor(url) || getStratusAccessToken();
  headers.Authorization = `Bearer ${token}`;
}

// [SDASH-6161] The option withCredentials is necessary to store VISION_SESSION cookie
export const setAuthorizationHeader = accessToken => {
  ucdeGatewayApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  ucdeGatewayApi.defaults.withCredentials = true;
};

const getOptions = ({ noCache = false, timeout }) => {
  const options = {};

  if (timeout) {
    options.timeout = timeout;
  }

  return axiosOptionsCacheControl(options, noCache);
};

service.register({
  async onRequest(interceptorConfig) {
    await refreshToken();

    setAuthorizationHeader(getStratusAccessToken());
    setAuthorizationHeaderInInterceptor(interceptorConfig);

    return interceptorConfig;
  },
  onResponseError(error) {
    if (error) {
      const method = error?.response?.config?.method;
      const url = error?.response?.config?.url;
      const accountDeleteUrlRegex = /^.*?\/2\/accounts\/[^/]+$/;
      const isDeleteRequest = url && accountDeleteUrlRegex.test(url) && method === 'delete';

      const status = pathOr(0, ['response', 'status'], error);
      if ((status === 401 || status === 403) && !isDeleteRequest) {
        // Critical scopes missing, must force relogin
        redirectToSignIn({ forceLogin: true });
        return;
      }
      if (Config.STACK === 'dev') {
        console.error(parseUcdeGatewayApiError(error));
      }
      throw error;
    }
  },
});

export const getUserData = async () => {
  if (mock) {
    return { data: userInfoMock };
  }
  const options = getOptions({ noCache: true });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/users/me`, options);
  return response;
};

export const getUserIISubscription = async ({ country, language }) => {
  if (mock) {
    return userIISubscriptionMock;
  }

  const response = await ucdeGatewayApi.get(`${apiVersion()}/users/instant-ink/subscriptions?locale=${country}/${language}`);
  return response.data;
};

export const getUserPortalElementsData = async () => {
  if (mock) {
    return { data: portalElementsMock };
  }

  const options = getOptions({ noCache: true });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/users/me/portal-elements`, options);
  return response;
};

export const getHPAdvancedSubscription = async () => {
  if (mock) {
    return hpAdvSubscriptionInfo;
  }

  const options = getOptions({ noCache: false });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/accounts/entitlements/smart-advance`, options);
  return response;
};

export const inviteUser = async ({ email, id }) => {
  const response = await ucdeGatewayApi.post(`${apiVersion()}/users/accounts/${id}/userinvitations`, { email });

  return response.data;
};

export const deleteInvitation = async ({
  id,
  resourceId,
  callback,
}) => {
  const response = await ucdeGatewayApi.delete(`${apiVersion()}/users/accounts/${id}/userinvitations/${resourceId}`);
  if (callback) {
    callback();
  }
  return response;
};

export const getUserProfile = async callback => {
  if (mock) {
    return { data: userInfoMock };
  }

  let response;
  try {
    const options = getOptions({ noCache: true });
    response = await ucdeGatewayApi.get(`${apiVersion()}/users/profile`, options);
  } catch (error) {
    response = error.response;
  }

  if (callback) {
    callback();
  }

  return response;
};

export const getUsers = async noCache => {
  if (mock) {
    return getUsersMock;
  }

  const options = getOptions({ noCache });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/users`, options);
  return response.data;
};

export const saveUserData = async userData => {
  const response = await ucdeGatewayApi.put(`${apiVersion()}/users/profile/${userData.resourceId}`, userData);
  return response;
};

export const getAllUserDevices = async noCache => {
  const options = getOptions({ noCache });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices`, options);
  return response;
};

export const getDeviceCompleteInfo = async ({ cloudId }, noCache) => {
  if (mock) {
    return getDeviceCompleteInfoMock;
  }
  const options = getOptions({ noCache });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${cloudId}?info=complete`, options);
  return response;
};

export const getDeviceInfo = async cloudId => {
  try {
    const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${cloudId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getOrganizations = async filter => {
  try {
    const params = filter ? { params: { filter } } : {};
    const response = await ucdeGatewayApi.get(`${apiVersion()}/users/me/organizations`, params);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const tokenExchange = async tenantId => {
  const response = await ucdeGatewayApi.post(`${apiVersion()}/auth/token-exchange`, { tenantId });
  return response;
};

export const getProgramLevel = async () => {
  const options = getOptions({ noCache: true });
  const response = await ucdeGatewayApi.get(`${apiVersion()}/accounts/program-level`, options);
  return response;
};

export const createOrg = async ({ idToken, accountName, accountId }) => {
  const response = await ucdeGatewayApi.post(`${apiVersion()}/accounts`, { idToken, accountName, accountId });
  return response.data;
};

export const getAccount = async () => {
  try {
    const response = await ucdeGatewayApi.get(`${apiVersion()}/accounts`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDeviceSecondOfferEligibility = async cloudId => {
  try {
    const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${cloudId}/hp-plus/eligibility`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const removeDevice = async (accountId, cloudId, ignoreWarning) => {
  let ignoreWarningParam = '';
  if (ignoreWarning) {
    ignoreWarningParam = '?ignoreWarning=true';
  }

  try {
    const response = await ucdeGatewayApi.delete(`${apiVersion()}/accounts/${accountId}/devices/${cloudId}${ignoreWarningParam}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCountriesInfo = async () => {
  if (mock) {
    return getCountriesMock;
  }
  const response = await ucdeGatewayApi.get(`${apiVersion()}/users/profile/countries-info`);
  return response.data;
};

export const getAllDevicesStatus = async noCache => {
  const options = getOptions({ noCache, timeout: HOME_TIMEOUT });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/health`, options);
  return response.data;
};

export const getDeviceHealth = async (cloudId, noCache) => {
  const options = getOptions({ noCache });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${cloudId}/health/device`, options);
  return response;
};

export const getConnectivity = async cloudId => {
  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${cloudId}/health/connectivity`);
  return response;
};

export const getDeviceSuppliesInfo = async cloudId => {
  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${cloudId}/supplies`);
  return response;
};

export const patchDeviceOwnershipsTransfer = async (cloudId, fromAccountId, toAccountId) => {
  const data = { fromAccountId, toAccountId };

  try {
    const response = await ucdeGatewayApi.patch(`${apiVersion()}/devices/ownerships/${cloudId}/transfer`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDevicesOverallStatus = async noCache => {
  const options = getOptions({ noCache });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/overall-status`, options);
  return response;
};

export const getDashboardFeaturesInfo = async noCache => {
  const options = getOptions({ noCache });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/dashboard/features`, options);
  return response;
};

export const getDevicesTelemetry = async (accountId, noCache) => {
  const options = getOptions({ noCache, timeout: HOME_TIMEOUT });

  const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/${accountId}/telemetry`, options);
  return response.data;
};

export const saveMonitoringLog = async errorPayload => {
  const authHeader = {
    headers: { Authorization: `Bearer ${getStratusAccessToken()}` },
  };

  const response = await ucdeGatewayApi.post(`${apiVersion()}/monitoring/logs`, errorPayload, authHeader);

  return response.data;
};

export const getVirtualAgentBotConversation = async (userId, language, launchPoint, devOs) => {
  const data = {
    language, launchPoint, devOs,
  };
  const response = await ucdeGatewayApi.post(`/2/users/virtualagent/bot/conversation/${userId}`, data);
  return response.data;
};

export const preLoadUserData = async () => {
  const accessToken = getStratusAccessToken();
  if (accessToken) {
    const authHeader = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    await ucdeGatewayApi.post(`${apiVersion()}/async/preload-data`, {}, authHeader);
  }
};

export const encryptAnalytics = async payload => {
  try {
    const response = await ucdeGatewayApi.post(`${apiVersion()}/analytics/encrypt`, payload);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const deleteAccount = async userId => {
  try {
    const response = await ucdeGatewayApi.delete(`${apiVersion()}/accounts/${userId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTransferDeviceEligibility = async cloudId => {
  try {
    const response = await ucdeGatewayApi.get(`${apiVersion()}/devices/ownerships/${cloudId}/transfer/eligibility`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDeviceCacheDevices = async devicesType => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      return deviceCacheDevicesMock;
    }
  }

  try {
    const response = await ucdeGatewayApi.get(`${apiVersion('3')}/devices?type=${devicesType}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const patchFirmwareUpdateType = async (deviceUuid, updateType) => {
  const data = { updateType };

  try {
    const response = await ucdeGatewayApi.patch(`${apiVersion()}/devices/${deviceUuid}/firmwareupdate/configuration`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
