/*
  This function hides menu when visible in a small screen device
  Veneer doesn´t support this feature, so we have to:
  1. keep track of the current state (expanded/collased)
  2. so we can click toggle only when sidemenu is expanded
*/

const collapseButtonSelector = ' #portal-side-menu div button:nth-child(2)';
const sideMenuSelector = ' #portal-side-menu nav';
const toggleButtonSelector = '.vn-side-menu__toggle button';

const getFirst = selector => document.querySelector(selector);

const isVisible = selector => {
  const element = getFirst(selector);
  return !!element && window.getComputedStyle(element).display !== 'none';
};

const updatePortalSideMenuExpanded = () => {
  window.portalSideMenuExpanded = isVisible(collapseButtonSelector);
};

const initSideMenuControl = () => {
  if (!window.portalSideMenuInitialized) {
    window.portalSideMenuInitialized = true;
    updatePortalSideMenuExpanded();
    const menu = getFirst(sideMenuSelector);
    if (menu) {
      menu.addEventListener('webkitTransitionEnd', updatePortalSideMenuExpanded);
      menu.addEventListener('transitionend', updatePortalSideMenuExpanded);
    }
  }
};

export const collapseSideMenu = () => {
  initSideMenuControl();
  if (window.portalSideMenuExpanded || isVisible(collapseButtonSelector)) {
    const toggleButton = getFirst(toggleButtonSelector);
    if (toggleButton) {
      toggleButton.click();
      window.portalSideMenuExpanded = false;
    }
  }
};

export default collapseSideMenu;
