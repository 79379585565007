import Types from './types';
import { makeActionCreator } from '../../../utils/redux';
import { makeApiCall } from '../../../actions/application';
import { getLanguageCode } from '../../../utils/jweb';

const getJWebLanguageCodeSuccess = makeActionCreator(
  Types.LOAD_JWEB_LANGUAGE_CODE_SUCCESS,
  'payload',
);
const getJWebLanguageCodeFailed = makeActionCreator(
  Types.LOAD_JWEB_LANGUAGE_CODE_FAILED,
  'payload',
);
const getJWebLanguageCode = makeApiCall({
  asyncFn: getLanguageCode,
  successActionCreator: getJWebLanguageCodeSuccess,
  failureActionCreator: getJWebLanguageCodeFailed,
});

export const jwebSignInRequest = makeActionCreator(
  Types.LOAD_JWEB_SIGNIN_REQUEST,
  'path',
  'requireFreshToken',
  'skipTokenRefresh',
);
export const jwebSignInSuccess = makeActionCreator(Types.LOAD_JWEB_SIGNIN_SUCCESS, 'payload');
export const jwebSignInFailed = makeActionCreator(Types.LOAD_JWEB_SIGNIN_FAILED, 'payload');

const Actions = {
  getJWebLanguageCode,
  getJWebLanguageCodeSuccess,
  getJWebLanguageCodeFailed,
  jwebSignInRequest,
  jwebSignInSuccess,
  jwebSignInFailed,
};

export default Actions;
