import { path } from 'ramda';
import {
  call,
  put,
  takeLeading,
} from 'redux-saga/effects';
import { getDashboardFeaturesInfo } from '../../../api/UCDEGateway';
import {
  getDashboardFeatures,
  getDashboardFeaturesFailed,
  getDashboardFeaturesSuccess,
} from './actions';

function* handleDashboardFeaturesFetch(action) {
  try {
    yield put(getDashboardFeatures({ fetching: true }));

    const response = yield call(getDashboardFeaturesInfo, action.payload);

    const printAnywhereCount = path(['data', 'printAnywhere', 'count'], response);
    const smartSecurityCount = path(['data', 'smartSecurity', 'count'], response);
    const totalCount = path(['data', 'totalCount'], response);

    const printAnywhere = printAnywhereCount ? {
      count: printAnywhereCount,
    } : {};

    const smartSecurity = smartSecurityCount ? {
      count: smartSecurityCount,
    } : {};

    if (!totalCount) {
      throw Error();
    }

    yield put(getDashboardFeatures({
      fetching: false,
      error: false,
      totalCount,
      printAnywhere,
      smartSecurity,
    }));
    yield put(getDashboardFeaturesSuccess());
  } catch (error) {
    yield put(getDashboardFeatures({ fetching: false, error: true }));
    yield put(getDashboardFeaturesFailed());
  }
}

function* watchGetDashboardFeatures() {
  yield takeLeading(getDashboardFeatures.toString(), handleDashboardFeaturesFetch);
}

export default watchGetDashboardFeatures;
