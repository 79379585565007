import axios from 'axios';
import { getCookie } from './globals';
import Config from '../config';

const instance = axios.create();

const ucdeBase = Config.UCDE_GATEWAY_URL;

export async function ucde(requestObj) {
  const {
    url,
    method = 'GET',
    ...options
  } = requestObj;
  const response = await instance.request({
    url: `${ucdeBase}${url}`,
    method,
    ...options,
  });
  return response;
}

const base = Config.HPC_GATEWAY_URL;
const csrfToken = getCookie('hpc3-csrf-token');

export async function gateway(requestObj) {
  const {
    url,
    method = 'GET',
    version = '2',
    ...options
  } = requestObj;

  const response = await instance.request({
    url: `${base}/${version}${url}`,
    method,
    withCredentials: true,
    headers: {
      'x-hpc3-csrf-token': csrfToken,
    },
    xsrfCookieName: 'hpc3-csrf-token',
    xsrfHeaderName: 'x-hpc3-csrf-token',
    ...options,
  });
  return response;
}

export async function util(requestObj) {
  const {
    url,
    method = 'GET',
    ...options
  } = requestObj;

  const response = await instance.request({
    url: `${Config.HPC_GATEWAY_URL}${url}`,
    method,
    withCredentials: true,
    ...options,
  });
  return response;
}
