export const preFetchScript = href => {
  const link = document.createElement('link');
  link.rel = 'prefetch';
  link.href = href;
  link.as = 'script';

  document.head.appendChild(link);
};

export const preFetchStyle = href => {
  const link = document.createElement('link');
  link.rel = 'prefetch';
  link.href = href;
  link.as = 'style';

  document.head.appendChild(link);
};
