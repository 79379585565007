import React from 'react';
import {
  Content,
  Icon,
} from './styles';
import i18n from '../../utils/i18n';
import { renderModalWithActionButtons } from '../CustomModal';
import { history } from '../../App';

export const renderErrorModal = ({
  title = i18n.t('home.error.retrieveData.title'),
  errorMessage = i18n.t('home.error.retrieveData.message'),
  actionLabel = i18n.t('home.error.confirmLabel'),
  actionCallback = () => window.location.reload(),
  dismissLabel = undefined,
  dismissCallback = () => history.back(),
  onClose = undefined,
} = {}) => {
  const content = () => (
    <Content>
      <div>
        <Icon size={48} />
        <h4>{title}</h4>
        <p>{errorMessage}</p>
      </div>
    </Content>
  );

  renderModalWithActionButtons({
    id: 'ucde-error-modal',
    dataTestId: 'dashboard-error-modal',
    content,
    actionLabel,
    actionCallback,
    dismissLabel,
    dismissCallback,
    onClose,
  });
};

export default renderErrorModal;
