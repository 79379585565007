import axios from 'axios';
import Config from '../config';

const base = Config.HPC_GATEWAY_URL;

async function getSplunkConfigData() {
  const response = await axios.get(`${base}/1/nonAuth/rum-token`);
  return response.data;
}

export default getSplunkConfigData;
