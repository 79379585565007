export const verifyUDLAttributes = () => {
  if (window.dataLayer && typeof window.dataLayer === 'object') {
    return true;
  }
  return false;
};

export const registerUDLEvent = props => {
  const {
    'data-udl-link-id': linkID,
    'data-udl-link-placement': linkPlacement,
  } = props;

  if (linkPlacement && linkID) {
    if (verifyUDLAttributes()) {
      window.dataLayer.push({
        event: 'e_linkClick',
        linkPlacement,
        linkID,
      });
    }
  }
};
