import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${props => props.isLoading
    && `
    cursor: default;
  `}
`;

export default Container;
