import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFeatureFlags from '../../utils/useFeatureFlags';
import selectPageNameFromRoute from '../../store/modules/pageTitle/selector';
import {
  kebabCase,
  selectHelpLinks as selectHelpLinksCraft,
} from '../../store/modules/helpCenter/selector';
import { selectHelpLinks as selectHelpLinksCs } from '../../store/modules/helpCenter/contentStackSelector';

import selectUcdePortalElements, {
  selectPortalElementsProgramLevel,
} from '../../store/modules/portalElements/selectors';
import { getCookie } from '../../utils/globals';
import { USER_TYPE } from '../../constants/cookieNames';
import {
  Container,
  Title,
} from './styles';

const PageTitle = () => {
  const [helpLinks, setHelpLinks] = useState([]);
  const programLevel = useSelector(selectPortalElementsProgramLevel) || getCookie(USER_TYPE);
  const ucdePortalElements = useSelector(selectUcdePortalElements());
  const flags = useFeatureFlags();
  const { useContentStack } = flags;
  const selectHelpLinks = useContentStack ? selectHelpLinksCs : selectHelpLinksCraft;
  const pageNameStr = useSelector(selectPageNameFromRoute(helpLinks, flags));

  useEffect(() => {
    async function loadHelpLinks() {
      const helpLinksData = await selectHelpLinks({ programLevel, ucdePortalElements });
      setHelpLinks(helpLinksData);
    }
    loadHelpLinks();
  }, []);

  return (
    <Container>
      <Title data-testid={kebabCase(pageNameStr)}>
        {pageNameStr}
      </Title>
    </Container>
  );
};

export default PageTitle;
