export const ENVY6000 = 'envy6000';
export const ENVYPRO6400 = 'envypro6400';
export const DJ2700IA = 'dj2700ia';
export const DJ4100IA = 'dj4100ia';
export const DJ6400IA = 'dj6400ia';
export const DJ6000 = 'dj6000';
export const DJ6400 = 'dj6400';
export const DJ2700 = 'dj2700';
export const DJ4100 = 'dj4100';
export const TANGO = 'tango';
