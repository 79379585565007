import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: center;
  ${props => (props.theme.hostIsDesktopApp ? 'display: none;' : '')}
`;

export const Title = styled.div`
  visibility: hidden;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  line-height: 20px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-items: center;

  @media (max-width: 1200px) {
    color: #666;
    visibility: visible;
    font-size: max(min(4.35vw,16px), 12px);
  }
`;
