import React, { useEffect, useState } from 'react';
import { JarvisOnboardingComponent, UserRequestedSignOutError } from '@jarvis/react-user-onboarding';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  pathOr,
  pathSatisfies,
  prop,
} from 'ramda';
import { usePathCorrection, useSelector } from '../../hooks';
import { selectOnboardingProps } from '../../selectors/user';
import { getExchangeToken } from '../../api/HPCSession';
import { initializeUcdeUser } from '../../store/modules/ucdeUser/actions';
import {
  exchangeOrgToken,
  getSelectedOrgId,
  getStratusAccessToken,
  getStratusIdToken,
  isJWebApp,
  isStratusAccessTokenV2Base,
  jarvisAuthProvider,
  redirectToSignIn,
} from '../../utils/auth';
import { renderErrorModal } from '../../components/ErrorModal';
import { getCookie } from '../../lib/manageCookies';
import {
  HPC3_SESSION_TYPE,
  IS_AGENT_SESSION,
  IS_INVITE,
  SELECTED_ORG_ID,
  USER_TYPE,
} from '../../constants/cookieNames';
import { deleteCookie } from '../../utils/globals';
import { initializeUser } from '../../actions/userInfo';
import {
  gatewayRouteEnabledForBaseUser,
  removeCountryAndLanguage,
  shouldRedirectToMyPrintersPage,
} from '../../utils/routing';
import { saveMonitoringLogOnError } from '../../utils/apiErrorHandling';
import { PATHS } from '../../utils/constants';
import useFeatureFlags from '../../utils/useFeatureFlags';
import { getAccount, getUserData } from '../../api/UCDEGateway';
import Loader from '../../components/shared-components/Loader';
import i18n from '../../utils/i18n';
import windowOpen from '../../utils/windowOpener';

const Onboarding = () => {
  const onboardingProps = useSelector(selectOnboardingProps());

  const {
    country,
    language,
    baseUrl,
    redirectTo,
    encodedState,
    callType,
    callTypeValue,
  } = onboardingProps;

  const { newTenancyModel } = useFeatureFlags();

  const [initialUserLevel, setInitialUserLevel] = useState(null);
  const [isCheckingInvite, setIsCheckingInvite] = useState(false);
  const dispatch = useDispatch();
  const hpc3SessionType = getCookie(HPC3_SESSION_TYPE);
  const legacyPath = usePathCorrection(hpc3SessionType === 'user' ? '/myprinters' : '/');
  const isInvite = getCookie(IS_INVITE);
  const selectedOrgId = getSelectedOrgId();
  const stratusIdToken = getStratusIdToken();

  useEffect(() => {
    setInitialUserLevel((getCookie(USER_TYPE) || '').toLowerCase());
  }, []);

  if (!onboardingProps) {
    return null;
  }

  const basePath = `/${country}/${language}/ucde`;

  let redirectToVerified = redirectTo;
  if (redirectToVerified) {
    if (shouldRedirectToMyPrintersPage(redirectToVerified)) {
      redirectToVerified = `${basePath}${PATHS.MY_PRINTERS}`;
    }
  }

  const handleContactSupportClick = () => {
    const supportUrl = `https://support.hp.com/${country}-${language}/contact`;
    windowOpen(supportUrl, '_blank');
  };

  const path = redirectToVerified || basePath;

  if (newTenancyModel && isStratusAccessTokenV2Base() && !selectedOrgId) {
    const to = encodedState ? `${basePath}/org-selector?state=${encodedState}` : `${basePath}/org-selector`;
    return <Redirect from={`${basePath}/loggedin`} to={to} />;
  }

  if (getCookie(IS_AGENT_SESSION)) {
    dispatch(initializeUcdeUser(path));
    return <></>;
  }

  if (!getStratusAccessToken() || (!stratusIdToken && !isJWebApp())) {
    const to = encodedState ? `${basePath}/signin?state=${encodedState}` : `${basePath}/signin`;
    return <Redirect from={`${basePath}/loggedin`} to={to} />;
  }

  const authProvider = {
    ...jarvisAuthProvider,
    getIDToken: async () => {
      const idToken = getStratusIdToken();
      return idToken;
    },
    onTokenExchangeRequired: async props => {
      const accountId = pathOr('', ['accountId'], props);

      if (accountId) {
        await exchangeOrgToken(accountId);
      } else {
        await getExchangeToken(getStratusAccessToken());
      }
    },
  };

  const getAccountType = async () => {
    try {
      const response = await getAccount();
      return pathOr('', ['type'], response.data);
    } catch (e) {
      return '';
    }
  };

  const isUserRole = async () => {
    try {
      const response = await getUserData();
      return pathSatisfies(userTenant => userTenant === true, ['userTenant'], response.data);
    } catch (e) {
      return false;
    }
  };

  const handleOnboardingFinished = async arg => {
    setIsCheckingInvite(true);
    deleteCookie(IS_INVITE);
    deleteCookie(SELECTED_ORG_ID);

    if (isInvite === 'true') {
      const accountType = await getAccountType();
      const userRole = await isUserRole();

      if (accountType === 'BusinessTransactionalSMB' && userRole) {
        window.location.href = '/get-software';
        return;
      }
    }

    setIsCheckingInvite(false);

    if (!arg) {
      const userLevel = getCookie(USER_TYPE);

      if (!gatewayRouteEnabledForBaseUser(removeCountryAndLanguage(path))) {
        if (userLevel.toLowerCase() === 'base') {
          dispatch(initializeUser('legacy'));
          window.location.href = legacyPath;
          return;
        }
        if (initialUserLevel === 'base' && initialUserLevel !== userLevel.toLowerCase()) {
          window.location.href = basePath;
          return;
        }
      }

      dispatch(initializeUcdeUser(path));
      return;
    }

    if (arg instanceof UserRequestedSignOutError) {
      dispatch(push(`${basePath}/signout`));
      return;
    }

    const { response } = arg;

    if (!response) {
      renderErrorModal(arg);
      return;
    }

    const { status } = response;
    if (status === 401 || status === 403) {
      redirectToSignIn({ forceLogin: true });
    }
  };

  const handleOnboardingError = async error => {
    saveMonitoringLogOnError('JarvisOnboardingComponent', error);
    const code = prop('code', error);

    if (code === 401 || code === 403) {
      redirectToSignIn({ forceLogin: true });
    }
  };

  const uiMgtSvcConfig = {};

  if (callType) {
    uiMgtSvcConfig.callType = callType;
    uiMgtSvcConfig.callTypeValue = callTypeValue;
  }

  const getAccountMgtSvcConfig = () => {
    if (selectedOrgId === 'CreatePersonalAccount') {
      return {
        account: {
          accountName: i18n.t('myPrinters.orgSelector.personalAccount'),
          type: 'Personal',
        },
      };
    }

    return {
      account: {
        accountId: selectedOrgId,
      },
    };
  };

  if (isCheckingInvite) {
    return <Loader fullScreen />;
  }

  return (
    <JarvisOnboardingComponent
      country={country.toUpperCase()}
      language={language}
      baseURLProvider={baseUrl}
      authProvider={authProvider}
      uiMgtSvcConfig={uiMgtSvcConfig}
      onFinished={handleOnboardingFinished}
      onError={handleOnboardingError}
      accountMgtSvcConfig={getAccountMgtSvcConfig()}
      onContactSupportClick={handleContactSupportClick}
    />
  );
};

export default Onboarding;
