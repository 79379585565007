/* eslint-disable no-use-before-define */
import { createReducer } from '../utils/redux';
import * as analyticsActions from '../actions/analytics';

const initialState = {};

const handlers = {
  [analyticsActions.saveUDLParams]: addToState,
};

const analyticsReducer = createReducer(initialState, handlers);
export default analyticsReducer;

function addToState(state, { params }) {
  // Don't overwrite values that were initially provided via query string
  return {
    ...params,
    ...state,
  };
}
