import axios from 'axios';
import {
  curry,
  find,
  map,
  path,
  prop,
  propEq,
} from 'ramda';
import { matchPath } from 'react-router';
import getSectionsFromCraftMock from './mock-smart-help-sections.json';
import getMainContentFromCraftMock from './mock-smart-help.json';
import { SERVICES } from '../../../utils/constants';
import { serviceEligible, serviceEnabled } from '../../../utils/portalElements';

// Change mock to true if you want to test help center on local dev environment
const mock = false;
const getPathname = () => window.location.pathname;
const selectRootMatch = () => matchPath(getPathname(), { path: '/:country/:language/:resource?', exact: false });
const selectHelpCenterMatch = () => (
  matchPath(getPathname(), { path: '/:country/:language/:resource/help/:topic?/:subtopic?', exact: true })
);

const fixAllUrls = html => html.replace(/http:\/\//ig, 'https://');

const getCountryLanguage = () => {
  if (selectRootMatch()) {
    const { country, language } = selectRootMatch().params;
    return { country, language };
  }
  return { country: 'us', language: 'en' };
};

const getLocale = () => {
  const { country, language } = getCountryLanguage();
  return `${language}_${country.toUpperCase()}`;
};

const filterSectionsFromCraft = ({ sections, programLevel = undefined, ucdePortalElements }) => sections
  .filter(section => {
    const { slug = '' } = section;
    if (programLevel && typeof programLevel === 'string' && slug === 'hp-plus') {
      return programLevel.toLowerCase() === 'hpplus';
    }
    if (slug === 'hp-instant-ink' && !serviceEligible(ucdePortalElements, SERVICES.PRINT_PLANS)) {
      return false;
    }
    if (slug === 'hp-smart-advance' && !serviceEligible(ucdePortalElements, SERVICES.HP_SMART_ADVANCE)) {
      return false;
    }
    return true;
  });

export const getSectionsFromCraft = async ({ programLevel, ucdePortalElements }) => {
  const HelpSectionsJSONPath = `/api/smart-help-sections.json.${getLocale()}`;
  const fallbackPath = '/api/smart-help-sections.json.en_US';
  let sections = [];

  if (mock) {
    sections = getSectionsFromCraftMock.data;
  } else {
    try {
      const { data } = await axios.get(HelpSectionsJSONPath);
      sections = data.data;
    } catch (e) {
      const { data } = await axios.get(fallbackPath);
      sections = data.data;
    }
  }

  return filterSectionsFromCraft({ sections, programLevel, ucdePortalElements });
};

export const getMainContentFromCraft = async () => {
  const HelpJSONPath = `/api/smart-help.json.${getLocale()}`;

  if (mock) {
    return getMainContentFromCraftMock.data;
  }
  const { data } = await axios.get(HelpJSONPath);
  return data.data;
};

const findTopicContentFromSections = curry((sections, topic) => find(
  propEq('slug', topic),
  sections,
));

export const selectHelpCenterPageProps = async sections => {
  const match = selectHelpCenterMatch();

  if (!match || !match.params || !sections) {
    return {};
  }

  const {
    params,
  } = match;

  const {
    topic,
    subtopic,
    country,
    language,
  } = params;

  const firstSlug = sections && sections[0] ? sections[0].slug : 'about-hp-smart';

  const findTopicContent = findTopicContentFromSections(sections);
  const validTopics = map(prop('slug'), sections);
  const topicIsValid = topic && validTopics.includes(topic);

  const { title } = topicIsValid
    ? findTopicContent(topic)
    : {};

  return {
    firstSlug,
    topicIsValid,
    topic,
    subtopic,
    title,
    country,
    language,
    rightColumnLinks: [{
      title: 'My Account',
      links: [{
        to: '#',
        text: 'Can\'t access your account',
      }, {
        to: '#',
        text: 'Learn how to change your password and fix login issues.',
      }],
    }, {
      title: 'Billing',
      links: [{
        to: '#',
        text: 'Resolve issues when credit card failed?',
      }, {
        to: '#',
        text: 'Change the credit card on file for your account?',
      }],
    }, {
      title: 'Plans & Pricing',
      links: [{
        to: '#',
        text: 'Update your Instant Supplies plan',
      }, {
        to: '#',
        text: 'How to log into the account at hpinstantink.com',
      }],
    }],
  };
};

const getTitles = data => ({
  helpForAndroid: path([0, 'smartHelpTitles', 0, 'androidApp'], data),
  helpForDesktop: path([0, 'smartHelpTitles', 0, 'desktopApp'], data),
  helpForIos: path([0, 'smartHelpTitles', 0, 'iosApp'], data),
  helpForMobile: path([0, 'smartHelpTitles', 0, 'mobileApp'], data),
});

const contentKeys = [
  'helpForAllApps',
  'helpForAndroid',
  'helpForDesktop',
  'helpForIos',
  'helpForMobile',
];

const siftData = (titles, data, useNoHpPlusText) => contentKeys.reduce((accum, key) => {
  const noHpPlusKey = 'helpForAllAppsNoHpPlus';
  const finalKey = useNoHpPlusText ? noHpPlusKey : key;

  if (data[key]) {
    return key === 'helpForAllApps'
      ? [
        {
          id: finalKey,
          content: fixAllUrls(data[finalKey]),
        },
        ...accum,
      ]
      : [
        ...accum,
        {
          title: prop(finalKey, titles),
          id: finalKey,
          content: fixAllUrls(data[finalKey]),
        },
      ];
  }
  return accum;
}, []);

export const kebabCase = str => str
  .split(' ')
  .map(word => word.toLowerCase())
  .join('-');

const filterHelpSections = ({ sections, ucdePortalElements }) => sections.filter(section => {
  if (section.helpTopic === 'HP Smart Advance' && !serviceEnabled(ucdePortalElements, SERVICES.HP_SMART_ADVANCE)) {
    return false;
  }
  if (section.helpTopic === 'HP Instant Ink' && !serviceEligible(ucdePortalElements, SERVICES.PRINT_PLANS)) {
    return false;
  }
  if (section.helpTopic === 'HP Smart Security' && !serviceEnabled(ucdePortalElements, SERVICES.SMART_SECURITY)) {
    return false;
  }
  return section;
});

export const selectHelpCenterMainProps = async ({ ucdePortalElements }, main, sections) => {
  const match = selectHelpCenterMatch();

  if (!match || !match.params || !main || !sections) {
    return [];
  }

  const topic = path(['params', 'topic'], match);
  const titles = getTitles(main);
  const findTopicContent = findTopicContentFromSections(sections);
  const { appHelpSection = [] } = (findTopicContent(topic) || {});
  const filteredHelpSection = filterHelpSections({ sections: appHelpSection, ucdePortalElements });

  const programLevel = path(['info', 'programLevel'], ucdePortalElements);
  const useNoHpPlusText = (programLevel.toLowerCase() !== 'hpplus' && topic === 'hp-instant-ink');

  return filteredHelpSection.map(section => {
    if (section.helpLinkText) {
      return {
        title: {
          text: section.helpLinkText,
          to: fixAllUrls(section.helpLinkAddress),
        },
        dataAnalyticsTag: section.analyticsID,
        id: kebabCase(section.analyticsID),
      };
    }

    const {
      helpTopic: title,
      analyticsID: dataAnalyticsTag,
    } = section;
    return {
      title,
      dataAnalyticsTag,
      id: kebabCase(dataAnalyticsTag),
      content: siftData(titles, section, useNoHpPlusText),
    };
  });
};

export const selectHelpCenterMobileProps = async ({ ucdePortalElements }, main, sections) => {
  const match = selectHelpCenterMatch();

  if (!match || !match.params || !sections) {
    return [];
  }

  const {
    resource = 'ucde',
    topic,
    subtopic,
    country,
    language,
  } = match.params;

  if (subtopic && !main) {
    return [];
  }

  const findTopicContent = findTopicContentFromSections(sections);

  const { appHelpSection = [] } = (findTopicContent(topic) || {});
  const filteredHelpSection = filterHelpSections({ sections: appHelpSection, ucdePortalElements });

  const programLevel = path(['info', 'programLevel'], ucdePortalElements);
  const useNoHpPlusText = (programLevel.toLowerCase() !== 'hpplus' && topic === 'hp-instant-ink');

  if (subtopic) {
    const subSection = filteredHelpSection.find(({ analyticsID }) => subtopic === kebabCase(analyticsID));
    if (subSection) {
      const titles = getTitles(main);
      return siftData(titles, subSection, useNoHpPlusText).map(packet => ({
        ...packet,
        title: {
          text: packet.title,
        },
      }));
    }
  }
  return filteredHelpSection.map(section => {
    const {
      analyticsID,
      helpLinkText,
      helpLinkAddress,
      helpTopic,
    } = section;

    const kebabbedId = kebabCase(analyticsID);
    const sectionMeta = {
      id: kebabbedId,
      dataAnalyticsTag: analyticsID,
    };
    if (helpLinkText) {
      return {
        ...sectionMeta,
        title: {
          text: helpLinkText,
          to: fixAllUrls(helpLinkAddress),
        },
      };
    }
    const pathname = `/${country}/${language}/${resource}/help/${topic}/${kebabbedId}`;
    const titles = getTitles(main);
    const uniqueItem = filteredHelpSection.length === 1;
    const content = uniqueItem ? siftData(titles, section, useNoHpPlusText)[0]?.content : null;

    return {
      ...sectionMeta,
      title: {
        text: helpTopic,
        destination: {
          pathname,
          state: {
            takeover: helpTopic,
          },
        },
      },
      content: uniqueItem && content,
    };
  });
};

export const selectHelpLinks = async ({ programLevel, ucdePortalElements }) => {
  const match = selectRootMatch() || {};
  const sections = await getSectionsFromCraft({ programLevel, ucdePortalElements });

  if (!match || !match.params || !sections) {
    return [];
  }

  const {
    country = 'us',
    language = 'en',
    resource = 'ucde',
  } = match.params;

  const links = sections.map(({ slug, title }) => (
    {
      label: title,
      pathname: `/${country}/${language}/${resource}/help/${slug}`,
    }
  ));

  return links;
};
