import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import IconBriefCase from '@veneer/core/dist/scripts/icons/icon_briefcase';
import IconCheckmarck from '@veneer/core/dist/scripts/icons/icon_checkmark';
import IconPerson from '@veneer/core/dist/scripts/icons/icon_person';
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast';
import IconChevronRight from '@veneer/core/dist/scripts/icons/icon_chevron_right';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import { Container } from './styles';
import SelectableButton from '../SelectableButton';
import { cropText } from '../../../../../utils/globals';
import { exchangeOrgToken, removeLocalStorageScopesItem } from '../../../../../utils/auth';
import i18n from '../../../../../utils/i18n';

const ORG_PERSONAL = 'Personal';

const OrgList = ({ orgList }) => {
  const [isExchangingToken, setIsExchangingToken] = useState(false);
  const { addToast } = useToast();

  const getCropedResourceId = nodeId => cropText({
    data: nodeId,
    limit: 4,
    startWithEllipsis: true,
    showEllipsis: false,
  });

  const getCropedAccountName = accountName => cropText({
    data: accountName,
    limit: 20,
    startWithEllipsis: false,
    showEllipsis: true,
  });

  const rigthOrgSelectorIcon = org => {
    if (org.active) {
      return <IconCheckmarck />;
    }

    return <IconChevronRight />;
  };

  const exchangeToken = useCallback(async (org, event) => {
    event.stopPropagation();
    setIsExchangingToken(true);
    try {
      await exchangeOrgToken(org.nodeId);
      removeLocalStorageScopesItem();
      window.location.reload();
    } catch (err) {
      addToast({
        type: 'negative', text: 'Failed to exchange token', id: 'negative',
      });
      setIsExchangingToken(false);
    }
  });

  if (isExchangingToken) {
    return (
      <Container>
        <ProgressIndicator appearance="linear" />
      </Container>
    );
  }

  if (!orgList || orgList.length === 0) {
    return <React.Fragment />;
  }

  if (orgList.length === 1 && [...orgList][0].type === ORG_PERSONAL) {
    return <React.Fragment />;
  }

  return (
    <Container>
      {orgList.map(org => (
        <SelectableButton
          active={org.active}
          key={org.nodeId}
          tooltip={`${org.accountName} (${org.nodeId})`}
          leftIcon={org.type === ORG_PERSONAL
            ? <IconPerson filled={org.active} />
            : <IconBriefCase filled={org.active} />}
          rightIcon={rigthOrgSelectorIcon(org)}
          {...(!org.active
            && { onClick: event => exchangeToken(org, event) }
          )}
        >
          {org.type === ORG_PERSONAL
            ? getCropedAccountName(org.accountName)
            : i18n.t(
              'myPrinters.orgSelector.optionTemplate',
              {
                orgName: getCropedAccountName(org.accountName),
                orgID: getCropedResourceId(org.nodeId),
              },
            )}
        </SelectableButton>

      ))}
    </Container>
  );
};

OrgList.propTypes = {
  orgList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OrgList;
