import types from './types';
import { makeActionCreator } from '../../../utils/redux';
import { makeApiCall } from '../../../actions/application';
import { getAllUserDevices } from '../../../api/UCDEGateway';

export const getDetailedDevices = makeActionCreator(types.GET_DETAILED_DEVICES, 'payload');

export const getDetailedDevicesSuccess = makeActionCreator(types.GET_DETAILED_DEVICES_SUCCESS, 'payload');

export const getDetailedDevicesFailed = makeActionCreator(types.GET_DETAILED_DEVICES_FAILED, 'payload');

export const getDetailedDevicesAutoRetry = {
  requestActionCreator: getDetailedDevices,
  successActionCreator: getDetailedDevicesSuccess,
  failureActionCreator: getDetailedDevicesFailed,
};

export const getDevices = makeActionCreator(types.GET_DEVICES, 'payload');

export const getDevicesFailed = makeActionCreator(types.GET_DEVICES_FAILED, 'payload');

export const getDeviceSupplies = makeActionCreator(types.GET_DEVICE_SUPPLIES, 'payload');

export const getUCDEDevices = tenantId => makeApiCall({
  asyncFn: getAllUserDevices,
  args: {
    tenantId,
  },
  successActionCreator: getDevices,
  failureActionCreator: getDevicesFailed,
});
