import { createSelector } from 'reselect';
import { selectPathname, selectRootMatch } from '../../../selectors/routing';
import getMenuItemsObject from '../../../components/Portal/SideNav/menuItems';
import selectUcdePortalElements, {
  selectHasPlatinumServiceActiveSubscription,
  selectHasPrintPlansActiveSubscription,
} from '../portalElements/selectors';
import i18n from '../../../utils/i18n';

function createEntries(
  url,
  helpLinks,
  flags,
  portalElements,
  printPlansActiveSubscription = false,
  platinumServiceActiveSubscription = false,
) {
  const menuItems = getMenuItemsObject({
    portalElements,
    printPlansActiveSubscription,
    platinumServiceActiveSubscription,
    flags,
  });
  const itens = Object.values(menuItems);

  const menuItemsEntries = itens.flatMap(({
    pathname, title, labelId, subMenus = [],
  }) => {
    const subMenuItems = subMenus.map(subItem => ({
      pathname: subItem.pathname,
      titleStringId: subItem.title || subItem.labelId,
    }));

    return subMenus.length > 0
      ? subMenuItems
      : { pathname, titleStringId: title || labelId };
  });

  const helpLinksEntries = helpLinks.map(({ pathname, label }) => {
    const resource = pathname.replace(url, '');

    return {
      pathname: resource,
      titleString: label,
    };
  });

  return [...menuItemsEntries, ...helpLinksEntries];
}

const selectPageNameFromRoute = (helpLinks, flags = {}) => createSelector(
  selectRootMatch,
  selectPathname,
  selectUcdePortalElements,
  selectHasPrintPlansActiveSubscription,
  selectHasPlatinumServiceActiveSubscription,
  (rootMatch, pathname, portalElements, printPlansActiveSubscription, platinumServiceActiveSubscription) => {
    const { url } = rootMatch;
    const currentRoute = pathname.replace(url, '') || '/';
    const entries = createEntries(
      url,
      helpLinks,
      flags,
      portalElements,
      printPlansActiveSubscription,
      platinumServiceActiveSubscription,
    );
    const currentEntry = entries.find(entry => entry.pathname === currentRoute);

    return currentEntry ? i18n.t(currentEntry.titleStringId) || currentEntry.titleString : '';
  },
);

export default selectPageNameFromRoute;
