import Config from '../../config';
import Environments from '../../config/environments';
import { jarvisAuthProvider } from '../auth';
import SCOPES from '../../constants/localStorage';

const getStack = () => {
  let stack = `${Config.STACK}-`;

  if (Config.STACK === Environments.prd.STACK) {
    stack = '';
  }

  return stack;
};

const adapterAccessControl = () => ({
  refresh: async () => {
    const url = `https://${getStack()}us1.api.ws-hp.com/portalsvc/session/v1/scopes`;
    const token = await jarvisAuthProvider.getAccessToken();
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const payload = await response.json();
    const scopeList = payload?.scopes?.map(scopeName => ({
      scope: scopeName,
    })) || [];

    localStorage.setItem(SCOPES, JSON.stringify(scopeList));
  },
  checkScopes: async (
    scopesToCheck,
  // eslint-disable-next-line no-unused-vars
    options = {},
  ) => {
    const scopeList = JSON.parse(localStorage.getItem(SCOPES));
    return scopesToCheck?.every?.(requestedScope => scopeList?.some?.(
      cachedScope => requestedScope.scope === cachedScope.scope,
    ));
  },
});

export default adapterAccessControl;
