import {
  isEmpty,
  isNil,
} from 'ramda';
import { verifyUDLAttributes } from './registerUDLEvent';
// values to send to UDL
const HOME = 'home'; // works in this group and the one below
const CATEGORY = 'category';
const DETAIL = 'detail';

// values that correspond to route resources
const SLASH = '/';
const SIGNIN = 'signin';
const SIGNOUT = 'signout';
const LOGGEDIN = 'loggedin';
const PRINT_PLANS = 'print_plans';
const MY_PRINTERS = 'my-printers';
const MANAGE_USERS = 'manage-users';
const SERVICES = 'services';

const SMART_SECURITY = 'smart-security';

// Sets with 0^1 lookup for helping to determine what gets sent to UDL
const HOME_SET = new Set([HOME, SLASH]);
const CATEGORY_SET = new Set([SIGNIN, SIGNOUT, LOGGEDIN, PRINT_PLANS, MY_PRINTERS, MANAGE_USERS, SERVICES]);

const determineL5 = ({ secondary, tertiary }) => {
  if (!secondary || HOME_SET.has(secondary)) {
    return HOME;
  }

  if (CATEGORY_SET.has(secondary)) {
    if (secondary === PRINT_PLANS && tertiary) {
      return DETAIL;
    }
    return CATEGORY;
  }

  return DETAIL;
};

const determineL8 = ({
  primary, secondary, tertiary, quaternary, pageNameL5,
}) => {
  if (pageNameL5 === HOME) {
    return HOME;
  }
  if (tertiary === SMART_SECURITY) {
    return SMART_SECURITY;
  }
  return quaternary || tertiary || secondary || primary;
};

const determineL6 = ({ secondary, pageNameL5 }) => {
  if (pageNameL5 === HOME) {
    return HOME;
  }
  if (pageNameL5 === DETAIL) {
    return secondary;
  }

  return null;
};

const determineL7 = ({ tertiary, quaternary, pageNameL5 }) => {
  if (pageNameL5 === HOME) {
    return HOME;
  }

  if (pageNameL5 === DETAIL) {
    return quaternary
      ? tertiary
      : null;
  }

  return null;
};

export const constructEventInfo = params => {
  if (!params || isEmpty(params)) {
    return null;
  }

  const {
    primary,
  } = params;

  if (!['ucde', 'gateway'].includes(primary)) {
    return null;
  }

  const pageNameL5 = determineL5(params);
  const pageNameL6 = determineL6({ ...params, pageNameL5 });
  const pageNameL7 = determineL7({ ...params, pageNameL5 });
  const pageNameL8 = determineL8({ ...params, pageNameL5 });

  return {
    pageNameL5,
    pageNameL8,
    ...pageNameL6 && { pageNameL6 },
    ...pageNameL7 && { pageNameL7 },
  };
};

export const registerPageView = params => {
  const {
    pageNameL5,
    pageNameL6,
    pageNameL7,
    pageNameL8,
    deviceSKU,
    encryptedClientID,
    encryptedDeviceID,
    country,
  } = params;

  const dataLayerObject = {
    event: 'e_pageView',
    pageNameL5,
  };

  if (!isNil(pageNameL6)) {
    dataLayerObject.pageNameL6 = pageNameL6;
  }

  if (!isNil(pageNameL7)) {
    dataLayerObject.pageNameL7 = pageNameL7;
  }

  if (!isNil(pageNameL8)) {
    dataLayerObject.pageNameL8 = pageNameL8;
  }

  if (!isNil(deviceSKU)) {
    dataLayerObject.deviceSKU = deviceSKU;
  }

  if (!isNil(encryptedClientID)) {
    dataLayerObject.clientID = encryptedClientID;
  }

  if (!isNil(encryptedDeviceID)) {
    dataLayerObject.deviceID = encryptedDeviceID;
  }

  if (!isNil(country)) {
    dataLayerObject.v151 = country;
  }

  if (verifyUDLAttributes()) {
    window.dataLayer.push(dataLayerObject);
  }
};

export const registerClickEvent = ({
  linkID,
  linkPlacement,
  deviceSKU,
  encryptedClientID,
  encryptedDeviceID,
  country,
}) => {
  if (verifyUDLAttributes()) {
    window.dataLayer.push({
      event: 'e_linkClick',
      linkPlacement,
      linkID,
      ...deviceSKU && { deviceSKU },
      ...encryptedClientID && { clientID: encryptedClientID },
      ...encryptedDeviceID && { deviceID: encryptedDeviceID },
      ...country && { v151: country },
    });
  }
};

export const registerInternalEvent = ({
  action,
  label,
  deviceSKU,
  encryptedClientID,
  encryptedDeviceID,
  country,
}) => {
  if (verifyUDLAttributes()) {
    window.dataLayer.push({
      event: 'e_internal',
      action,
      label,
      ...deviceSKU && { deviceSKU },
      ...encryptedClientID && { clientID: encryptedClientID },
      ...encryptedDeviceID && { deviceID: encryptedDeviceID },
      ...country && { v151: country },
    });
  }
};

const registerPromotionEvent = ({
  event,
  linkID,
  linkPlacement,
  promotionId,
  promotionName,
  deviceSKU,
  encryptedClientID,
  encryptedDeviceID,
  country,
}) => {
  if (verifyUDLAttributes()) {
    window.dataLayer.push({
      event,
      linkID,
      linkPlacement,
      ecommerce: {
        promoView: {
          promotions: [{
            id: promotionId,
            name: promotionName,
          }],
        },
      },
      ...deviceSKU && { deviceSKU },
      ...encryptedClientID && { clientID: encryptedClientID },
      ...encryptedDeviceID && { deviceID: encryptedDeviceID },
      ...country && { v151: country },
    });
  }
};

export const registerPromotionImpressionEvent = ({
  linkID,
  linkPlacement,
  promotionId,
  promotionName,
  deviceSKU,
  encryptedClientID,
  encryptedDeviceID,
  country,
}) => {
  registerPromotionEvent({
    event: 'e_promotionImpression',
    linkID,
    linkPlacement,
    promotionId,
    promotionName,
    ...deviceSKU && { deviceSKU },
    ...encryptedClientID && { encryptedClientID },
    ...encryptedDeviceID && { encryptedDeviceID },
    ...country && { country },
  });
};

export const registerPromotionClickEvent = ({
  linkID,
  linkPlacement,
  promotionId,
  promotionName,
  deviceSKU,
  encryptedClientID,
  encryptedDeviceID,
  country,
}) => {
  registerPromotionEvent({
    event: 'e_promotionClick',
    linkID,
    linkPlacement,
    promotionId,
    promotionName,
    ...deviceSKU && { deviceSKU },
    ...encryptedClientID && { encryptedClientID },
    ...encryptedDeviceID && { encryptedDeviceID },
    ...country && { country },
  });
};

const formatLocaleToUDL = ({ language, country }) => {
  if (language && country) {
    return (`${language}-${country}`).toLowerCase();
  }
  return 'en-us';
};

export const configureUDLMetaTags = ({ language, country }) => {
  const locale = formatLocaleToUDL({ language, country });

  const html = document.getElementsByTagName('html')[0];
  html.setAttribute('lang', locale);

  const meta = document.querySelectorAll('meta[name=target_country]')[0];
  meta.setAttribute('content', country);
};
