import {
  compose,
  identity,
  join,
  map,
  path,
  pick,
  propOr,
  values,
} from 'ramda';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { constructEventInfo } from '../utils/analytics';
import * as PRINTERIDS from '../constants/printerIds';

const printerIdRegex = compose(
  join('|'),
  map(str => `${str}-info`),
  values,
)(PRINTERIDS);

const SELECTORS_BASE = {
  selectLocation: path(['router', 'location']),
  selectPathname: path(['router', 'location', 'pathname']),
  selectQuery: path(['router', 'location', 'query']),
  selectSearch: path(['router', 'location', 'search']),
};

export const selectRootMatch = createMatchSelector('/:country/:language/:resource?');

export const selectSmartWelcomeMatch = createMatchSelector(
  '/:country/:language/in-app/:platform(ios|android|mac|windows)/:message(welcome|printer-data)',
);

export const selectDataCollectionNoticeMatch = createMatchSelector(
  '/:country/:language/plain/:type(data-collection-notice|services-data-collection-notice|'
  + 'printer-data-collection-notice|windows-printer-data-collection-notice)/:version?',
);

export const selectPathname = createSelector(
  SELECTORS_BASE.selectPathname,
  identity,
);

export const selectLocation = createSelector(
  SELECTORS_BASE.selectLocation,
  identity,
);

export const selectQueryParameters = createSelector(
  SELECTORS_BASE.selectQuery,
  identity,
);

export const selectUDLQueryParams = createSelector(
  SELECTORS_BASE.selectQuery,
  pick(['appInstanceID', 'deviceID', 'userID', 'companyID', 'deviceSKU']),
);

export const selectQueryString = createSelector(
  SELECTORS_BASE.selectSearch,
  identity,
);

export const selectPathnameWithQuery = createSelector(
  selectPathname,
  selectQueryString,
  selectQueryParameters,
  (pathname, queryString, queryParams) => ({ pathname: `${pathname}${queryString}`, queryParams }),
);

export const selectLocaleParamsFromRoute = createSelector(
  selectRootMatch,
  match => ({
    language: path(['params', 'language'], match),
    country: path(['params', 'country'], match),
  }),
);

export const selectPrinterEssentialsMatch = createMatchSelector(`/:country/:language/:id(${printerIdRegex})`);

const getLocaleProps = compose(
  pick(['country', 'language']),
  propOr({}, 'params'),
);

export const selectOnboardingRouteProps = createSelector(
  SELECTORS_BASE.selectQuery,
  selectRootMatch,
  (query, match) => ({
    encodedState: propOr('', 'state', query),
    ...getLocaleProps(match),
  }),
);

const selectResources = createMatchSelector('/:country/:language/:primary?/:secondary?/:tertiary?/:quaternary?');

export const selectPageViewPathInfo = createSelector(
  selectPathname,
  selectResources,
  (pathname, match) => {
    const {
      params,
    } = match || {};
    return {
      pathname,
      eventInfo: constructEventInfo(params),
    };
  },
);

export const selectPathnameWithoutLocale = createSelector(
  selectPathname,
  selectQueryString,
  selectLocaleParamsFromRoute,
  (pathname, queryString, { country, language }) => ({
    pathnameWithoutLocale: pathname.replace(`/${country}/${language}`, ''),
    queryString,
  }),
);
