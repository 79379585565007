import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider';
import { push } from 'connected-react-router';
import { Provider as KeepAliveProvider } from 'react-keep-alive';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import Portal from '../components/Portal';
import { PATHS } from '../utils/constants';
import { Content, UCDEContainer, UCDEMainTheme } from '../pages/styles';
import SignIn from '../components/SignIn';
import ProtectRoute from '../components/ProtectRoute';
import injectOneTrust from '../utils/injectOneTrust';
import { configureUDLMetaTags } from '../utils/analytics';
import {
  createRelativePath,
  gatewayRouteEnabledForBaseUser,
  isHpxMyAccountRoute,
  signInOrOnboardingRoute,
  splitRouteIntoCountryAndLanguage,
} from '../utils/routing';
import {
  selectLocaleParamsFromRoute,
  selectPathnameWithoutLocale,
} from '../selectors/routing';
import {
  selectUcdeUserApiError,
  selectUcdeUserLoading,
  selectUcdeUserLocale,
} from '../store/modules/ucdeUser/selectors';
import { selectJWebLanguageCode } from '../store/modules/jweb/selectors';
import selectUcdePortalElements from '../store/modules/portalElements/selectors';
import Loader from '../components/shared-components/Loader';
import LoaderContent from '../components/shared-components/LoaderContent';
import Onboarding from '../pages/Onboarding';
import i18n from '../utils/i18n';
import { getCookie } from '../utils/globals';
import {
  isJWebApp,
  isJWebDesktopApp,
  isJWebNative,
  isStratusAccessTokenV1,
  isStratusAccessTokenV2Base,
} from '../utils/auth';
import {
  jWebGetDeviceInfo,
} from '../utils/jweb';
import { renderErrorModal } from '../components/ErrorModal';
import ScrollToTop from '../components/shared-components/ScrollToTop';
import { useHandlePageViewTracking } from '../hooks';
import { HPC3_SESSION_TYPE, USER_TYPE } from '../constants/cookieNames';
import { initializePortalInterface } from '../interface';
import Config from '../config';
import { preLoadMicroFrontends } from '../components/MicroFrontend/loadManifest';
import { preLoadUserData } from '../api/UCDEGateway';
import {
  Container,
  GridSeparator,
} from '../components/shared-components/styles';
import handleLocaleInPath from './handleLocaleInPath';
import {
  canShowFixedFooter, selectSignInProps,
} from '../store/modules/portal/selectors';
import Footer from '../components/Portal/Footer';
import OrgSelector from '../components/OrgSelector';
import useFeatureFlags from '../utils/useFeatureFlags';

const UCDEBaseRoutes = lazy(() => import(/* webpackPrefetch: true */'./UCDEBaseRoutes'));

const UCDEMainRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const { pathnameWithoutLocale, queryString } = useSelector(selectPathnameWithoutLocale);
  const ucdeUserApiError = useSelector(selectUcdeUserApiError);
  const isUcdeUserLoading = useSelector(selectUcdeUserLoading);
  const ucdeUserLocale = useSelector(selectUcdeUserLocale);
  const ucdePortalElements = useSelector(selectUcdePortalElements());
  const { newTenancyModel, supportCoptorToken } = useFeatureFlags();
  const jWebLocaleString = useSelector(selectJWebLanguageCode);
  const { language, country } = useSelector(selectLocaleParamsFromRoute);
  const showFixedFooter = useSelector(canShowFixedFooter);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({});

  const { redirectTo } = useSelector(selectSignInProps);

  // We understand that my-account route from JWeb will always use Coptor token,
  // which is why we are not checking if token is Coptor or not here. We will need
  // to revisit this logic in case we have to support this route for another JWeb
  // app (e.g. HP Smart App).
  if (supportCoptorToken && isJWebApp() && isHpxMyAccountRoute(pathnameWithoutLocale)) {
    Config.isHpxApp = true;
  }

  Config.UCDEGatewayApiVersion = '2';

  const { params: { root: rootRoute } } = match;
  const gateway = rootRoute === 'gateway';
  const baseUser = /base/i.test(getCookie(USER_TYPE));

  useEffect(() => {
    async function getDeviceInfo() {
      if (isJWebApp()) {
        setDeviceInfo(await jWebGetDeviceInfo());
      }
    }
    getDeviceInfo();

    async function correctLocalePath() {
      const localeParams = {
        urlCountry: redirectTo ? splitRouteIntoCountryAndLanguage(redirectTo)[0] : country,
        urlLanguage: redirectTo ? splitRouteIntoCountryAndLanguage(redirectTo)[1] : language,
        jWebLocaleString,
        ucdeUserLocale,
        pathnameWithoutLocale,
        urlQueryString: queryString,
        redirect: redirectTo,
      };
      const localePath = await handleLocaleInPath(localeParams);

      if (localePath) {
        dispatch(push(localePath));
      }
      setMessagesLoaded(true);
      return null;
    }
    correctLocalePath();
  }, []);

  useEffect(() => {
    initializePortalInterface();
    preLoadMicroFrontends(baseUser, gateway);
    if (!baseUser && !Config.isHpxApp) {
      preLoadUserData();
    }
  }, []);

  useEffect(() => {
    if (ucdeUserApiError || ucdePortalElements.apiError) {
      renderErrorModal({
        title: i18n.t('home.error.retrieveData.title'),
        errorMessage: i18n.t('home.error.retrieveData.message'),
        actionLabel: i18n.t('home.error.confirmLabel'),
        actionCallback: () => window.location.reload(),
        dismissLabel: i18n.t('home.error.cancelLabel'),
        dismissCallback: () => dispatch(push(`/${country}/${language}/ucde${PATHS.SIGNOUT}`)),
      });
    }
  }, [ucdeUserApiError, ucdePortalElements.apiError]);

  const userTypeSession = ucdePortalElements.info ? ucdePortalElements.info.programLevel : null;
  const customAnalytics = {
    userTypeSession,
    appBuild: deviceInfo.appBuild ? deviceInfo.appBuild : null,
    jWebVersion: deviceInfo.jWebViewVersion ? deviceInfo.jWebViewVersion : null,
  };

  useHandlePageViewTracking(customAnalytics);

  if (baseUser && (isStratusAccessTokenV1() || !isStratusAccessTokenV2Base())) {
    const validGatewayRoute = gatewayRouteEnabledForBaseUser(pathnameWithoutLocale);

    if (!validGatewayRoute && isJWebNative()) {
      const userLevel = getCookie(HPC3_SESSION_TYPE);
      window.location.href = userLevel === 'user' ? '/myprinters' : '/';
      return <Loader fullScreen />;
    }

    if (!gateway && !signInOrOnboardingRoute(pathnameWithoutLocale)) {
      const toGateway = pathnameWithoutLocale.replace('ucde', 'gateway');
      return (
        <Redirect
          from={match.url}
          to={`/${country}/${language}${toGateway}${queryString}`}
        />
      );
    }
  }

  UCDEMainTheme.gateway = gateway;
  UCDEMainTheme.hostIsDesktopApp = isJWebDesktopApp();

  if (isUcdeUserLoading || ucdePortalElements.loading) {
    return <Loader fullScreen />;
  }

  const shouldInjectOneTrust = () => !signInOrOnboardingRoute(match.url)
    && !isUcdeUserLoading && !ucdePortalElements.loading
    && !(typeof (isUcdeUserLoading) === 'undefined')
    && !(typeof (ucdePortalElements.loading) === 'undefined');

  if (shouldInjectOneTrust()) {
    injectOneTrust(Config.ONE_TRUST_SPECIFIC_IDENTIFIER);
  }

  configureUDLMetaTags({ language, country });

  const hidePortal = gateway || baseUser;

  return (
    !messagesLoaded
      ? <Loader fullScreen />
      : (
        <ThemeProvider theme={UCDEMainTheme}>
          <KeepAliveProvider>
            <UCDEContainer>
              <ScrollToTop />
              <IdleTimer
                ref={ref => window.Portal.activity.register(ref)}
                timeout={Config.IDLE_TIMEOUT}
                onActive={event => window.Portal.events.triggerEvent('onActive', event)}
                onIdle={event => window.Portal.events.triggerEvent('onIdle', event)}
              />
              <ToastProvider
                position="top"
              >
                <Switch>
                  <Route path={createRelativePath(match, PATHS.SIGNIN)}>
                    <SignIn url={match.url} queryString={queryString} />
                  </Route>
                  <Route path={createRelativePath(match, PATHS.ONBOARDING)}>
                    <Onboarding />
                  </Route>
                  {newTenancyModel && (
                    <Route path={createRelativePath(match, PATHS.ORG_SELECTOR)}>
                      <OrgSelector />
                    </Route>
                  )}
                  <Route path={createRelativePath(match, '/')}>
                    <ProtectRoute
                      path={createRelativePath(match, '/')}
                      component={hidePortal ? <></> : <Portal match={match} />}
                    />
                    <Content showFixedFooter={showFixedFooter}>
                      <Suspense fallback={(
                        <Container>
                          <GridSeparator>
                            <LoaderContent fullScreen />
                          </GridSeparator>
                        </Container>
                        )}
                      >
                        <ProtectRoute
                          path={createRelativePath(match, '/')}
                          component={<UCDEBaseRoutes match={match} />}
                        />
                      </Suspense>
                      <ProtectRoute
                        path={createRelativePath(match, '/')}
                        component={hidePortal ? <></> : <Footer />}
                      />
                    </Content>
                  </Route>
                </Switch>
              </ToastProvider>
            </UCDEContainer>
          </KeepAliveProvider>
        </ThemeProvider>
      )
  );
};

UCDEMainRoutes.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UCDEMainRoutes;
