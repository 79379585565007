import {
  registerClickEvent,
  registerInternalEvent,
  registerPageView,
} from '../utils/analytics';
import { onClickActionFunction } from '../utils/deeplinks';
import portalActivity from '../utils/portalActivity';
import portalEvents from '../utils/portalEvents';
import auth from './auth';

export const initializePortalInterface = () => {
  window.Portal = {
    activity: portalActivity,
    auth,
    analytics: {
      registerPageView,
      registerClickEvent,
      registerInternalEvent,
    },
    deeplinks: {
      navigateFunction: onClickActionFunction(),
    },
    events: portalEvents,
  };
};

export default initializePortalInterface;
