import styled from 'styled-components';
import { UCDEMainTheme } from '../../pages/styles';
import { stylesIf } from '../../utils/styleHelpers';

export const BoxContent = styled.div`
  padding: 32px;
  overflow: auto;
`;

export const Box = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  ${stylesIf('centered', 'align-items: center;')}
  ${stylesIf('centered', 'justify-content: center;')}

  ${BoxContent} {
    ${stylesIf('centered', 'text-align: center;')}
    width: ${stylesIf('centered', '480px', '100%')};

    @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
      width: 100%;
      ${stylesIf('centered', 'margin: 40px;')}
      ${stylesIf('centered', 'padding: 0;')}
    }
  }
`;
