export function rules4InternetExplorer(content) {
  // Mixin for IE 10 / IE11 by MSDN
  return `
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      ${content};
    }
  `;
}

export function rules4OldIEs(content) {
  // Mixin for IE 10 / IE11 / Edge Legacy by MSDN
  return `
    // Internet Explorer support
    ${rules4InternetExplorer(content)};

    // Edge Legacy support
    @supports (-ms-ime-align:auto) {
      ${content};
    }
  `;
}

export function rules4Safari10Plus(content) {
  // Mixin Safari 10+: https://browserstrangeness.bitbucket.io/css_hacks.html#safari
  return `
    @media not all and (min-resolution:.001dpcm) {
      ${content};
    }
  `;
}

export function rules4Browsers(mixins, content) {
  return mixins.reduce((acc, cur) => (
    `
      ${acc};
      ${cur(content)};
    `
  ), '');
}
