import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { isMobile } from 'react-device-detect';
import {
  rules4Browsers,
  rules4InternetExplorer,
  rules4OldIEs,
  rules4Safari10Plus,
} from './mixins';
import { stylesIf } from '../../utils/styleHelpers';

/*
 * Base components for pages
 */
export const Title = styled.h4`
  flex-basis: 100%;
  font-size: 40px;
  font-family: ${props => props.theme.main.hp.fontFamilyLight};
  margin-bottom: 10px;
  margin-top: -9px; // crop line height
  padding-top: 32px;
`;

export const Description = styled.div`
  font-family: ${props => props.theme.main.hp.fontFamilyLight};
  font-size: 16px;
  margin-bottom: ${stylesIf('noMargin', '0', '32px')};
  max-width: 660px;
`;

/*
 * Grid Layout Components: used in dashboard / pages
 */
export const Grid = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 640px 300px;
  -ms-grid-columns: 640px 300px;

  grid-gap: ${isMobile ? '0 32px' : '32px 32px'};
  margin: ${stylesIf('noMargin', '0', '32px 0')};

  // Simulates grid-gap for old IEs.
  ${rules4OldIEs(`
    > * {
      &:not(:last-child)  {
        :not(:empty) {
          margin-bottom: 32px;
        }
      }
    }
  `)}

  // Fix side content width for Internet Explorer only by adding margin width to column width
  ${rules4InternetExplorer(`
    -ms-grid-columns: 640px 332px;
  `)}

  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    ${props => (props.theme.hostIsDesktopApp ? '' : 'grid-template-columns: 1fr;')};
  }

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    -ms-grid-row: 1;
  }
`;

export const GridSeparator = styled.div`
  max-width: 975px;
  width: 100%;
  ${stylesIf('noMargin', '', 'margin-bottom: 32px;')}

  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    width: inherit;
    flex: 1;
  }
`;

export const LeftGridSeparator = styled(GridSeparator)`
  ${rules4OldIEs(`
    margin-left: 10px;
    margin-right: 10px;
  `)}
`;

export const RightGridSeparator = styled(GridSeparator)`
  ${rules4OldIEs(`
    margin-left: 10px;
    margin-right: 10px;
  `)}
`;

export const PrinterInfoCardsSeparator = styled(GridSeparator)`
  ${rules4Browsers(
    [rules4OldIEs, rules4Safari10Plus], `
      margin-bottom: 0;
    `,
  )};
`;

const getLeftMargin = ({ noMargins, theme: { gateway } }, base = 260) => {
  if (gateway) {
    return noMargins ? '0' : '32px';
  }
  return noMargins ? `${base}px` : `${base + 32}px`;
};
const getRightMargin = ({ noMargins }) => (noMargins ? '0' : '32px');

export const Container = styled.div`
  margin: ${props => `0 ${getRightMargin(props)} 0 ${getLeftMargin(props)}`};
  position: relative;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    ${props => (props.theme.hostIsDesktopApp ? '' : `margin: 0 ${getRightMargin(props)};`)};
  }

  @media (min-width: ${tokens.xlMin}) and (max-width: ${props => props.theme.breakPoints.xg}) {
    margin-left: ${props => getLeftMargin(props)};
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    ${props => (props.theme.hostIsDesktopApp || props.noMargins ? '' : 'margin: 0 16px;')};
    padding-bottom: 32px; // Prevent overlapping the footer
  }

  @media (max-width: ${tokens.lgMax}) {
    ${props => props.theme.hostIsDesktopApp && `
      padding-bottom: 0;
      margin: 0 ${getRightMargin(props)} 0 ${getLeftMargin(props, 0)};
    `};
  }

  @media (min-width: ${tokens.xlMin}) {
    ${props => props.theme.hostIsDesktopApp && `
      padding-bottom: 0;
      margin: 0 ${getRightMargin(props)} 0 ${getLeftMargin(props)};
    `};
  }
`;

export const PartnerContainer = styled(Container)`
  padding-top: 3px;
`;

export const SideContent = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;

  ${rules4OldIEs(`
    margin-left: 32px;
  `)}

  > * {
    &:not(:last-child)  {
      margin-bottom: 32px;
    }
  }
`;

export const MainContent = styled.div`
  > * {
    &:not(:last-child)  {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`;

export const NotificationContent = styled.div`
  > * {
    &:not(:empty) {
      padding: 0 0 32px 0;
      > div {
        box-shadow: 0 4px 16px #2121211A;
      }
    }
  }
`;

/*
 * Cards Components: used in dashboard page
 */
export const CardContainer = styled.div`
  padding: 32px;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    padding: 20px;
  }
`;

export const CardHeader = styled.div`
  display: ${props => props.display || 'flex'};
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  font-family: ${props => props.theme.main.hp.fontFamily};

  h5 {
    font-family: ${props => props.theme.main.hp.fontFamily};
    font-size: 24px;
  }

  a {
    color: ${props => props.theme.main.hp.color};
    font-size: 14px;
    line-height: 20px;
    text-align: right;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CardHeaderStatus = styled(CardHeader)`
  border-bottom: 1px solid #EBEBEB;
`;

export const CardHeaderBenefits = styled(CardHeader)`
`;

export const CardHeaderOtherFeatures = styled(CardHeader)`
  h5 {
    &:last-child {
      margin-top: 32px;
    }
  }
`;
