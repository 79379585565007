import React, { useState } from 'react';
import Button from '@veneer/core/dist/scripts/button';
import Modal from '@veneer/core/dist/scripts/modal';
import PropTypes from 'prop-types';
import {
  render,
  unmountComponentAtNode,
} from 'react-dom';

const ActionButtons = ({
  actionLabel,
  actionCallback,
  dismissLabel,
  dismissCallback,
  removeModal,
}) => {
  const handleAction = () => {
    if (actionCallback) {
      actionCallback();
    }
    if (removeModal) {
      removeModal();
    }
  };

  const handleDismiss = () => {
    if (dismissCallback) {
      dismissCallback();
    }
    if (removeModal) {
      removeModal();
    }
  };

  if (actionLabel) {
    return (
      <div className="vn-button-group--responsive">
        <Button
          aria-label={actionLabel}
          onClick={handleAction}
        >
          {actionLabel}
        </Button>
        {dismissLabel && (
          <Button
            appearance="ghost"
            aria-label={dismissLabel}
            onClick={handleDismiss}
          >
            {dismissLabel}
          </Button>
        )}
      </div>
    );
  }

  return null;
};

ActionButtons.propTypes = {
  actionLabel: PropTypes.string,
  actionCallback: PropTypes.func,
  dismissLabel: PropTypes.string,
  dismissCallback: PropTypes.func,
  removeModal: PropTypes.func,
};

ActionButtons.defaultProps = {
  actionLabel: undefined,
  actionCallback: undefined,
  dismissLabel: undefined,
  dismissCallback: undefined,
  removeModal: undefined,
};

const CustomModal = ({
  container,
  dataTestId,
  ModalComponent,
  ModalContent,
  modalContentParameters,
  ModalFooter,
  modalFooterParameters,
  ModalTitle,
  modalTitleParameters,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const removeCustomModal = () => {
    if (onClose) {
      onClose();
    }

    setIsVisible(false);

    unmountComponentAtNode(container);
    container.parentNode.removeChild(container);

    document.body.style['overflow-y'] = 'auto';
  };

  const title = ModalTitle
    ? (
      <ModalTitle
        removeModal={removeCustomModal}
        {...modalTitleParameters}
      />
    ) : null;

  const footer = ModalFooter
    ? (
      <ModalFooter
        removeModal={removeCustomModal}
        {...modalFooterParameters}
      />
    ) : null;

  return (
    <ModalComponent
      id="regular-modal"
      data-testid={dataTestId}
      onClose={removeCustomModal}
      show={isVisible}
      footer={footer}
      title={title}
      align="center"
      density="high"
      portal
    >
      <div>
        <ModalContent
          removeModal={removeCustomModal}
          {...modalContentParameters}
        />
      </div>
    </ModalComponent>
  );
};

CustomModal.propTypes = {
  container: PropTypes.objectOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired,
  ModalComponent: PropTypes.instanceOf(Object),
  ModalContent: PropTypes.instanceOf(Object).isRequired,
  modalContentParameters: PropTypes.objectOf(PropTypes.any).isRequired,
  ModalFooter: PropTypes.instanceOf(Object),
  modalFooterParameters: PropTypes.objectOf(PropTypes.any),
  ModalTitle: PropTypes.instanceOf(Object),
  modalTitleParameters: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
};

CustomModal.defaultProps = {
  ModalComponent: Modal,
  ModalFooter: undefined,
  modalFooterParameters: {},
  ModalTitle: undefined,
  modalTitleParameters: {},
  onClose: undefined,
};

export const renderModal = ({
  id = 'ucde-modal',
  dataTestId = 'ucde-modal',
  component,
  content,
  parameters = {},
  footer = undefined,
  footerParameters = {},
  title = undefined,
  titleParameters = {},
  onClose = undefined,
}) => {
  const containerDomNode = document.createElement('div');
  containerDomNode.id = id;

  document.body.style['overflow-y'] = 'hidden';
  document.body.appendChild(containerDomNode);

  render(
    <CustomModal
      container={containerDomNode}
      dataTestId={dataTestId}
      ModalComponent={component}
      ModalContent={content}
      modalContentParameters={parameters}
      ModalFooter={footer}
      modalFooterParameters={footerParameters}
      ModalTitle={title}
      modalTitleParameters={titleParameters}
      onClose={onClose}
    />,
    containerDomNode,
  );
};

export const renderModalWithActionButtons = ({
  id = 'ucde-modal-with-action-buttons',
  dataTestId = 'ucde-modal-with-action-buttons',
  content,
  parameters = {},
  actionLabel = undefined,
  actionCallback = undefined,
  dismissLabel = undefined,
  dismissCallback = undefined,
  onClose = undefined,
} = {}) => {
  const footer = actionLabel || dismissLabel ? ActionButtons : null;

  renderModal({
    id,
    dataTestId,
    content,
    parameters,
    footer,
    footerParameters: {
      actionLabel,
      actionCallback,
      dismissLabel,
      dismissCallback,
    },
    onClose,
  });
};

export default renderModal;
