import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { selectRootMatch } from '../../../selectors/routing';
import { makePathRelative } from '../../../utils/routing';
import { getCookie } from '../../../utils/globals';
import { USER_TYPE } from '../../../constants/cookieNames';
import { selectPortalElementsProgramLevel } from '../../../store/modules/portalElements/selectors';
import { DEEPLINK_MAP } from '../../../utils/constants';
import { registerUDLEvent } from '../../../utils/registerUDLEvent';
import { isValidSchema } from '../../../utils/deeplinks';

const IsUserLevelBase = () => {
  const userType = useSelector(selectPortalElementsProgramLevel) || getCookie(USER_TYPE);
  return userType.toLowerCase() === 'base';
};

export const GoToViewNotifications = resultLink => {
  const dispatch = useDispatch();
  const match = useSelector(selectRootMatch);
  const buildRelativePath = makePathRelative(match);
  const relativePath = buildRelativePath(resultLink);

  return () => {
    dispatch(push(relativePath));
  };
};

export const OnRegisterEvent = (linkPlacement, param) => {
  const eventInfo = {
    'data-udl-link-id': param.linkID,
    'data-udl-link-placement': linkPlacement,
  };
  return registerUDLEvent(eventInfo);
};

export const shouldShowAction = () => {
  const showAction = !IsUserLevelBase();
  return ({
    schema,
    key,
  }) => {
    if (showAction && isValidSchema(schema)) {
      // show only known key to link mappings
      return DEEPLINK_MAP[key];
    }
    // will hide unknown schemas - besides hp-smart-app, only http and https are valid for now
    return showAction && schema && schema.startsWith('http');
  };
};

export const notificationMenuOption = (showNotificationSettings = false) => [
  showNotificationSettings && {
    value: 1,
    stringId: 'myAccount.notificationSettings.mainHeader',
    linkTo: DEEPLINK_MAP.account_details_notification_settings,
    OnClick: GoToViewNotifications,
  },
  {
    value: 2,
    stringId: 'myAccount.headers.viewNotifications',
    linkTo: DEEPLINK_MAP.account_details_notification_view,
    OnClick: GoToViewNotifications,
  },
].filter(Boolean);
