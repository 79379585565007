import axios from 'axios';
import Config from '../config';

export const redundantLocales = {
  de_LI: 'de_DE',
  de_LU: 'de_DE',
  en_AE: 'en_US',
  en_AF: 'en_US',
  en_AG: 'en_US',
  en_AI: 'en_US',
  en_AL: 'en_US',
  en_AW: 'en_US',
  en_AZ: 'en_US',
  en_BA: 'en_US',
  en_BB: 'en_US',
  en_BD: 'en_US',
  en_BH: 'en_US',
  en_BN: 'en_US',
  en_BS: 'en_US',
  en_BT: 'en_US',
  en_BW: 'en_US',
  en_BZ: 'en_US',
  en_CM: 'en_US',
  en_CR: 'en_US',
  en_CY: 'en_US',
  en_DZ: 'en_US',
  en_EG: 'en_US',
  en_ET: 'en_US',
  en_GD: 'en_US',
  en_GE: 'en_US',
  en_GH: 'en_US',
  en_HK: 'en_US',
  en_ID: 'en_US',
  en_IL: 'en_US',
  en_IN: 'en_US',
  en_IS: 'en_US',
  en_JM: 'en_US',
  en_JO: 'en_US',
  en_JP: 'en_US',
  en_KE: 'en_US',
  en_KH: 'en_US',
  en_KN: 'en_US',
  en_KW: 'en_US',
  en_KY: 'en_US',
  en_LA: 'en_US',
  en_LB: 'en_US',
  en_LC: 'en_US',
  en_LK: 'en_US',
  en_LY: 'en_US',
  en_MA: 'en_US',
  en_ME: 'en_US',
  en_MK: 'en_US',
  en_MM: 'en_US',
  en_MN: 'en_US',
  en_MS: 'en_US',
  en_MU: 'en_US',
  en_MV: 'en_US',
  en_MY: 'en_US',
  en_NA: 'en_US',
  en_NG: 'en_US',
  en_NP: 'en_US',
  en_OM: 'en_US',
  en_PH: 'en_US',
  en_PK: 'en_US',
  en_PR: 'en_US',
  en_QA: 'en_US',
  en_RS: 'en_US',
  en_RW: 'en_US',
  en_SA: 'en_US',
  en_SG: 'en_US',
  en_SX: 'en_US',
  en_TC: 'en_US',
  en_TH: 'en_US',
  en_TL: 'en_US',
  en_TN: 'en_US',
  en_TT: 'en_US',
  en_TW: 'en_US',
  en_TZ: 'en_US',
  en_UG: 'en_US',
  en_VC: 'en_US',
  en_VG: 'en_US',
  en_VI: 'en_US',
  en_VN: 'en_US',
  en_ZA: 'en_US',
  en_ZM: 'en_US',
  es_AR: 'es_ES',
  es_BO: 'es_ES',
  es_BZ: 'es_ES',
  es_CL: 'es_ES',
  es_CO: 'es_ES',
  es_CU: 'es_ES',
  es_DO: 'es_ES',
  es_EC: 'es_ES',
  es_GT: 'es_ES',
  es_HN: 'es_ES',
  es_MX: 'es_ES',
  es_NI: 'es_ES',
  es_PA: 'es_ES',
  es_PE: 'es_ES',
  es_PR: 'es_ES',
  es_PY: 'es_ES',
  es_SV: 'es_ES',
  es_UY: 'es_ES',
  es_VE: 'es_ES',
  fr_BJ: 'fr_FR',
  fr_BL: 'fr_FR',
  fr_CI: 'fr_FR',
  fr_GF: 'fr_FR',
  fr_GP: 'fr_FR',
  fr_HT: 'fr_FR',
  fr_LC: 'fr_FR',
  fr_MF: 'fr_FR',
  fr_MG: 'fr_FR',
  fr_MQ: 'fr_FR',
  fr_NC: 'fr_FR',
  fr_PF: 'fr_FR',
  fr_RE: 'fr_FR',
  fr_SN: 'fr_FR',
  fr_YT: 'fr_FR',
  nl_BQ: 'nl_NL',
  nl_CW: 'nl_NL',
  pt_AO: 'pt_PT',
  pt_MZ: 'pt_PT',
  ru_BY: 'ru_RU',
  ru_KZ: 'ru_RU',
  ru_UA: 'ru_RU',
  ru_UZ: 'ru_RU',
  sv_FI: 'sv_SE',
};

export const contentStackRedundantLocales = {
  ...redundantLocales,
  de_AT: 'de_DE',
  de_CH: 'de_DE',
  el_CY: 'el_GR',
  en_AU: 'en_US',
  en_CA: 'en_US',
  en_IE: 'en_US',
  en_MT: 'en_US',
  en_NZ: 'en_US',
  fr_BE: 'fr_FR',
  fr_CA: 'fr_FR',
  fr_CH: 'fr_FR',
  fr_LU: 'fr_FR',
  it_CH: 'it_IT',
  nl_BQ: 'nl_BE',
  nl_CW: 'nl_BE',
  nl_NL: 'nl_BE',
  ro_MD: 'ro_RO',
};

// Published locales are controlled by a feature flag, these are the default ones.
export const PublishedDefault = [
  'bg_BG',
  'cs_CZ',
  'da_DK',
  'de_AT',
  'de_CH',
  'de_DE',
  'de_LI',
  'de_LU',
  'el_CY',
  'el_GR',
  'en_AE',
  'en_AF',
  'en_AG',
  'en_AI',
  'en_AL',
  'en_AU',
  'en_AW',
  'en_AZ',
  'en_BA',
  'en_BB',
  'en_BD',
  'en_BH',
  'en_BN',
  'en_BS',
  'en_BT',
  'en_BW',
  'en_BZ',
  'en_CA',
  'en_CM',
  'en_CR',
  'en_CY',
  'en_DZ',
  'en_EG',
  'en_ET',
  'en_GB',
  'en_GD',
  'en_GE',
  'en_GH',
  'en_HK',
  'en_ID',
  'en_IE',
  'en_IL',
  'en_IN',
  'en_IS',
  'en_JM',
  'en_JO',
  'en_JP',
  'en_KE',
  'en_KH',
  'en_KN',
  'en_KW',
  'en_KY',
  'en_LA',
  'en_LB',
  'en_LC',
  'en_LK',
  'en_LY',
  'en_MA',
  'en_ME',
  'en_MK',
  'en_MM',
  'en_MN',
  'en_MS',
  'en_MT',
  'en_MU',
  'en_MV',
  'en_MY',
  'en_NA',
  'en_NG',
  'en_NP',
  'en_NZ',
  'en_OM',
  'en_PH',
  'en_PK',
  'en_PR',
  'en_QA',
  'en_RS',
  'en_RW',
  'en_SA',
  'en_SG',
  'en_SX',
  'en_TC',
  'en_TH',
  'en_TL',
  'en_TN',
  'en_TT',
  'en_TW',
  'en_TZ',
  'en_UG',
  'en_US',
  'en_VC',
  'en_VG',
  'en_VI',
  'en_VN',
  'en_ZA',
  'en_ZM',
  'es_AR',
  'es_BO',
  'es_CL',
  'es_CO',
  'es_CU',
  'es_DO',
  'es_EC',
  'es_ES',
  'es_GT',
  'es_HN',
  'es_MX',
  'es_NI',
  'es_PA',
  'es_PE',
  'es_PR',
  'es_PY',
  'es_SV',
  'es_UY',
  'es_VE',
  'et_EE',
  'fi_FI',
  'fr_BE',
  'fr_BJ',
  'fr_BL',
  'fr_CA',
  'fr_CH',
  'fr_CI',
  'fr_FR',
  'fr_GF',
  'fr_GP',
  'fr_HT',
  'fr_LC',
  'fr_LU',
  'fr_MF',
  'fr_MG',
  'fr_MQ',
  'fr_NC',
  'fr_PF',
  'fr_RE',
  'fr_SN',
  'fr_YT',
  'hr_HR',
  'hu_HU',
  'it_CH',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'lt_LT',
  'lv_LV',
  'nb_NO',
  'nl_BE',
  'nl_BQ',
  'nl_CW',
  'nl_NL',
  'pl_PL',
  'pt_AO',
  'pt_BR',
  'pt_MZ',
  'pt_PT',
  'ro_MD',
  'ro_RO',
  'ru_BY',
  'ru_KZ',
  'ru_RU',
  'ru_UA',
  'ru_UZ',
  'sk_SK',
  'sl_SI',
  'sv_FI',
  'sv_SE',
  'tr_TR',
  'zh_CN',
  'zh_HK',
  'zh_TW',
];

const UnpublishedDefault = [];

// All the locales supported (published + unpublished) by the Portal.
export const Locales = [
  ...PublishedDefault,
  ...UnpublishedDefault,
];

export const FallbackCountries = {
  bg: 'BG',
  cs: 'CZ',
  da: 'DK',
  de: 'DE',
  el: 'GR',
  en: 'US',
  es: 'ES',
  et: 'EE',
  fi: 'FI',
  fr: 'FR',
  hr: 'HR',
  hu: 'HU',
  it: 'IT',
  ja: 'JP',
  ko: 'KR',
  lt: 'LT',
  lv: 'LV',
  nb: 'NO',
  nl: 'NL',
  pl: 'PL',
  pt: 'PT',
  ro: 'RO',
  ru: 'RU',
  sk: 'SK',
  sl: 'SI',
  sv: 'SE',
  tr: 'TR',
  zh: 'CN',
};

export const getLocaleMessages = async locale => {
  // Providing all locales, published or not.
  if (Locales.includes(locale)) {
    try {
      const response = await axios.get(`${Config.LANGUAGE_FILES_URL}/${locale}.json?v=1`);
      return response.data;
    } catch (ex) {
      // Return null when unable to load the messages
    }
  }
  return null;
};
