import Types from './types';
import { makeActionCreator } from '../../../utils/redux';
import { makeApiCall } from '../../../actions/application';
import { getHPAdvancedSubscription } from '../../../api/UCDEGateway';

export const getHpAdvancedSubscriptionRequest = makeActionCreator(
  Types.HP_ADVANCED_SUBSCRIPTION_REQUEST,
  'payload',
);

const getHpAdvancedSubscriptionSuccess = makeActionCreator(
  Types.HP_ADVANCED_SUBSCRIPTION_SUCCESS,
  'payload',
);

const getHpAdvancedSubscriptionFailed = makeActionCreator(
  Types.HP_ADVANCED_SUBSCRIPTION_FAILED,
  'payload',
);

export const getHpAdvancedSubscription = makeApiCall({
  asyncFn: getHPAdvancedSubscription,
  successActionCreator: getHpAdvancedSubscriptionSuccess,
  failureActionCreator: getHpAdvancedSubscriptionFailed,
});

const Actions = {
  getHpAdvancedSubscription,
  getHpAdvancedSubscriptionRequest,
  getHpAdvancedSubscriptionSuccess,
  getHpAdvancedSubscriptionFailed,
};

export default Actions;
