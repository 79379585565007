import React, { useCallback, useEffect, useState } from 'react';
import RadioButtons from '@veneer/core/dist/scripts/radio_buttons/radio_buttons';
import RadioButton from '@veneer/core/dist/scripts/radio_button/radio_button';
import Button from '@veneer/core/dist/scripts/button/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getOrganizations } from '../../api/UCDEGateway';
import Loader from '../shared-components/Loader';
import {
  exchangeOrgToken,
  getSelectedOrgId,
  setSelectedOrgId,
} from '../../utils/auth';
import { useSelector } from '../../hooks';
import { DEEPLINK_MAP, PATHS } from '../../utils/constants';
import { makePathRelative, shouldRedirectToMyPrintersPage } from '../../utils/routing';
import { selectRootMatch } from '../../selectors/routing';
import { cropText, getCookie } from '../../utils/globals';
import {
  IS_INVITE,
} from '../../constants/cookieNames';
import { selectOnboardingProps } from '../../selectors/user';
import i18n from '../../utils/i18n';
import { OrgSelectorModal, SubHeader } from './styles';

const OrgSelector = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectRootMatch);
  const relPath = makePathRelative(match);
  const [orgSelected, setOrg] = useState(null);
  const [orgList, setOrgList] = useState({
    hasLoaded: false,
    isLoading: true,
    error: null,
    items: null,
    exchanging: false,
  });
  const onboardingProps = useSelector(selectOnboardingProps());
  const { redirectTo, country, language } = onboardingProps;

  const getCropedResourceId = nodeId => cropText({
    data: nodeId,
    limit: 4,
    startWithEllipsis: true,
    showEllipsis: false,
  });

  const getRedirectPath = () => (shouldRedirectToMyPrintersPage(redirectTo) ? `/${country}/${language}/ucde${PATHS.MY_PRINTERS}` : redirectTo);

  const redirectToPortalWithSelectedOrgId = orgId => {
    setSelectedOrgId(orgId);
    dispatch(push(relPath(redirectTo ? getRedirectPath() : DEEPLINK_MAP.ucde)));
  };

  const getOrgLabel = (orgName, orgID) => i18n.t('myPrinters.orgSelector.optionTemplate', {
    orgName,
    orgID: getCropedResourceId(orgID),
  });

  const exchange = useCallback(async orgId => {
    setOrgList({
      ...orgList,
      exchanging: true,
    });

    let error = null;
    const targetOrg = orgSelected || orgId;

    if (targetOrg === 'CreatePersonalAccount') {
      redirectToPortalWithSelectedOrgId(targetOrg);
      return;
    }

    try {
      await exchangeOrgToken(targetOrg);
      redirectToPortalWithSelectedOrgId(targetOrg);
    } catch (err) {
      error = 'Failed to exchange token';
    }

    if (error) {
      setOrgList({
        ...orgList,
        isLoading: false,
        exchanging: false,
        error,
      });
    }
  }, [orgSelected]);

  useEffect(() => {
    async function fetchData() {
      if (orgList.isLoading && !orgList.hasLoaded && !orgList.error) {
        let items = [];
        let error = null;
        let personalOrgId = '';

        const personalOrgCreationItem = {
          value: 'CreatePersonalAccount',
          label: i18n.t('myPrinters.orgSelector.personalAccount'),
          type: 'Personal',
        };

        try {
          const response = await getOrganizations();

          if (response.status === 200) {
            items = response.data.map(org => {
              if (org.type === 'Personal') {
                personalOrgId = org.nodeId;
              }

              return {
                value: org.nodeId,
                label: org.type === 'Personal' ? org.accountName : getOrgLabel(org.accountName, org.nodeId),
                type: org.type,
              };
            });
          }

          if (items.length === 0) { // account has no org
            redirectToPortalWithSelectedOrgId(personalOrgCreationItem.value);
          }

          if (personalOrgId && items.length === 1) { // account has only personal org
            exchange(personalOrgId);
          }

          if (!personalOrgId) { // account has no personal org
            items = [...items, personalOrgCreationItem];
          }
        } catch (err) {
          error = 'Failed to load organizations';
        }

        setOrgList({
          ...orgList,
          isLoading: items.length === 0 || (personalOrgId && items.length === 1), // automatic exchange cases
          hasLoaded: error === null,
          items,
          error,
        });
      }
    }

    const orgIdFromInvite = getSelectedOrgId();

    if (getCookie(IS_INVITE) === 'true' && orgIdFromInvite) { // invite flow
      redirectToPortalWithSelectedOrgId(orgIdFromInvite);
    } else {
      fetchData();
    }
  }, []);

  if (orgList.isLoading && !orgList.error) {
    return <Loader fullScreen />;
  }

  return (
    <OrgSelectorModal
      id="org-selector-modal"
      title={i18n.t('myPrinters.orgSelector.header')}
      show
      footer={(
        <Button
          appearance="primary"
          disabled={!orgList.hasLoaded || orgSelected === null}
          loading={orgList.exchanging}
          expanded
          onClick={() => exchange()}
        >
          {i18n.t('myPrinters.orgSelector.button')}
        </Button>
      )}
    >
      {orgList.error && (<p>{orgList.error}</p>)}
      {orgList.hasLoaded && orgList.items && (
        <>
          <SubHeader>{`${i18n.t('myPrinters.orgSelector.subHeader')}:`}</SubHeader>
          <RadioButtons
            name="organizations"
            onChange={(e, value) => setOrg(value)}
          >
            {orgList.items.map(org => (
              <RadioButton
                key={`org-${org.value}`}
                value={org.value}
                label={org.label}
                disabled={orgList.exchanging}
              />
            ))}
          </RadioButtons>
        </>
      )}
    </OrgSelectorModal>
  );
};

export default OrgSelector;
