const udlString = `(function(w,d,s,l,i,o){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var
f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;j.onload=o;f.parentNode.insertBefore(j,f);})(window,document,
'script','dataLayer','GTM-MZXB4R4');
`;

export default function injectUDL({ callback }) {
  if (!document.getElementById('udl-script')) {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('id', 'udl-script');
    document.head.appendChild(script);
    script.text = udlString;
  }
  callback();
}
