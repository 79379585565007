export class PortalEvents {
  constructor() {
    this.onEvent = {};
  }

  addEventListener = (name, action) => {
    if (!this.onEvent[name]) {
      this.onEvent[name] = [];
    }
    this.onEvent[name].push(action);
  }

  removeEventListener = (name, action) => {
    if (this.onEvent[name]) {
      const index = this.onEvent[name].indexOf(action);
      if (index >= 0) {
        this.onEvent[name].splice(index, 1);
      }
    }
  }

  triggerEvent = (name, event) => {
    if (this.onEvent[name]) {
      this.onEvent[name].forEach(action => {
        action(event);
      });
    }
  }
}

const portalEvents = new PortalEvents();

export default portalEvents;
