import { createSelector } from 'reselect';
import {
  both,
  identity,
  includes,
  is,
  path,
  pathSatisfies,
  prop,
} from 'ramda';
import { SERVICE_TAGS } from '../../../utils/constants';

const selectorsBase = {
  UCDE_PORTAL_ELEMENTS: prop('ucdePortalElements'),
};

const selectUcdePortalElements = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  identity,
);

export const selectUcdePortalElementsLoading = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  prop('loading'),
);

export const selectHasPrintPlansActiveSubscription = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  pathSatisfies(
    both(
      is(Array),
      includes(SERVICE_TAGS.SEE_PRINT_PLANS_WITH_SUB_MENUS),
    ),
    ['printPlans', 'tags'],
  ),
);

export const selectHasHpOnePrintersActiveSubscription = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  pathSatisfies(
    both(
      is(Array),
      includes(SERVICE_TAGS.SEE_HP_ONE_PRINTERS_MENU),
    ),
    ['hpOnePrinters', 'tags'],
  ),
);

export const selectHasPlatinumServiceActiveSubscription = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  pathSatisfies(
    both(
      is(Array),
      includes('SEE_PLATINUM_SERVICES_WITH_SUB_MENUS'),
    ),
    ['platinumServices', 'tags'],
  ),
);

export const selectPortalElementsProgramLevel = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  portalElements => path(['info', 'programLevel'], portalElements),
);

export const selectFeatureCards = createSelector(
  selectorsBase.UCDE_PORTAL_ELEMENTS,
  portalElements => path(['home', 'cards', 'features'], portalElements),
);

export default selectUcdePortalElements;
