import { pathOr } from 'ramda';
import { getDashboardFeatures } from './actions';
import { createReducer } from '../../../utils/redux';

const initialState = {};

function getDashboardFeaturesHandler(state = {}, action) {
  const lastFetch = new Date();
  const isFetching = pathOr(false, ['payload', 'fetching'], action);
  const isError = pathOr(false, ['payload', 'error'], action);
  const totalCount = pathOr(0, ['payload', 'totalCount'], action);
  const printAnywhere = pathOr({}, ['payload', 'printAnywhere'], action);
  const smartSecurity = pathOr({}, ['payload', 'smartSecurity'], action);

  const result = {
    ...state,
    lastFetch,
    isFetching,
    isError,
    totalCount,
    printAnywhere,
    smartSecurity,
  };

  return result;
}

const handlers = {
  [getDashboardFeatures]: getDashboardFeaturesHandler,
};

const dashboardFeaturesReducer = createReducer(initialState, handlers);

export default dashboardFeaturesReducer;
