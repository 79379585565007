import React from 'react';
import PropTypes from 'prop-types';
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle';
import Button from '@veneer/core/dist/scripts/button';
import Config from '../../config';
import {
  Box,
  BoxContent,
} from './styles';
import i18n from '../../utils/i18n';

// Notes:
// - https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
//   Not using hooks because: "There are no Hook equivalents for these methods
//   (getSnapshotBeforeUpdate, componentDidCatch and getDerivedStateFromError) yet,
//   but they will be added soon.
// - https://reactjs.org/docs/error-boundaries.html
//   Error boundaries do not catch errors for: event handlers, async code, server side
//   rendering, errors thrown in the error boundary itself
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info,
    });
    console.error('ERROR', error, 'INFO', info);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      const showFullMessage = Config.STACK === 'dev' || Config.STACK === 'pie';

      if (showFullMessage) {
        const { error, info } = this.state;
        let toClipboard;
        let errorText;
        if (error instanceof Error) {
          toClipboard = `Error: ${error.name}\n\nMessage:\n${error.message}\n\nStack:\n${error.stack}`;
          errorText = (
            <>
              <h3>{error.name}</h3>
              <div>{error.message}</div>
              <pre>{error.stack}</pre>
            </>
          );
        } else if (typeof error === 'string') {
          toClipboard = `Error: ${error}`;
          errorText = <div>{error}</div>;
        } else {
          toClipboard = `Error:\n${JSON.stringify({ error })}`;
          errorText = <div>{JSON.stringify({ error })}</div>;
        }

        let stackText;
        if (info && info.componentStack) {
          toClipboard += `\n\nComponent Stack:\n${info.componentStack}`;
          stackText = (
            <>
              <h3>Component Stack</h3>
              <pre>{info.componentStack}</pre>
            </>
          );
        }

        const copyToClipboard = () => {
          const text = document.createElement('textarea');
          text.innerHTML = toClipboard;
          document.body.appendChild(text);
          text.select();
          text.setSelectionRange(0, toClipboard.length - 1);
          document.execCommand('copy');
          text.remove();
        };

        return (
          <Box>
            <BoxContent>
              <h2>An error occurred</h2>
              <div>
                To easily report this issue, please use the button
                below to copy the error message to clipboard.
              </div>
              <Button onClick={copyToClipboard}>Copy to Clipboard</Button>
              <hr />
              {errorText}
              {stackText}
            </BoxContent>
          </Box>
        );
      }

      const message = i18n.t('errorBoundary.genericMessage');

      return (
        <Box centered>
          <BoxContent>
            <IconMinusCircle color="colorRed6" size={40} />
            <div>{message}</div>
          </BoxContent>
        </Box>
      );
    }

    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
