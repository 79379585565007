import {
  all,
  call,
  put,
  takeLeading,
} from 'redux-saga/effects';
import {
  pick,
  prop,
  propEq,
  propOr,
} from 'ramda';
import {
  getAllUserDevices,
  getDeviceCacheDevices,
  getDeviceCompleteInfo,
  getDeviceSuppliesInfo,
} from '../../../api/UCDEGateway';
import {
  getDetailedDevices,
  getDetailedDevicesFailed,
  getDetailedDevicesSuccess,
  getDeviceSupplies,
  getDevices,
} from './actions';
import i18n from '../../../utils/i18n';

function* fetchDeviceCacheDevices(devicesTypes) {
  const resp = yield call(getDeviceCacheDevices, devicesTypes);
  return propOr([], 'data', resp);
}

function* fetchDetailedDevice({ cloudId, noCache }) {
  try {
    const resp = yield call(getDeviceCompleteInfo, { cloudId }, noCache);
    return propOr({}, 'data', resp);
  } catch (e) {
    return {
      status: {
        error: e,
      },
    };
  }
}

function* handleDetailedDeviceFetch(action) {
  try {
    const { payload: { refresh, hpxDevices: hpxDevicesFeatureFlag } } = action;

    yield put(getDevices({ fetching: true }));
    const { data: devices } = yield call(getAllUserDevices, refresh);
    const devicesWithNoCache = devices.map((device => ({
      ...device,
      noCache: refresh,
    })));
    let detailedDeviceResponses = yield all(devicesWithNoCache.map(fetchDetailedDevice));

    if (hpxDevicesFeatureFlag) {
      const deviceCachePCs = yield call(fetchDeviceCacheDevices, 'iot-laptop');
      if (Array.isArray(deviceCachePCs) && deviceCachePCs.length) {
        detailedDeviceResponses = [...detailedDeviceResponses, ...deviceCachePCs];
      }
    }

    const detailedDevices = detailedDeviceResponses.map(resp => {
      const deviceResponse = prop('device', resp);
      const device = {
        ...deviceResponse,
        modelName: deviceResponse.modelName || i18n.t('myPrinters.defaultPrinterName'),
      };
      const deviceCloudId = prop('cloudId', deviceResponse);
      const initialDevice = devices.find(propEq('cloudId', deviceCloudId));
      const freshData = pick([
        'health',
        'supplies',
        'securityAssessment',
        'status',
        'subscription',
        'printerOptions',
        'ownershipId',
        'benefits',
        'firmwareUpdateConfiguration',
      ], resp);
      const result = {
        ...initialDevice,
        ...device,
        ...freshData,
      };
      return result;
    });

    yield put(getDevices({
      fetching: false,
      error: false,
      data: detailedDevices,
      lastFetch: new Date(),
    }));
    yield put(getDetailedDevicesSuccess());
  } catch (error) {
    yield put(getDevices({ fetching: false, error: true }));
    yield put(getDetailedDevicesFailed());
  }
}

function* handleDeviceSuppliesFetch(action) {
  try {
    yield put(getDeviceSupplies({ ...action.payload, fetchingSupplies: true }));

    const resp = yield call(getDeviceSuppliesInfo, action.payload.cloudId);

    yield put(getDeviceSupplies({ supplies: resp.data, cloudId: action.payload.cloudId, fetchingSupplies: false }));
  } catch (error) {
    yield put(getDeviceSupplies({ ...action.payload, fetchingSupplies: false }));
  }
}

export function* watchGetDeviceSupplies() {
  yield takeLeading(getDeviceSupplies.toString(), handleDeviceSuppliesFetch);
}

export function* watchGetDetailedDevices() {
  yield takeLeading(getDetailedDevices.toString(), handleDetailedDeviceFetch);
}
