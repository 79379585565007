import React from 'react';
import PropTypes from 'prop-types';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';

import { Container } from './styles';

const Loader = ({ fullScreen }) => (
  <Container fullScreen={fullScreen}>
    <ProgressIndicator />
  </Container>
);

Loader.propTypes = {
  fullScreen: PropTypes.bool,
};

Loader.defaultProps = {
  fullScreen: false,
};

export default Loader;
