export class PortalActivity {
  constructor() {
    this.idleTimer = null;
  }

  register = idleTimer => {
    this.idleTimer = idleTimer;
  };

  isIdle = () => {
    if (this.idleTimer && this.idleTimer.isIdle) {
      return this.idleTimer.isIdle();
    }
    return false;
  }
}

const portalActivity = new PortalActivity();

export default portalActivity;
