export const HOME_PAGE = {
  ACCOUNT_PROFILE: 'SEE_ACCOUNT_PROFILE',
  SMART_DASHBOARD: 'SMART_DASHBOARD',
  STANDARD_DASHBOARD: 'STANDARD_DASHBOARD',
};

export const PATHS = {
  ACCOUNT_DETAILS: {
    NOTIFICATIONS_SETTINGS: '/account-details/notification-settings',
    PRINT_PLANS: '/account-details/(shipping-information|billing-information)',
    PRINT_PLANS_SHIPPING_INFORMATION: '/account-details/shipping-information',
    PRINT_PLANS_BILLING_INFORMATION: '/account-details/billing-information',
    ORDERS: '/account-details/orders',
    PROFILE: '/account-details/profile',
    USERS: '/account-details/manage-users',
    VIEW_NOTIFICATIONS: '/account-details/view-notifications',
    DELETE_ACCOUNT: '/account-details/delete-account',
    SHIPPING_BILLING: '/account-details/shipping-billing',
    SUBSCRIPTIONS: '/account-details/subscriptions',
    STATEMENTS: '/account-details/statements',
  },
  CHECKOUT: '/checkout',
  HELP_CENTER: '/help/:topic?/:subtopic?',
  HP_PLUS_SECOND_OFFER: '/my-printers/activate-hp-plus',
  HOME: '/home',
  MY_PRINTERS: '/my-printers',
  ONBOARDING: '/loggedin',
  ORG_SELECTOR: '/org-selector',
  PRINT_PLANS: '/print_plans',
  CHANGE_PLAN: '/print_plans/my_account',
  PAAS_DASHBOARD: {
    OVERVIEW: '/paas/dashboard',
    UPDATE_PLAN: '/paas/update-plan',
    HISTORY: '/paas/history',
    SHIPMENT_TRACKING: '/paas/shipment-tracking',
  },
  SERVICES: {
    HP_SMART_ADVANCE: '/services/hp-smart-advance',
    PRINT_ANYWHERE: '/services/print-on-the-go',
    PRINT_ON_THE_GO: '/services/print-on-the-go/:component?',
    SMART_SECURITY: '/services/smart-security/:deviceID?',
    SUMMARY: '/services/summary',
  },
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
  SUSTAINABILITY: {
    FOREST_FIRST: '/sustainability/forest-first',
  },
  HPX_MY_ACCOUNT: '/my-account',
  HP_ONE_PC: '/hp-all-in-laptop-plan',
  HP_ONE_PRINTER: {
    OVERVIEW: '/hp-all-in-print-plan/overview',
    UPDATE_PLAN: '/hp-all-in-print-plan/update-plan',
    PRINT_HISTORY: '/hp-all-in-print-plan/print-history',
    SHIPMENT_TRACKING: '/hp-all-in-print-plan/shipment-tracking',
  },
};

export const MATCHES = {
  HP_ONE_PC: '/(hpone|hp-all-in-laptop-plan)',
  HP_ONE_PRINTER: {
    OVERVIEW: '/(hpone-printer|hp-all-in-print-plan)/overview',
    UPDATE_PLAN: '/(hpone-printer|hp-all-in-print-plan)/update-plan',
    PRINT_HISTORY: '/(hpone-printer|hp-all-in-print-plan)/print-history',
    SHIPMENT_TRACKING: '/(hpone-printer|hp-all-in-print-plan)/shipment-tracking',
    CANCELLATION: '/(hpone-printer|hp-all-in-print-plan)/cancellation',
  },
};

export const SERVICE_TAGS = {
  SEE_HP_ONE_PCS_MENU: 'SEE_HP_ONE_PCS_MENU',
  SEE_HP_ONE_PRINTERS_MENU: 'SEE_HP_ONE_PRINTERS_MENU',
  SEE_PRINT_PLANS_WITH_SUB_MENUS: 'SEE_PRINT_PLANS_WITH_SUB_MENUS',
};

export const HP_PLUS_SECOND_OFFER_PUBLIC_PATH = '/activate-hp-plus';
export const MOBILE_FAX_PUBLIC_PATH = '/mobile-fax';
export const PAPER_SUBSCRIPTION_PUBLIC_PATH = '/paper-subscription';

export const ROUTES_NO_NEED_PORTAL_ELEMENTS = [
  PATHS.ACCOUNT_DETAILS.NOTIFICATIONS_SETTINGS,
  PATHS.ACCOUNT_DETAILS.VIEW_NOTIFICATIONS,
  PATHS.ONBOARDING,
  PATHS.SIGNIN,
  PATHS.HPX_MY_ACCOUNT,
];

export const GATEWAY_PATHS_ENABLED_FOR_BASE_USERS = [
  PATHS.ACCOUNT_DETAILS.DELETE_ACCOUNT,
  ...ROUTES_NO_NEED_PORTAL_ELEMENTS,
];

export const SERVICES = {
  EPRINT: 'ePrint',
  HP_SMART_ADVANCE: 'smartAdvance',
  PRINT_PLANS: 'printPlans',
  PLATINUM_SERVICES: 'platinumServices',
  PRINT_ON_THE_GO: 'printOnTheGo',
  SMART_SECURITY: 'smartSecurity',
  HP_ONE_PCS: 'hpOnePCs',
  HP_ONE_PRINTERS: 'hpOnePrinters',
};

export const SERVICES_STATES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  PUBLISHED: 'Published',
};

export const PROGRAM_LEVEL = {
  HP_PLUS: 'HpPlus',
  UCDE: 'UCDE',
};

export const SUBSCRIPTION_STATE = {
  SUBSCRIBED: 'subscribed',
};

export const PRINTER_BIZ_MODEL = {
  HP_PLUS: 'E2E',
};

export const DEEPLINK_MAP = {
  // Home
  ucde: '',
  // HP+ Print Plans
  print_plans: '/print_plans',
  print_plans_my_account: '/print_plans/my_account',
  print_plans_print_history: '/print_plans/print_history',
  print_plans_ink_shipment: '/print_plans/ink_shipment',
  print_plans_account_history: '/print_plans/account_history',
  paas_dashboard: '/paas/dashboard',
  hpone_dashboard: PATHS.HP_ONE_PC,
  hpone_printer_overview: PATHS.HP_ONE_PRINTER.OVERVIEW,
  hpone_printer_update_plan: PATHS.HP_ONE_PRINTER.UPDATE_PLAN,
  hpone_printer_track_shipments: PATHS.HP_ONE_PRINTER.SHIPMENT_TRACKING,
  my_printers: '/my-printers',
  my_printer: '/my-printer',
  manage_users: '/account-details/manage-users',
  // Manage HP Benefits
  services_hp_smart_advance: '/services/hp-smart-advance',
  services_summary: '/services/summary',
  services_print_on_the_go: '/services/print-on-the-go',
  services_smart_security: '/services/smart-security',
  // My Account
  account_details_billing_information: '/account-details/billing-information',
  account_details_shipping_information: '/account-details/shipping-information',
  account_details_profile: '/account-details/profile',
  account_details_notification_settings: '/account-details/notification-settings',
  account_details_notification_view: '/account-details/view-notifications',
  statements: '/account-details/statements',
  subscriptions: '/account-details/subscriptions',
  orders: '/account-details/orders',
  account_details_shipping_billing: '/account-details/shipping-billing',
  // Help Center
  help: '/help',
  help_about_hp_smart: '/help/about-hp-smart',
  help_additional_help_and_support: '/help/additional-help-and-support',
  help_hp_plus: '/help/hp-plus',
  help_hp_plus_hp_smart_advance: '/help/hp-plus/hp-smart-advance',
  help_printer_and_connection_information: '/help/printer-and-connection-information',
  help_printing_and_scanning: '/help/printing-and-scanning',
  help_hp_smart_advance: '/help/hp-smart-advance',
};

/*
  * Definitions over this doc: https://emailregex.com/
  */
export const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line

export const LD_ANONYMOUS_USER_KEY = '2f183a4e64493af3f377f745eda502363cd3e7ef6e4d266d444758de0a85fcc8-ANONYMOUS';
export const LD_COMPONENT_NAME = 'LaunchDarkly';

export const REFRESH_TOKEN_RETRIES = 2;

// JWeb Events
export const JWEB_EVENTS = {
  CLOSE: 'Close',
  JARVIS_FINISHED: 'jarvisEventFinished',
  CLEANUP_ACCOUNT_DELETION: 'CleanupAccountDeletion',
};

export const ORGANIZATION_FILTERS = {
  user: 'user',
  admin: 'admin',
  all: 'all',
};

export const COMFE_QUERY_STRING = 'comfe';

// HP+ Second Offer promotion event data
export const SECOND_OFFER_PROMO_ID = 'HP_PLUS_2ND_CHANCE_V1';
export const SECOND_OFFER_PROMO_NAME = 'HP+ conversion';
export const COUNTRY_UNAVAILABLE = 'Unavailable';

export const SPLUNK_RUM = {
  beaconUrl: 'https://rum-ingest.us1.signalfx.com/v1/rum',
  app_name: 'print:hpsmart:ucde-portal',
};
