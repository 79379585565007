import { pathOr } from 'ramda';
import Actions, { getHpAdvancedSubscriptionRequest } from './actions';
import { createReducer } from '../../../utils/redux';

const initialState = {};

export const hpAdvancedSubscriptionRequest = (state = initialState) => (
  {
    ...state,
    loading: true,
  }
);

export const hpAdvancedSubscriptionSuccess = (state = initialState, action) => (
  {
    ...state,
    ...pathOr({}, ['payload', 'data'], action),
    loading: false,
  }
);

export const hpAdvancedSubscriptionFailed = (state = initialState, action) => (
  {
    ...state,
    ...pathOr({}, ['payload', 'data'], action),
    apiError: true,
    loading: false,
  }
);

export const hpAdvancedSubscriptionReset = () => initialState;

const handlers = {
  [getHpAdvancedSubscriptionRequest]: hpAdvancedSubscriptionRequest,
  [Actions.getHpAdvancedSubscriptionSuccess]: hpAdvancedSubscriptionSuccess,
  [Actions.getHpAdvancedSubscriptionFailed]: hpAdvancedSubscriptionFailed,
};

const HpAdvancedSubscriptionReducer = createReducer(initialState, handlers);

export default HpAdvancedSubscriptionReducer;
