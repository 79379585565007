import types from './types';
import { makeActionCreator } from '../../../utils/redux';

export const getDashboardFeatures = makeActionCreator(types.GET_DASHBOARD_FEATURES, 'payload');

export const getDashboardFeaturesSuccess = makeActionCreator(types.GET_DASHBOARD_FEATURES_SUCCESS, 'payload');

export const getDashboardFeaturesFailed = makeActionCreator(types.GET_DASHBOARD_FEATURES_FAILED, 'payload');

export const getDashboardFeaturesAutoRetry = {
  requestActionCreator: getDashboardFeatures,
  successActionCreator: getDashboardFeaturesSuccess,
  failureActionCreator: getDashboardFeaturesFailed,
};
