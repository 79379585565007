import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  display: -ms-flex;
  justify-content:center;
  align-items: center;
`;

export const Circle = styled.div`
  background: ${props => props.backgroundColor};
  opacity: ${props => props.backgroundOpacity};
  width: ${props => props.backgroundSize}px;
  height: ${props => props.backgroundSize}px;
  border-radius: 50%;
`;

export const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  line-height: ${props => props.backgroundSize}px;
  width: ${props => props.backgroundSize}px;
  height: ${props => props.backgroundSize}px;
  font-family: ${props => props.theme.main.hp.fontFamily};
  font-size: ${props => props.fontSize};
  color: ${props => props.fontColor};
  text-align: center;
  align-items: center;
  
  @media (max-width: ${props => props.theme.breakPoints.md}) {
    font-size: 16px;
  }
`;
