import Environments from './environments';

const env = {
  hpsmartdev: 'dev',
  hpsmartpie: 'pie',
  hpsmartstage: 'stg',
  hpsmart: 'prd',
  'hp-smartstage': 'stg',
  'hp-smart': 'prd',
  'local-hpc3': 'local',
  'local-hpsmart': 'local_v2',
  10: 'local_jweb_android',
  localhost: 'local_jweb_windows',
};

const relativeUrlsKeys = [
  'AUTHZ_OPENID_REDIRECT_URL',
  'LANGUAGE_FILES_URL',
  'MY_PRINTERS_URL',
  'MY_PRINTER_URL',
  'HPC_GATEWAY_URL',
  'UCDE_GATEWAY_URL',
  'SESSION_SERVICE_BASE_URL',
];

const apiUrlKeys = [
  'CONTENT_STACK_URL',
];

const MY_ACCOUNT_DOMAIN = 'myaccount.hp.com';
const remove3w = url => url.replace(/(?:www\.)?/, '');
const myAccountDomain = url => `hpsmart${remove3w(url).replace(/(\.)?(myaccount.*)/, '')}`;
const defaultDomain = url => remove3w(url).split('.')[0];
const domain = url => (url.includes(MY_ACCOUNT_DOMAIN) ? myAccountDomain(url) : defaultDomain(url));

const Config = {
  ...Environments.common,
  ...Environments[env[domain(window.location.hostname)]],
};

if (!Config.STACK) {
  throw new Error(`Environment not found for ${window.location.hostname}`);
}

const fixUrlForApi = url => (url.includes(MY_ACCOUNT_DOMAIN)
  ? url.replace(window.location.hostname, Config.API_DOMAIN)
  : url.replace('www', 'api'));

const stringToBoolean = value => value === true || value.toLowerCase() === 'true';
const isBooleanValue = value => value.toLowerCase() === 'false' || value.toLowerCase() === 'true';
const fixRelativeUrl = (value, isApi) => {
  let targetUrl = (!value.startsWith('http')
    ? `${window.location.protocol}//${window.location.hostname}${value}`
    : value);

  if (targetUrl.includes('/api') || isApi) {
    targetUrl = fixUrlForApi(targetUrl);
  }

  return targetUrl;
};

Object.keys(Config).forEach(key => {
  const envValue = process.env[`REACT_APP_${key}`];
  if (envValue) {
    Config[key] = isBooleanValue(envValue) ? stringToBoolean(envValue) : envValue;
  }
  if (relativeUrlsKeys.includes(key)) {
    Config[key] = fixRelativeUrl(Config[key]);
  }
  if (apiUrlKeys.includes(key)) {
    Config[key] = fixRelativeUrl(Config[key], true);
  }
});

Config.createSigninUrl = (state = '', promptLogin = false, stratusClientId) => `${Config.AUTHZ_OPENID_URL}/openid/v1/authorize?`
  + `client_id=${stratusClientId || Config.STRATUS_CLIENT_ID}`
  + `&response_type=${Config.RESPONSE_TYPE}`
  + `&scope=${Config.STRATUS_SCOPE}`
  + `&redirect_uri=${Config.AUTHZ_OPENID_REDIRECT_URL}`
  + `&state=${state}`
  + `${promptLogin ? '&prompt=login' : ''}`;

Config.baseUrl = (apiName, apiVersion) => {
  if (apiName === 'ws-hp.com/accountmgtsvc' && apiVersion === '2') {
    return `${Config.AUTHZ_ONBOARDING_BASE_URL}/v2/ecosystem/accountmgtsvc`;
  }
  if (apiName === 'ws-hp.com/uimgtsvc' && apiVersion === '2') {
    return `${Config.AUTHZ_ONBOARDING_BASE_URL}/v2/ecosystem/uimgtsvc`;
  }
  if (apiName === 'ws-hp.com/programmgtsvc' && apiVersion === '2') {
    return `${Config.AUTHZ_ONBOARDING_BASE_URL}/v2/ecosystem/programmgtsvc`;
  }
  if (apiName === 'ws-hp.com/devices' && apiVersion === '1') {
    return `${Config.AUTHZ_ONBOARDING_DEVICES_BASE_URL}/v1`;
  }
  if (apiName === 'ws-hp.com/messagesdb' && apiVersion === '1') {
    return `${Config.STRATUS_BASE_URL}/atlas/v1`;
  }
  return Config.AUTHZ_ONBOARDING_BASE_URL;
};

Config.UCDEGatewayApiVersion = '1';

Config.isHpxApp = false;

Config.isMyAccountDomain = window.location.hostname.includes(MY_ACCOUNT_DOMAIN);

export default Config;
