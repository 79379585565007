import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@veneer/core/dist/scripts/tooltip';
import { ChildrenWrapper, Container, SideContentWrapper } from './styles';

const SelectableButton = ({
  tooltip,
  leftIcon,
  rightIcon,
  children,
  onClick,
  active,
}) => {
  const Wrapper = tooltip ? Tooltip : React.Fragment;

  const wrapperProps = useMemo(() => {
    if (tooltip) {
      return {
        content: tooltip,
        arrow: true,
        placement: 'bottom',
        useJsPositioning: true,
        contentHideDelayOnHover: 0,
        contentShowDelayOnHover: 300,
      };
    }
    return {};
  }, [tooltip]);

  return (
    <Wrapper {...wrapperProps}>
      <Container
        active={active}
        onClick={onClick}
      >
        <SideContentWrapper>
          {leftIcon}
        </SideContentWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        <SideContentWrapper>
          {rightIcon}
        </SideContentWrapper>
      </Container>
    </Wrapper>
  );
};

SelectableButton.propTypes = {
  tooltip: PropTypes.string,
  leftIcon: PropTypes.objectOf(PropTypes.any),
  rightIcon: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

SelectableButton.defaultProps = {
  tooltip: undefined,
  leftIcon: undefined,
  rightIcon: undefined,
  active: false,
  onClick: () => undefined,
};

export default SelectableButton;
