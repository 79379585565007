import {
  call,
  put,
  takeLeading,
} from 'redux-saga/effects';
import {
  getUsers,
} from '../../../api/UCDEGateway';
import {
  getUsersAction,
  getUsersActionFailed,
  getUsersActionSuccess,
} from './actions';

function* fetchUsers(action) {
  try {
    const response = yield call(getUsers, action.payload);
    yield put(getUsersActionSuccess(response));
  } catch (e) {
    yield put(getUsersActionFailed());
  }
}

function* watchGetUsers() {
  yield takeLeading(getUsersAction.toString(), fetchUsers);
}

export default watchGetUsers;
