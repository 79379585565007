import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ContextualMenu } from '../../shared-components/Analytics/ContextualMenu';
import { selectUcdeUserFullName } from '../../../store/modules/ucdeUser/selectors';
import { makePathRelative } from '../../../utils/routing';
import { selectRootMatch } from '../../../selectors/routing';
import i18n from '../../../utils/i18n';
import Avatar from '../../Avatar';
import { AvatarContainer } from './styles';
import { PATHS } from '../../../utils/constants';

const ProfileMenu = () => {
  const accountProfileOption = {
    value: 0,
    label: i18n.t('home.accountIcon.accountProfile'),
    path: PATHS.ACCOUNT_DETAILS.PROFILE,
    udlEvent: {
      linkID: 'profile',
      linkPlacement: 'top-nav',
    },
  };

  const signOutOption = {
    value: 1,
    label: i18n.t('home.accountIcon.overlayMenu'),
    path: PATHS.SIGNOUT,
    udlEvent: {
      linkID: 'signout',
      linkPlacement: 'top-nav',
    },
  };

  const userFullName = useSelector(selectUcdeUserFullName);
  const match = useSelector(selectRootMatch);
  const relativePath = makePathRelative(match);
  const dispatch = useDispatch();

  const handleOption = useCallback(option => {
    dispatch(push(relativePath(option.path)));
  }, [dispatch]);

  return (
    <ContextualMenu
      options={[accountProfileOption, signOutOption]}
      placement="bottom-end"
      onClick={(e, option) => handleOption(option)}
    >
      <AvatarContainer>
        <Avatar
          text={userFullName}
          backgroundOpacity="0.2"
          fontSize="16px"
        />
      </AvatarContainer>
    </ContextualMenu>
  );
};

export default ProfileMenu;
