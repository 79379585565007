import {
  select,
  takeLeading,
} from 'redux-saga/effects';
import {
  closePortal,
} from './actions';
import { selectPortalCanClose } from './selectors';
import { dispatchJWebCloseEvent } from '../../../utils/auth';

function* tryClosePortal() {
  const canClose = yield select(selectPortalCanClose);

  if (!canClose) {
    return;
  }

  dispatchJWebCloseEvent();
}

function* watchClosePortal() {
  yield takeLeading(closePortal.toString(), tryClosePortal);
}

export default watchClosePortal;
