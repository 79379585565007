import * as actions from './actions';
import { createReducer } from '../../../utils/redux';
import { randomStringId } from '../../../utils/globals';

const INITIAL_STATE = {
  accountStatusCardRefreshOnClick: null,
  asyncEvents: {},
};

function accountStatusCardRefreshOnClick(state) {
  return {
    ...state,
    accountStatusCardRefreshOnClick: randomStringId(),
  };
}

function handleAsyncEventObject({
  state,
  component,
  isFetching,
  isRefreshing,
  hasError,
  backgroundFunctions,
  componentRetries,
  currentStatus,
  lastFetch,
}) {
  return {
    ...state,
    asyncEvents: {
      ...state.asyncEvents,
      [component]: {
        isFetching,
        isRefreshing,
        hasError,
        currentStatus,
        componentRetries,
        backgroundFunctions,
        lastFetch,
      },
    },
  };
}

function handleBackgroundFunctionsRetries(state, action) {
  const {
    component,
    functionIndex,
    retries,
  } = action.payload;

  const backgroundFunctionsState = state.asyncEvents[component].backgroundFunctions;

  const backgroundFunctions = {
    ...backgroundFunctionsState,
    [functionIndex]: {
      ...backgroundFunctionsState[functionIndex],
      retries,
    },
  };

  const componentRetries = Math.max(
    ...Object
      .keys(backgroundFunctions)
      .map(backgroundFunctionKey => backgroundFunctions[backgroundFunctionKey].retries),
  );

  return {
    backgroundFunctions,
    componentRetries,
  };
}

function asyncEventRequest(state, action) {
  const {
    isRefreshing = false,
    asyncFunctions,
    component,
  } = action.payload;

  const backgroundFunctions = asyncFunctions.map(asyncFunction => (
    {
      functionName: asyncFunction.name || asyncFunction.requestActionCreator.toString(),
      retries: 0,
    }
  ));

  let stateChanges = {
    state,
    component,
    isFetching: true,
    isRefreshing,
    hasError: false,
    backgroundFunctions,
    currentStatus: isRefreshing ? 'refreshing' : 'fetching',
  };

  if (state.asyncEvents[component]) {
    const { hasError, lastFetch } = state.asyncEvents[component];
    stateChanges = { ...stateChanges, hasError, lastFetch };
  }

  const newEventsObject = handleAsyncEventObject(stateChanges);
  return newEventsObject;
}

function asyncEventRetry(state, action) {
  const { component, hasError } = action.payload;
  const { isRefreshing } = state.asyncEvents[component];
  const {
    backgroundFunctions,
    componentRetries,
  } = handleBackgroundFunctionsRetries(state, action);

  let stateChanges = {
    state,
    component,
    isFetching: true,
    isRefreshing,
    hasError,
    backgroundFunctions,
    componentRetries,
    currentStatus: 'retrying',
  };

  if (state.asyncEvents[component]) {
    const { lastFetch } = state.asyncEvents[component];
    stateChanges = { ...stateChanges, lastFetch };
  }

  const newEventsObject = handleAsyncEventObject(stateChanges);
  return newEventsObject;
}

function asyncEventFailed(state, action) {
  const { component } = action.payload;
  const { lastFetch } = state.asyncEvents[component];
  const {
    backgroundFunctions,
    componentRetries,
  } = handleBackgroundFunctionsRetries(state, action);

  const stateChanges = {
    state,
    component,
    isFetching: false,
    hasError: true,
    backgroundFunctions,
    componentRetries,
    currentStatus: 'error',
    lastFetch,
  };

  const newEventsObject = handleAsyncEventObject(stateChanges);
  return newEventsObject;
}

function asyncEventSuccess(state, action) {
  const lastFetch = new Date();
  const { component } = action.payload;

  const stateChanges = {
    state,
    component,
    isFetching: false,
    hasError: false,
    backgroundFunctions: {},
    componentRetries: 0,
    currentStatus: 'success',
    lastFetch,
  };

  const newEventsObject = handleAsyncEventObject(stateChanges);
  return newEventsObject;
}

const handlers = {
  [actions.accountStatusCardRefreshOnClick]: accountStatusCardRefreshOnClick,
  [actions.asyncEventRequest]: asyncEventRequest,
  [actions.asyncEventRetry]: asyncEventRetry,
  [actions.asyncEventFailed]: asyncEventFailed,
  [actions.asyncEventSuccess]: asyncEventSuccess,
};

const eventsReducer = createReducer(INITIAL_STATE, handlers);

export default eventsReducer;
